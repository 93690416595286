import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';
import useProjectIssuePrioritiesQuery from 'issues/hooks/useProjectIssuePrioritiesQuery';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import IssueDto from 'issues/types/IssueDto';
import TagCreateSelect from 'tags/components/TagCreateSelect';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import useProjectWorkPhasesQuery from 'labels/hooks/useProjectWorkPhasesQuery';
import { isEqual } from 'lodash';
import IChildren from 'common/types/IChildren';
import ISxProps from 'common/types/ISxProps';
import LocalizedDatePicker from 'common/components/LocalizedDatePicker';
import moment, { Moment } from 'moment';

interface PanelProps extends IChildren, ISxProps {
}

function Panel({
  children,
  sx,
}: PanelProps) {
  const theme = useTheme();
  return (
    <Box
      id="MutateIssueGeneralPanel"
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 0px 8px -4px rgba(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        p: 1.5,
        pt: 1,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

interface MutateIssueDialogProps {
  issueId: string,
  onClose: () => void,
}

type FormState = Partial<Pick<IssueDto, 'title' | 'description' | 'issueType' | 'issuePriority' | 'tags' | 'disciplines' | 'buildings' | 'floors' | 'workPhase' | 'startingDate' | 'dueDate'>>;

export default function EditIssueGeneralDialog({
  issueId,
  onClose,
}: MutateIssueDialogProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issue } = useIssueQuery(issueId);

  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync: updateIssueAsync, isPending: isLoading } = useIssueUpdateMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [formState, setFormState] = useState<FormState | undefined>(undefined);
  useEffect(() => {
    // init form values
    setFormState((prev) => {
      if (prev || !issue) return prev;
      return {
        id: issueId,
        issueType: issue.issueType,
        title: issue.title,
        description: issue.description,
        startingDate: issue.startingDate,
        dueDate: issue.dueDate,
        issuePriority: issue.issuePriority,
        visibility: issue.visibility,
        allowedUserGroups: issue.allowedUserGroups,
        tags: issue.tags,
        workPhase: issue.workPhase,
        disciplines: issue.disciplines,
        buildings: issue.buildings,
        floors: issue.floors,
      };
    });
  }, [issue, issueId]);

  const onClickConfirm = useCallback(async () => {
    if (!formState || !issue) throw new Error('dependency error');
    setErrorMessage(undefined);
    try {
      await updateIssueAsync({
        id: issueId,
        ...(formState.issueType && formState.issueType !== issue.issueType ? { type: { value: formState.issueType } } : {}),
        ...(formState.title && formState.title !== issue.title ? { title: { value: formState.title } } : {}),
        ...(formState.description && formState.description !== issue.description ? { description: { value: formState.description } } : {}),
        ...(formState.startingDate && formState.startingDate !== issue.startingDate ? { startingDate: { value: formState.startingDate } } : {}),
        ...(formState.dueDate && formState.dueDate !== issue.dueDate ? { dueDate: { value: formState.dueDate } } : {}),
        ...(formState.issuePriority && formState.issuePriority !== issue.issuePriority ? { priority: { value: formState.issuePriority ?? null } } : {}),
        ...(formState.tags && !isEqual(formState.tags, issue.tags) ? { tags: { value: formState.tags ?? null } } : {}),
        ...(formState.workPhase && formState.workPhase !== issue.workPhase ? { workPhase: { value: formState.workPhase ?? null } } : {}),
        ...(formState.disciplines && !isEqual(formState.disciplines, issue.disciplines) ? { disciplines: { value: formState.disciplines ?? null } } : {}),
        ...(formState.buildings && !isEqual(formState.buildings, issue.buildings) ? { buildings: { value: formState.buildings ?? null } } : {}),
        ...(formState.floors && !isEqual(formState.floors, issue.floors) ? { floors: { value: formState.floors ?? null } } : {}),
      });
      onClose();
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [formState, getRequestErrorMessage, issue, issueId, onClose, updateIssueAsync]);

  const { data: issueTypes } = useProjectIssueTypesQuery();
  const { data: issuePriorities } = useProjectIssuePrioritiesQuery();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: workPhases } = useProjectWorkPhasesQuery();

  const onChangeIssueTitle = useCallback((event: ChangeEvent<HTMLInputElement>) => setFormState((prev) => (prev ? { ...prev, title: event.target.value } : prev)), []);
  const onChangeIssueDescription = useCallback((event: ChangeEvent<HTMLInputElement>) => setFormState((prev) => (prev ? { ...prev, description: event.target.value } : prev)), []);
  const onChangeIssueType = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, issueType: event.target.value } : prev)), []);
  const onChangeIssuePriority = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, issuePriority: event.target.value } : prev)), []);
  const onChangeTags = useCallback((tagIds: string[]) => setFormState((prev) => (prev ? { ...prev, tags: tagIds } : prev)), []);
  const onChangeDisciplines = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, disciplines: event.target.value } : prev)), []);
  const onChangeBuildings = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, buildings: event.target.value } : prev)), []);
  const onChangeFloors = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, floors: event.target.value } : prev)), []);
  const onChangeWorkPhase = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, workPhase: event.target.value } : prev)), []);
  const onChangeDueDate = useCallback((momentDate: Moment | undefined) => setFormState((prev) => ({ ...prev, dueDate: momentDate?.toDate().toISOString() })), [setFormState]);
  const onChangeStartingDate = useCallback((momentDate: Moment | undefined) => setFormState((prev) => ({ ...prev, startingDate: momentDate?.toDate().toISOString() })), [setFormState]);

  const issueTypeName = useMemo(() => (formState?.issueType ? issueTypes?.find((type) => type.id === formState.issueType)?.name : undefined), [formState, issueTypes]);
  const dueDate = useMemo(() => (formState?.dueDate ? moment(new Date(formState.dueDate)) : undefined), [formState]);
  const startingDate = useMemo(() => (formState?.startingDate ? moment(new Date(formState.startingDate)) : undefined), [formState]);

  return (
    <Dialog id="EditIssueGeneralDialog" open PaperProps={{ sx: { maxWidth: '90vw', maxHeight: '90vh', overflow: 'initial' } }}>
      <DialogTitle component="div" sx={{ display: 'flex' }}>
        <Typography variant="h3">{t('edit-issue-general-dialog_title', 'Edit {{issueTypeName}}', { issueTypeName })}</Typography>
        <FormControl sx={{ minWidth: 120, ml: 'auto' }}>
          <InputLabel id="edit-issue-general-dialog_type-select-label">
            {t('edit-issue-general-dialog_type-select-label', 'Type')}
          </InputLabel>
          <Select
            id="edit-issue-general-dialog_type-select"
            labelId="edit-issue-general-dialog_type-select-label"
            value={formState?.issueType ?? ''}
            onChange={onChangeIssueType}
            label={t('edit-issue-general-dialog_type-select-label', 'Type')}
          >
            {!!issueTypes && issueTypes.map((type) => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogTitle>
      <DialogContent sx={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
        <Box
          sx={{
            overflow: 'auto',
            borderRadius: '8px',
            boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.2)',
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Box
            sx={{
              p: 2,
              display: 'inline-flex',
              gap: 2,
            }}
          >
            <Box sx={{ flex: '0 0 auto', width: 480, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Panel>
                <Typography variant="h4">
                  {t('edit-issue-general-dialog_general-panel-header', 'General')}
                </Typography>
                <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px', flexGrow: 1 }}>
                  <TextField
                    label={t('edit-issue-general-dialog_title-textfield-label', 'Title')}
                    id="edit-issue-general-dialog_title-textfield"
                    value={formState?.title ?? ''}
                    disabled={!formState || isLoading}
                    onChange={onChangeIssueTitle}
                  />
                  <TextField
                    label={t('edit-issue-general-dialog_description-textfield-label', 'Description')}
                    id="edit-issue-general-dialog_description-textfield"
                    value={formState?.description ?? ''}
                    disabled={!formState || isLoading}
                    onChange={onChangeIssueDescription}
                    multiline
                    inputProps={{ style: { minHeight: '73px', height: '397px', resize: 'vertical' } }}
                  />
                </Box>
              </Panel>
            </Box>
            <Box sx={{ flex: '0 0 auto', width: 320, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Panel>
                <Typography variant="h4">
                  {t('edit-issue-general-dialog_schedule-panel-header', 'Schedule')}
                </Typography>
                <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <LocalizedDatePicker
                      label={t('edit-issue-general-dialog_start-date-label', 'Start Date')}
                      value={startingDate}
                      onChange={onChangeStartingDate}
                    />
                    <LocalizedDatePicker
                      label={t('edit-issue-general-dialog_due-date-label', 'Due Date')}
                      value={dueDate}
                      onChange={onChangeDueDate}
                    />
                  </Box>
                  <FormControl sx={{ flex: '1 1 0' }}>
                    <InputLabel id="mutate-issue-general-panel_issue-priority-select-label">
                      {t('mutate-issue-general-panel_issue-priority-select-label', 'Priority')}
                    </InputLabel>
                    <Select
                      id="mutate-issue-general-panel_issue-priority-select"
                      labelId="mutate-issue-general-panel_issue-priority-select-label"
                      value={formState?.issuePriority ?? ''}
                      onChange={onChangeIssuePriority}
                      label={t('mutate-issue-general-panel_issue-priority-select-label', 'Priority')}
                    >
                      <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('mutate-issue-general-panel_priority-select-empty-value-label', 'None')}</MenuItem>
                      {!!issuePriorities && issuePriorities.map((priority) => <MenuItem key={priority.id} value={priority.id}>{priority.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>
              </Panel>
              <Panel>
                <Typography variant="h4">
                  {t('edit-issue-general-dialog_meta-data-panel-header', 'Meta Data')}
                </Typography>
                <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <FormControl>
                    <InputLabel htmlFor="edit-issue-general-dialog_tag-create-select">{t('edit-issue-general-dialog_tags-create-select-label', 'Tags')}</InputLabel>
                    <TagCreateSelect
                      id="edit-issue-general-dialog_tags-create-select"
                      value={formState?.tags ?? []}
                      disabled={!formState || isLoading}
                      onChange={onChangeTags}
                      label={t('edit-issue-general-dialog_tags-create-select-label', 'Tags')}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl sx={{ flex: '1 1 0' }}>
                    <InputLabel id="edit-issue-general-dialog_workphase-select-label">
                      {t('edit-issue-general-dialog_workphase-select-label', 'Work Phase')}
                    </InputLabel>
                    <Select
                      id="edit-issue-general-dialog_workphase-select"
                      labelId="edit-issue-general-dialog_workphase-select-label"
                      value={formState?.workPhase ?? ''}
                      disabled={!formState || isLoading}
                      onChange={onChangeWorkPhase}
                      label={t('edit-issue-general-dialog_workphase-select-label', 'Work Phase')}
                    >
                      <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('edit-issue-general-dialog_work-phase-select-empty-value-label', 'None')}</MenuItem>
                      {!!workPhases && workPhases.map((workPhase) => <MenuItem key={workPhase.id} value={workPhase.id}>{workPhase.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>
              </Panel>
              <Panel>
                <Typography variant="h4">
                  {t('edit-issue-general-dialog_model-association-panel-header', 'Model Association')}
                </Typography>
                <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                  <FormControl sx={{ flex: '1 1 0' }}>
                    <InputLabel id="edit-issue-general-dialog_disciplines-select-label">
                      {t('edit-issue-general-dialog_disciplines-select-label', 'Disciplines')}
                    </InputLabel>
                    <Select
                      id="edit-issue-general-dialog_disciplines-select"
                      labelId="edit-issue-general-dialog_disciplines-select-label"
                      multiple
                      value={formState?.disciplines ?? []}
                      disabled={!formState || isLoading}
                      onChange={onChangeDisciplines}
                      label={t('edit-issue-general-dialog_disciplines-select-label', 'Disciplines')}
                    >
                      {!!disciplines && disciplines.map((discipline) => <MenuItem key={discipline.id} value={discipline.id}>{discipline.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ flex: '1 1 0' }}>
                    <InputLabel id="edit-issue-general-dialog_buildings-select-label">
                      {t('edit-issue-general-dialog_buildings-select-label', 'Buildings')}
                    </InputLabel>
                    <Select
                      id="edit-issue-general-dialog_buildings-select"
                      labelId="edit-issue-general-dialog_buildings-select-label"
                      multiple
                      value={formState?.buildings ?? []}
                      disabled={!formState || isLoading}
                      onChange={onChangeBuildings}
                      label={t('edit-issue-general-dialog_buildings-select-label', 'Buildings')}
                    >
                      {!!buildings && buildings.map((building) => <MenuItem key={building.id} value={building.id}>{building.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ flex: '1 1 0' }}>
                    <InputLabel id="edit-issue-general-dialog_floors-select-label">
                      {t('edit-issue-general-dialog_floors-select-label', 'Floors')}
                    </InputLabel>
                    <Select
                      id="edit-issue-general-dialog_floors-select"
                      labelId="edit-issue-general-dialog_floors-select-label"
                      multiple
                      value={formState?.floors ?? []}
                      disabled={!formState || isLoading}
                      onChange={onChangeFloors}
                      label={t('edit-issue-general-dialog_floors-select-label', 'Floors')}
                    >
                      {!!floors && floors.map((floor) => <MenuItem key={floor.id} value={floor.id}>{floor.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Box>
              </Panel>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ gap: 2 }}>
        <Button
          id="EditIssueGeneralDialogCancelButton"
          variant="contained"
          color="secondary"
          onClick={onClose}
          sx={{ mr: 'auto', flexShrink: 0 }}
        >
          {t('edit-issue-general-dialog_cancel-button-label', 'Cancel')}
        </Button>
        {!!errorMessage && <Alert severity="error" sx={{ maxHeight: 100, overflow: 'auto' }}>{errorMessage}</Alert>}
        <Button
          id="EditIssueGeneralDialogConfirmButton"
          variant="contained"
          color="primary"
          onClick={onClickConfirm}
          disabled={isLoading}
          sx={{ gap: 1, flexShrink: 0 }}
        >
          {!!isLoading && <CircularProgress size={12} />}
          {t('edit-issue-general-dialog_confirm-edit-button-label', 'Save Changes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
