import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, TextField, Typography, useTheme } from '@mui/material';
import { mdiArrowUp, mdiEmail, mdiFileDocument, mdiLinkPlus, mdiSubdirectoryArrowRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useIssuesCreateMutation from 'issues/hooks/useIssuesCreateMutation';
import IssueDto from 'issues/types/IssueDto';
import IssueVisibility from 'issues/types/IssueVisibility';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';
import useProjectIssuePrioritiesQuery from 'issues/hooks/useProjectIssuePrioritiesQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import useProjectWorkPhasesQuery from 'labels/hooks/useProjectWorkPhasesQuery';
import useUserGroupsOdataQuery from 'users-groups/hooks/useUserGroupsOdataQuery';
import AssignmentSelect from 'issues/components/AssignmentSelect';
import useCollaboratorsOdataQuery from 'collaborators/hooks/useCollaboratorsOdataQuery';
import TagCreateSelect from 'tags/components/TagCreateSelect';
import LocalizedDatePicker from 'common/components/LocalizedDatePicker';
import useDraftIssueStatus from 'issues/hooks/useDraftIssueStatus';
import useOpenIssueStatus from 'issues/hooks/useOpenIssueStatus';
import LinkedIssuePanelItem from 'issues/components/LinkedIssuePanelItem';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import useIssuesUpdateManyMutation from 'issues/hooks/useIssuesUpdateManyMutation';
import useViewpointCreateMutation from 'issues/hooks/useViewpointsCreateMutation';
import UpdateManyIssuesDto from 'issues/types/UpdateManyIssuesDto';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import useRevisionsOdataQuery from 'models/hooks/useRevisionsOdataQuery';
import CreateViewpointDto from 'issues/types/CreateViewpointDto';
import LinkIssueModal from 'issues/components/LinkIssueModal';
import LinkEmailModal from 'issues/components/LinkEmailModal';
import DocumentSelectionContext, { DocumentSelectionContextState } from 'documents/contexts/DocumentSelectionContext';
import IssueLinkDocumentsDialog from 'issues/components/IssueLinkDocumentsDialog';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import LinkedDocumentVersionPanelItem from 'issues/components/LinkedDocumentVersionPanelItem';
import LinkedEmailPanelItem from 'issues/components/LinkedEmailPanelItem';
import useEmailsOdataQuery from 'emails/hooks/useEmailsOdataQuery';
import CreateIssuePanel from 'issues/components/CreateIssuePanel';
import CreateIssueLinkedViewpointsPanel from 'issues/components/CreateIssueLinkedViewpointsPanel';
import CreateIssueLinkedComponentsPanel from 'issues/components/CreateIssueLinkedComponentsPanel';
import ViewpointItem from 'models/types/ViewpointItem';
import useResourceUploadHandler from 'resources/hooks/useResourceUploadHandler';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import useBoxRef from 'common/hooks/useBoxRef';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

export type CreateIssueFormState = Partial<Pick<IssueDto,
'title' |
'description' |
'issueType' |
'issuePriority' |
'tags' |
'disciplines' |
'buildings' |
'floors' |
'workPhase' |
'visibility' |
'allowedUserGroups' |
'assignedCollaboratorIds' |
'reviewerId' |
'startingDate' |
'dueDate' |
'parentId' |
'childrenIds' |
'linkedEmailIds' |
'linkedDocumentVersionIds'
>> & {
  viewpoints?: ViewpointItem[] | undefined,
};

interface CreateIssueDialogProps {
  open: boolean,
  suppressKeepOpen?: boolean | undefined,
  onConfirm: (createdIssueId: string | undefined, keepOpen: boolean) => void,
  onCancel: () => void,
}

export default function CreateIssueDialog({
  open,
  suppressKeepOpen,
  onConfirm,
  onCancel,
}: CreateIssueDialogProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();

  const { data: issueTypes } = useProjectIssueTypesQuery();
  const { data: issuePriorities } = useProjectIssuePrioritiesQuery();
  const draftIssueStatus = useDraftIssueStatus();
  const openIssueStatus = useOpenIssueStatus();
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: floors } = useProjectFloorsQuery();
  const { data: workPhases } = useProjectWorkPhasesQuery();
  const { data: groups } = useUserGroupsOdataQuery({ filter: { isDeleted: false } });
  const { data: collaborators } = useCollaboratorsOdataQuery({ filter: { isDeleted: false } });

  const { modelFileIds } = useModelSelectionContext();
  const { data: revisions } = useRevisionsOdataQuery({});
  const modelMetaData = useMemo(() => {
    if (!revisions) return undefined;
    const modelFileIdsSet = new Set(modelFileIds);
    const loadedModelFiles = revisions.map((r) => r.changedFile).filter((m) => modelFileIdsSet.has(m.id));
    return {
      disciplines: Array.from(new Set(loadedModelFiles.map((f) => f.discipline))),
      buildings: Array.from(new Set(loadedModelFiles.map((f) => f.building))),
      floors: Array.from(new Set(loadedModelFiles.map((f) => f.floor))),
    };
  }, [modelFileIds, revisions]);

  const [keepDialogOpen, setKeepDialogOpen] = useState(false);
  const onChangeKeepDialogOpen = useCallback((event: ChangeEvent<HTMLInputElement>) => setKeepDialogOpen(event.target.checked), []);
  const [formState, setFormState] = useState<CreateIssueFormState | undefined>(undefined);
  useEffect(() => {
    setFormState((prev) => {
      if (prev || !issueTypes || !issuePriorities || !modelMetaData) return prev;
      return {
        issueType: issueTypes[0]?.id,
        issuePriority: issuePriorities.find((prio) => prio.isDefault && prio.originalName === 'Normal')?.id,
        visibility: IssueVisibility.Public,
        ...modelMetaData,
      };
    });
  }, [issuePriorities, issueTypes, modelMetaData]);

  const { data: parentIssue } = useIssueQuery(formState?.parentId);
  const { data: childIssues, isPlaceholderData: isChildIssuesPlaceholderData } = useIssuesOdataQuery(formState?.childrenIds ? { filter: { id: { in: formState.childrenIds } } } : undefined, { placeholderData: (data) => data });
  const linkedEmailsOdataFilter = useMemo(() => (formState?.linkedEmailIds?.length ? { filter: { id: { in: formState.linkedEmailIds } } } : undefined), [formState]);
  const { data: linkedEmails } = useEmailsOdataQuery(linkedEmailsOdataFilter);
  const linkedDocumentVersionsOdataFilter = useMemo(() => (formState?.linkedDocumentVersionIds?.length ? { filter: { id: { in: formState.linkedDocumentVersionIds } } } : undefined), [formState]);
  const { data: linkedDocumentVersions } = useDocumentVersionsQuery(linkedDocumentVersionsOdataFilter);

  const getRequestErrorMessage = useRequestErrorMessage();
  const { mutateAsync: createIssuesAsync, isPending: isLoadingCreateMutation } = useIssuesCreateMutation();
  const { mutateAsync: updateManyIssuesAsync, isPending: isLoadingManyMutation } = useIssuesUpdateManyMutation();
  const { mutateAsync: createViewpointsAsync, isPending: isLoadingCreateViewpoints } = useViewpointCreateMutation();
  const uploadResources = useResourceUploadHandler();
  const isLoading = isLoadingCreateMutation || isLoadingManyMutation || isLoadingCreateViewpoints;

  const { viewpointItems, setViewpointItems, linkedComponentItems } = useModelsInteractionContext();

  const addViewpointMetadataToFormState = useBoxRef(() => {
    if (!revisions || !viewpointItems) return;
    const modelFileIdsSet = new Set(viewpointItems.flatMap((item) => item.modelFileIds));
    const loadedModelFiles = revisions.map((r) => r.changedFile).filter((m) => modelFileIdsSet.has(m.id));
    setFormState((prev) => (prev ? {
      ...prev,
      disciplines: Array.from(new Set([...(prev.disciplines ?? []), ...loadedModelFiles.map((f) => f.discipline)])),
      buildings: Array.from(new Set([...(prev.buildings ?? []), ...loadedModelFiles.map((f) => f.building)])),
      floors: Array.from(new Set([...(prev.floors ?? []), ...loadedModelFiles.map((f) => f.floor)])),
    } : prev));
  });

  useEffect(() => {
    addViewpointMetadataToFormState.current();
  }, [addViewpointMetadataToFormState, viewpointItems]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const onClickConfirm = useCallback(async (draft: boolean) => {
    if (!formState || !formState.title || !draftIssueStatus || !openIssueStatus) return;
    setErrorMessage(undefined);

    const globalIdsByModelFileId = new Map<string, string[]>(modelFileIds.map((modelFileId) => [modelFileId, []]));
    linkedComponentItems.forEach((item) => {
      if (!item.modelFileId) return;
      const componentGlobalIds: string[] = globalIdsByModelFileId.get(item.modelFileId) ?? [];
      componentGlobalIds.push(item.globalId);
      globalIdsByModelFileId.set(item.modelFileId, componentGlobalIds);
    });
    try {
      const [createdIssue] = await createIssuesAsync([{
        title: { value: formState.title },
        status: { value: draft ? draftIssueStatus.id : openIssueStatus.id },
        ...(formState.issueType ? { type: { value: formState.issueType } } : {}),
        ...(formState.title ? { title: { value: formState.title } } : {}),
        ...(formState.description ? { description: { value: formState.description } } : {}),
        ...(formState.startingDate ? { startingDate: { value: formState.startingDate } } : {}),
        ...(formState.dueDate ? { dueDate: { value: formState.dueDate } } : {}),
        ...(formState.issuePriority ? { priority: { value: formState.issuePriority } } : {}),
        ...(formState.assignedCollaboratorIds?.length ? { assignedCollaboratorIds: { value: formState.assignedCollaboratorIds } } : {}),
        ...(formState.reviewerId ? { reviewerId: { value: formState.reviewerId } } : {}),
        ...(formState.visibility ? { visibility: { value: formState.visibility } } : {}),
        ...(formState.allowedUserGroups?.length && formState.visibility === IssueVisibility.Restricted ? { allowedGroups: { value: formState.allowedUserGroups ?? null }, visibility: { value: IssueVisibility.Restricted } } : {}),
        ...(formState.tags?.length ? { tags: { value: formState.tags } } : {}),
        ...(formState.workPhase ? { workPhase: { value: formState.workPhase } } : {}),
        ...(formState.disciplines?.length ? { disciplines: { value: formState.disciplines } } : {}),
        ...(formState.buildings?.length ? { buildings: { value: formState.buildings } } : {}),
        ...(formState.floors?.length ? { floors: { value: formState.floors } } : {}),
        ...(formState.parentId ? { parentId: { value: formState.parentId } } : {}),
        ...(linkedComponentItems.length ? { linkedComponentsGlobalIds: { value: linkedComponentItems.map((item) => item.globalId) } } : {}),
        ...(formState.linkedDocumentVersionIds ? { linkedDocumentVersionIds: { value: formState.linkedDocumentVersionIds } } : {}),
        ...(formState.linkedEmailIds ? { linkedEmailIds: { value: formState.linkedEmailIds } } : {}),
        ...(globalIdsByModelFileId.size ? { modelFileComponentIds: { value: Object.fromEntries(globalIdsByModelFileId) } } : undefined),
      }]);
      if (formState.childrenIds?.length) {
        const updateManyIssuesDto: UpdateManyIssuesDto = {
          ids: formState.childrenIds,
          timeStamp: new Date().toISOString(),
          parentId: { value: createdIssue.id },
        };
        await updateManyIssuesAsync(updateManyIssuesDto);
      }
      if (viewpointItems?.length) {
        const createViewpointDtos: CreateViewpointDto[] = await Promise.all(viewpointItems.map(async (viewpointItem, index) => {
          const screenshotFileName = `${createdIssue.id}_viewpoint-${index}.png`;
          const screenshotBlob = await (await fetch(viewpointItem.imageDataUrl)).blob();
          const [screenshotResourceId] = await uploadResources([{ blob: screenshotBlob, fileName: screenshotFileName }]);
          return {
            issueId: { value: createdIssue.id },
            imageId: { value: screenshotResourceId },
            imageName: { value: screenshotFileName },
            index: { value: index },
            creatingTool: { value: 'Visoplan-Web' },
            visualStates: { value: viewpointItem.visualStates },
            cameraType: { value: viewpointItem.cameraType },
            location: { value: viewpointItem.location },
            direction: { value: viewpointItem.direction },
            up: { value: viewpointItem.up },
            cameraFactor: { value: viewpointItem.cameraFactor },
            visibility: { value: viewpointItem.visibility },
            sections: { value: viewpointItem.sections },
            lines: { value: viewpointItem.lines },
          };
        }));
        await createViewpointsAsync(createViewpointDtos);
      }
      if (keepDialogOpen) {
        setFormState((prev) => (prev ? { ...prev, title: undefined, description: undefined } : prev));
      }
      onConfirm(createdIssue.id, keepDialogOpen);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [createIssuesAsync, createViewpointsAsync, draftIssueStatus, formState, getRequestErrorMessage, keepDialogOpen, linkedComponentItems, modelFileIds, onConfirm, openIssueStatus, updateManyIssuesAsync, uploadResources, viewpointItems]);

  const onClickSaveDraft = useCallback(async () => onClickConfirm(true), [onClickConfirm]);
  const onClickCreateAndPublish = useCallback(async () => onClickConfirm(false), [onClickConfirm]);

  const onChangeIssueTitle = useCallback((event: ChangeEvent<HTMLInputElement>) => setFormState((prev) => (prev ? { ...prev, title: event.target.value } : prev)), []);
  const onChangeIssueDescription = useCallback((event: ChangeEvent<HTMLInputElement>) => setFormState((prev) => (prev ? { ...prev, description: event.target.value } : prev)), []);
  const onChangeIssueType = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, issueType: event.target.value } : prev)), []);
  const onChangeIssuePriority = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, issuePriority: event.target.value } : prev)), []);
  const onChangeTags = useCallback((tagIds: string[]) => setFormState((prev) => (prev ? { ...prev, tags: tagIds } : prev)), []);
  const onChangeDisciplines = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, disciplines: event.target.value } : prev)), []);
  const onChangeBuildings = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, buildings: event.target.value } : prev)), []);
  const onChangeFloors = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, floors: event.target.value } : prev)), []);
  const onChangeWorkPhase = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, workPhase: event.target.value } : prev)), []);
  const onChangeVisibility = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, visibility: parseInt(event.target.value, 10) as IssueVisibility } : prev)), []);
  const onChangeAllowedGroups = useCallback((event: SelectChangeEvent<string[]>) => setFormState((prev) => (prev && Array.isArray(event.target.value) ? { ...prev, allowedUserGroups: event.target.value } : prev)), []);
  const onChangeAssignedCollaborators = useCallback((collaboratorIds: string[]) => setFormState((prev) => (prev ? { ...prev, assignedCollaboratorIds: collaboratorIds } : prev)), []);
  const onChangeReviewerId = useCallback((event: SelectChangeEvent<string>) => setFormState((prev) => (prev ? { ...prev, reviewerId: event.target.value } : prev)), []);
  const onChangeDueDate = useCallback((momentDate: Moment | undefined) => setFormState((prev) => ({ ...prev, dueDate: momentDate?.toDate().toISOString() })), [setFormState]);
  const onChangeStartingDate = useCallback((momentDate: Moment | undefined) => setFormState((prev) => ({ ...prev, startingDate: momentDate?.toDate().toISOString() })), [setFormState]);

  const addLinkButtonRef = useRef<HTMLButtonElement>(null);
  const [linkItemMenuOpen, setLinkItemMenuOpen] = useState(false);
  const onClickAddLinkButton = useCallback(() => setLinkItemMenuOpen(true), []);
  const onCloseAddLinkMenu = useCallback(() => setLinkItemMenuOpen(false), []);

  const [linkParentIssueDialogOpen, setLinkParentIssueDialogOpen] = useState(false);
  const disabledIssueIds = useMemo(() => (formState ? new Set([...(formState.childrenIds ?? []), ...(formState.parentId ? [formState.parentId] : [])]) : undefined), [formState]);
  const onClickLinkParentIssue = useCallback(() => {
    setLinkParentIssueDialogOpen(true);
    setLinkItemMenuOpen(false);
  }, []);
  const onCloseLinkParentIssueDialog = useCallback((selectedIssueIds: string[]) => {
    if (selectedIssueIds.length) {
      setFormState((prev) => (prev ? { ...prev, parentId: selectedIssueIds[0] } : prev));
    }
    setLinkParentIssueDialogOpen(false);
  }, []);
  const onClickRemoveParent = useCallback(() => {
    setFormState((prev) => (prev ? { ...prev, parentId: undefined } : prev));
  }, []);

  const [linkChildIssuesDialogOpen, setLinkChildIssuesDialogOpen] = useState(false);
  const onClickLinkChildIssues = useCallback(() => {
    setLinkChildIssuesDialogOpen(true);
    setLinkItemMenuOpen(false);
  }, []);
  const onCloseLinkChildIssuesDialog = useCallback((selectedIssueIds: string[]) => {
    if (selectedIssueIds.length) {
      setFormState((prev) => {
        if (!prev) return prev;
        const currentChildrenIds = prev.childrenIds ?? [];
        const nextSelectedIssueIds = Array.from(new Set(currentChildrenIds.concat(...selectedIssueIds)));
        return { ...prev, childrenIds: nextSelectedIssueIds };
      });
    }
    setLinkChildIssuesDialogOpen(false);
  }, []);
  const onClickRemoveChildIssue = useCallback((childIssueId: string) => {
    setFormState((prev) => {
      if (!prev?.childrenIds) return prev;
      return { ...prev, childrenIds: prev.childrenIds.filter((id) => id !== childIssueId) };
    });
  }, []);

  const [selectedDocumentVersionIds, setSelectedDocumentVersionIds] = useState<string[]>([]);
  const selectedDocumentVersionIdsSet = useMemo(() => new Set(selectedDocumentVersionIds), [selectedDocumentVersionIds]);
  const resetSelection = useCallback(() => {
    setSelectedDocumentVersionIds(formState?.linkedDocumentVersionIds ?? []);
  }, [formState?.linkedDocumentVersionIds]);
  const { data: selectedDocumentVersions } = useDocumentVersionsQuery(selectedDocumentVersionIds.length ? { filter: { id: { in: selectedDocumentVersionIds } } } : undefined);
  const { data: selectedDocumentsAllVersions } = useDocumentVersionsQuery(selectedDocumentVersions?.length ? { filter: { normalizedName: { in: selectedDocumentVersions.map((v) => v.normalizedName) } } } : undefined);
  const nonSelectableVersionIds = useMemo(() => {
    if (!selectedDocumentVersionIds.length) return new Set<string>();
    if (!selectedDocumentVersionIds || !selectedDocumentsAllVersions) return undefined;
    return new Set(selectedDocumentsAllVersions.map((v) => v.id).filter((id) => !selectedDocumentVersionIdsSet.has(id)));
  }, [selectedDocumentVersionIds, selectedDocumentVersionIdsSet, selectedDocumentsAllVersions]);
  const documentSelectionContextValue = useMemo<DocumentSelectionContextState>(() => ({
    selectedDocumentVersionIds,
    selectedDocumentVersionIdsSet,
    setSelectedDocumentVersionIds,
    nonDeselectableVersionIds: new Set(),
    nonSelectableVersionIds,
    resetSelection,
  }), [nonSelectableVersionIds, resetSelection, selectedDocumentVersionIds, selectedDocumentVersionIdsSet]);
  const [linkDocumentDialogOpen, setLinkDocumentDialogOpen] = useState(false);
  const onClickLinkDocument = useCallback(() => {
    setSelectedDocumentVersionIds(formState?.linkedDocumentVersionIds ?? []);
    setLinkDocumentDialogOpen(true);
    setLinkItemMenuOpen(false);
  }, [formState?.linkedDocumentVersionIds]);
  const onCloseIssueSelectLinkedDocumentsDialog = useCallback((confirmed: boolean) => {
    if (confirmed && selectedDocumentVersionIds?.length) {
      setFormState((prev) => {
        if (!prev) return prev;
        const currentLinkedDocumentVersionIds = prev.linkedDocumentVersionIds ?? [];
        const nextSelectedDocumentVersionIds = Array.from(new Set(currentLinkedDocumentVersionIds.concat(...selectedDocumentVersionIds)));
        return { ...prev, linkedDocumentVersionIds: nextSelectedDocumentVersionIds };
      });
    }
    setLinkDocumentDialogOpen(false);
  }, [selectedDocumentVersionIds]);
  const onClickRemoveDocumentVersionItem = useCallback((documentVersionId: string) => {
    setFormState((prev) => {
      if (!prev?.linkedDocumentVersionIds) return prev;
      return { ...prev, linkedDocumentVersionIds: prev.linkedDocumentVersionIds.filter((id) => id !== documentVersionId) };
    });
  }, []);

  const [linkEmailDialogOpen, setLinkEmailDialogOpen] = useState(false);
  const disabledEmailIds = useMemo(() => (formState?.linkedEmailIds ? new Set(formState.linkedEmailIds) : undefined), [formState?.linkedEmailIds]);
  const onClickLinkEmail = useCallback(() => {
    setLinkEmailDialogOpen(true);
    setLinkItemMenuOpen(false);
  }, []);
  const onCloseLinkEmailDialog = useCallback((selectedEmailId: string | undefined) => {
    if (selectedEmailId) {
      setFormState((prev) => {
        if (!prev) return prev;
        const currentLinkedEmailIds = prev.linkedEmailIds ?? [];
        const nextSelectedEmailIds = Array.from(new Set(currentLinkedEmailIds.concat(selectedEmailId)));
        return { ...prev, linkedEmailIds: nextSelectedEmailIds };
      });
    }
    setLinkEmailDialogOpen(false);
  }, []);
  const onClickRemoveEmailItem = useCallback((emailId: string) => {
    setFormState((prev) => {
      if (!prev?.linkedEmailIds) return prev;
      return { ...prev, linkedEmailIds: prev.linkedEmailIds.filter((id) => id !== emailId) };
    });
  }, []);

  const issueTypeName = useMemo(() => (formState?.issueType ? issueTypes?.find((type) => type.id === formState.issueType)?.name : undefined), [formState, issueTypes]);
  const dueDate = useMemo(() => (formState?.dueDate ? moment(new Date(formState.dueDate)) : undefined), [formState]);
  const startingDate = useMemo(() => (formState?.startingDate ? moment(new Date(formState.startingDate)) : undefined), [formState]);

  const onClickCancel = useCallback(() => {
    setViewpointItems([]);
    onCancel();
  }, [onCancel, setViewpointItems]);

  const allowedActions = useAllowedActions();
  const canAccess3dView = useMemo(() => allowedActions?.has(RoleAction._3DModel_3D_View), [allowedActions]);

  return (
    <Dialog id="CreateIssueDialog" open={open} PaperProps={{ sx: { maxWidth: '90vw', maxHeight: '90vh', overflow: 'initial' } }}>
      <DialogTitle component="div" sx={{ display: 'flex' }}>
        <Typography variant="h3">{t('create-issue-dialog_create-title', 'Create {{issueTypeName}}', { issueTypeName })}</Typography>
        <FormControl sx={{ minWidth: 120, ml: 'auto' }}>
          <InputLabel id="create-issue-dialog_type-select-label">
            {t('create-issue-dialog_type-select-label', 'Type')}
          </InputLabel>
          <Select
            id="create-issue-dialog_type-select"
            labelId="create-issue-dialog_type-select-label"
            value={formState?.issueType ?? ''}
            onChange={onChangeIssueType}
            label={t('create-issue-dialog_type-select-label', 'Type')}
          >
            {!!issueTypes && issueTypes.map((type) => <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>)}
          </Select>
        </FormControl>
      </DialogTitle>
      <DialogContent sx={{ flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'stretch' }}>
        <Box sx={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'auto',
              borderRadius: '8px',
              boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.2)',
              backgroundColor: theme.palette.grey[200],
            }}
          >
            <Box
              sx={{
                p: 2,
                display: 'inline-flex',
                gap: 2,
              }}
            >
              <Box sx={{ flex: '0 0 auto', width: 320, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <CreateIssuePanel>
                  <Typography variant="h4">
                    {t('create-issue-dialog_general-panel-header', 'General')}
                  </Typography>
                  <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <TextField
                      label={t('create-issue-dialog_title-textfield-label', 'Title')}
                      id="create-issue-dialog_title-textfield"
                      value={formState?.title ?? ''}
                      disabled={!formState || isLoading}
                      onChange={onChangeIssueTitle}
                    />
                    <TextField
                      label={t('create-issue-dialog_description-textfield-label', 'Description')}
                      id="create-issue-dialog_description-textfield"
                      value={formState?.description ?? ''}
                      disabled={!formState || isLoading}
                      onChange={onChangeIssueDescription}
                      multiline
                      inputProps={{ style: { minHeight: '73px', maxHeight: '400px' } }}
                    />
                  </Box>
                </CreateIssuePanel>
                <CreateIssuePanel>
                  <Typography variant="h4">
                    {t('create-issue-dialog_schedule-panel-header', 'Schedule')}
                  </Typography>
                  <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <LocalizedDatePicker
                        label={t('create-issue-dialog_start-date-label', 'Start Date')}
                        value={startingDate}
                        onChange={onChangeStartingDate}
                      />
                      <LocalizedDatePicker
                        label={t('create-issue-dialog_due-date-label', 'Due Date')}
                        value={dueDate}
                        onChange={onChangeDueDate}
                      />
                    </Box>
                    <FormControl sx={{ flex: '1 1 0' }}>
                      <InputLabel id="create-issue-dialog_issue-priority-select-label">
                        {t('create-issue-dialog_issue-priority-select-label', 'Priority')}
                      </InputLabel>
                      <Select
                        id="create-issue-dialog_issue-priority-select"
                        labelId="create-issue-dialog_issue-priority-select-label"
                        value={formState?.issuePriority ?? ''}
                        onChange={onChangeIssuePriority}
                        label={t('create-issue-dialog_issue-priority-select-label', 'Priority')}
                      >
                        <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('create-issue-dialog_priority-select-empty-value-label', 'None')}</MenuItem>
                        {!!issuePriorities && issuePriorities.map((priority) => <MenuItem key={priority.id} value={priority.id}>{priority.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Box>
                </CreateIssuePanel>
              </Box>
              <Box sx={{ flex: '0 0 auto', width: 320, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <CreateIssuePanel>
                  <Typography variant="h4">
                    {t('create-issue-dialog_responsibility-panel-header', 'Responsibility')}
                  </Typography>
                  <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <AssignmentSelect sx={{ flex: '1 1 0' }} value={formState?.assignedCollaboratorIds ?? []} onChange={onChangeAssignedCollaborators} />
                    <FormControl sx={{ flex: '1 1 0' }}>
                      <InputLabel id="create-issue-dialog_reviewer-select-label">
                        {t('create-issue-dialog_reviewer-select-label', 'Reviewer')}
                      </InputLabel>
                      <Select
                        id="create-issue-dialog_reviewer-select"
                        labelId="create-issue-dialog_reviewer-select-label"
                        value={formState?.reviewerId ?? ''}
                        onChange={onChangeReviewerId}
                        label={t('create-issue-dialog_reviewer-select-label', 'Reviewer')}
                      >
                        <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('create-issue-dialog_reviewer-select-empty-value-label', 'None')}</MenuItem>
                        {!!collaborators && collaborators.map((collaborator) => <MenuItem key={collaborator.id} value={collaborator.id}>{`${collaborator.firstName} ${collaborator.lastName}`}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ flex: '1 1 0' }}>
                      <InputLabel id="create-issue-dialog_visibility-select-label">
                        {t('create-issue-dialog_visibility-select-label', 'Visibility')}
                      </InputLabel>
                      <Select
                        id="create-issue-dialog_visibility-select"
                        labelId="create-issue-dialog_visibility-select-label"
                        value={`${formState?.visibility ?? ''}`}
                        onChange={onChangeVisibility}
                        label={t('create-issue-dialog_visibility-select-label', 'Visibility')}
                      >
                        <MenuItem value={`${IssueVisibility.Public}`}>{t('create-issue-dialog_visibility-select-value-public-label', 'Public')}</MenuItem>
                        <MenuItem value={`${IssueVisibility.Private}`}>{t('create-issue-dialog_visibility-select-value-private-label', 'Private')}</MenuItem>
                        <MenuItem value={`${IssueVisibility.Restricted}`}>{t('create-issue-dialog_visibility-select-value-restricted-label', 'Group Based')}</MenuItem>
                      </Select>
                    </FormControl>
                    {formState?.visibility === IssueVisibility.Restricted && (
                    <FormControl sx={{ flex: '1 1 0' }}>
                      <InputLabel id="create-issue-dialog_visibility-groups-select-label">
                        {t('create-issue-dialog_visibility-groups-select-label', 'Groups')}
                      </InputLabel>
                      <Select
                        id="create-issue-dialog_visibility-groups-select"
                        labelId="create-issue-dialog_visibility-groups-select-label"
                        value={formState?.allowedUserGroups ?? []}
                        onChange={onChangeAllowedGroups}
                        multiple
                        label={t('create-issue-dialog_visibility-groups-select-label', 'Groups')}
                      >
                        {!!groups && groups.map((group) => (
                          <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    )}
                  </Box>
                </CreateIssuePanel>
                <CreateIssuePanel>
                  <Typography variant="h4">
                    {t('create-issue-dialog_meta-data-panel-header', 'Meta Data')}
                  </Typography>
                  <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <FormControl>
                      <InputLabel htmlFor="create-issue-dialog_tag-create-select">{t('create-issue-dialog_tags-create-select-label', 'Tags')}</InputLabel>
                      <TagCreateSelect
                        id="create-issue-dialog_tags-create-select"
                        value={formState?.tags ?? []}
                        onChange={onChangeTags}
                        label={t('create-issue-dialog_tags-create-select-label', 'Tags')}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl sx={{ flex: '1 1 0' }}>
                      <InputLabel id="create-issue-dialog_workphase-select-label">
                        {t('create-issue-dialog_workphase-select-label', 'Work Phase')}
                      </InputLabel>
                      <Select
                        id="create-issue-dialog_workphase-select"
                        labelId="create-issue-dialog_workphase-select-label"
                        value={formState?.workPhase ?? ''}
                        onChange={onChangeWorkPhase}
                        label={t('create-issue-dialog_workphase-select-label', 'Work Phase')}
                      >
                        <MenuItem value="" sx={{ fontStyle: 'italic' }}>{t('create-issue-dialog_work-phase-select-empty-value-label', 'None')}</MenuItem>
                        {!!workPhases && workPhases.map((workPhase) => <MenuItem key={workPhase.id} value={workPhase.id}>{workPhase.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Box>
                </CreateIssuePanel>
              </Box>
              <Box sx={{ flex: '0 0 auto', width: 320, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <CreateIssuePanel>
                  <Typography variant="h4">
                    {t('create-issue-dialog_model-association-panel-header', 'Model Association')}
                  </Typography>
                  <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    <FormControl sx={{ flex: '1 1 0' }}>
                      <InputLabel id="create-issue-dialog_disciplines-select-label">
                        {t('create-issue-dialog_disciplines-select-label', 'Disciplines')}
                      </InputLabel>
                      <Select
                        id="create-issue-dialog_disciplines-select"
                        labelId="create-issue-dialog_disciplines-select-label"
                        multiple
                        value={formState?.disciplines ?? []}
                        onChange={onChangeDisciplines}
                        label={t('create-issue-dialog_discipline-select-label', 'Disciplines')}
                      >
                        {!!disciplines && disciplines.map((discipline) => <MenuItem key={discipline.id} value={discipline.id}>{discipline.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ flex: '1 1 0' }}>
                      <InputLabel id="create-issue-dialog_buildings-select-label">
                        {t('create-issue-dialog_buildings-select-label', 'Buildings')}
                      </InputLabel>
                      <Select
                        id="create-issue-dialog_buildings-select"
                        labelId="create-issue-dialog_buildings-select-label"
                        multiple
                        value={formState?.buildings ?? []}
                        onChange={onChangeBuildings}
                        label={t('create-issue-dialog_building-select-label', 'Buildings')}
                      >
                        {!!buildings && buildings.map((building) => <MenuItem key={building.id} value={building.id}>{building.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ flex: '1 1 0' }}>
                      <InputLabel id="create-issue-dialog_floors-select-label">
                        {t('create-issue-dialog_floors-select-label', 'Floors')}
                      </InputLabel>
                      <Select
                        id="create-issue-dialog_floors-select"
                        labelId="create-issue-dialog_floors-select-label"
                        multiple
                        value={formState?.floors ?? []}
                        onChange={onChangeFloors}
                        label={t('create-issue-dialog_floor-select-label', 'Floors')}
                      >
                        {!!floors && floors.map((floor) => <MenuItem key={floor.id} value={floor.id}>{floor.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                  </Box>
                </CreateIssuePanel>
                <CreateIssuePanel>
                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="h4">
                      {t('create-issue-dialog_links-panel-header', 'Links')}
                    </Typography>
                    <Button onClick={onClickAddLinkButton} ref={addLinkButtonRef} variant="contained" color="secondary" sx={{ ml: 'auto' }}>
                      <Icon path={mdiLinkPlus} size={1} />
                    </Button>
                    <Menu open={linkItemMenuOpen} onClose={onCloseAddLinkMenu} anchorEl={addLinkButtonRef.current}>
                      <MenuItem sx={{ gap: 1 }} onClick={onClickLinkParentIssue}>
                        <Icon path={mdiArrowUp} size={0.75} />
                        {t('create-issue-dialog_link-item-option-parent-issue', 'Parent Issue')}
                      </MenuItem>
                      <MenuItem sx={{ gap: 1 }} onClick={onClickLinkChildIssues}>
                        <Icon path={mdiSubdirectoryArrowRight} size={0.75} />
                        {t('create-issue-dialog_link-item-option-child-issue', 'Child Issue')}
                      </MenuItem>
                      <MenuItem sx={{ gap: 1 }} onClick={onClickLinkDocument}>
                        <Icon path={mdiFileDocument} size={0.75} />
                        {t('create-issue-dialog_link-item-option-document', 'Document')}
                      </MenuItem>
                      <MenuItem sx={{ gap: 1 }} onClick={onClickLinkEmail}>
                        <Icon path={mdiEmail} size={0.75} />
                        {t('create-issue-dialog_link-item-option-email', 'Email')}
                      </MenuItem>
                    </Menu>
                    {!!linkParentIssueDialogOpen && <LinkIssueModal title={t('create-issue-dialog_select-parent-issue-modal-title', 'Select Parent Issue')} disabledIssueIds={disabledIssueIds} onClose={onCloseLinkParentIssueDialog} />}
                    {!!linkChildIssuesDialogOpen && <LinkIssueModal title={t('create-issue-dialog_link-existing-issue-modal-title', 'Link Child Issues')} disabledIssueIds={disabledIssueIds} multiple onClose={onCloseLinkChildIssuesDialog} />}
                    {!!linkDocumentDialogOpen && (
                      <DocumentSelectionContext.Provider value={documentSelectionContextValue}>
                        <IssueLinkDocumentsDialog onClose={onCloseIssueSelectLinkedDocumentsDialog} />
                      </DocumentSelectionContext.Provider>
                    )}
                    {!!linkEmailDialogOpen && <LinkEmailModal onClose={onCloseLinkEmailDialog} disabledEmailIds={disabledEmailIds} />}
                  </Box>
                  <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    {!(
                      formState?.parentId
                    || formState?.childrenIds?.length
                    || formState?.linkedEmailIds?.length
                    || formState?.linkedDocumentVersionIds?.length
                    ) && (
                    <em>{t('create-issue-dialog_links-panel-empty-text', 'No linked items')}</em>
                    )}
                    {!!parentIssue && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="h6">{t('create-issue-dialog_parent-issue-header', 'Parent Issue')}</Typography>
                      <LinkedIssuePanelItem iconPath={mdiArrowUp} issue={parentIssue} onClickRemove={onClickRemoveParent} />
                    </Box>
                    )}
                    {!!childIssues?.length && (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="h6">
                        {t('create-issue-dialog_child-issues-header', 'Child Issues')}
                        {!!isChildIssuesPlaceholderData && <CircularProgress size={12} sx={{ ml: 1 }} />}
                      </Typography>
                      {childIssues.map((childIssue) => (
                        <LinkedIssuePanelItem disabled={isChildIssuesPlaceholderData} iconPath={mdiSubdirectoryArrowRight} issue={childIssue} onClickRemove={onClickRemoveChildIssue} />
                      ))}
                    </Box>
                    )}
                    {!!linkedDocumentVersions?.length && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h5" sx={{ px: 2 }}>
                          {t('issue-linked-items-panel_documents-header', 'Documents')}
                        </Typography>
                        {linkedDocumentVersions.map((linkedDocumentVersion) => (
                          <LinkedDocumentVersionPanelItem key={linkedDocumentVersion.id} documentVersion={linkedDocumentVersion} onClickRemove={onClickRemoveDocumentVersionItem} />
                        ))}
                      </Box>
                    )}
                    {!!linkedEmails?.length && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h5" sx={{ px: 2 }}>
                          {t('issue-linked-items-panel_emails-header', 'Emails')}
                        </Typography>
                        {linkedEmails.map((linkedEmail) => (
                          <LinkedEmailPanelItem key={linkedEmail.id} email={linkedEmail} onClickRemove={onClickRemoveEmailItem} />
                        ))}
                      </Box>
                    )}
                  </Box>
                </CreateIssuePanel>
              </Box>
            </Box>
          </Box>
        </Box>
        {!!canAccess3dView && (
          <Box sx={{ flexShrink: 0, overflowX: 'hidden', display: 'flex' }}>
            <Box
              sx={{
                flexGrow: 1,
                overflowX: 'auto',
                borderRadius: '8px',
                boxShadow: 'inset 0px 0px 8px -4px rgba(0,0,0,0.2)',
                backgroundColor: theme.palette.grey[200],
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  p: 2,
                  display: 'inline-flex',
                  gap: 2,
                }}
              >
                <CreateIssueLinkedViewpointsPanel sx={{ flexGrow: 1 }} />
                <CreateIssueLinkedComponentsPanel />
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ gap: 2 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClickCancel}
          sx={{ mr: 'auto', flexShrink: 0 }}
        >
          {t('create-issue-dialog_cancel-button-label', 'Cancel')}
        </Button>
        {!!errorMessage && (
          <Dialog open>
            <DialogTitle>{t('create-issue-dialog_error-dialog-close-button-label', 'Error')}</DialogTitle>
            <DialogContent>
              <Alert severity="error">{errorMessage}</Alert>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={onCloseErrorMessage}>{t('create-issue-dialog_error-dialog-close-button-label', 'Close')}</Button>
            </DialogActions>
          </Dialog>
        )}
        <FormControlLabel
          sx={{ ml: 'auto', maxWidth: '250px', textAlign: 'right' }}
          control={<Checkbox checked={keepDialogOpen && !suppressKeepOpen} onChange={onChangeKeepDialogOpen} disabled={suppressKeepOpen} />}
          label={t('create-issue-dialog_keep-dialog-open-checkbox-label', 'Keep dialog open to create another issue')}
          labelPlacement="start"
        />
        <Button
          id="CreateIssueDialogSaveDraftButton"
          variant="contained"
          color="secondary"
          onClick={onClickSaveDraft}
          disabled={isLoading || !formState?.title?.trim().length}
          sx={{ gap: 1, flexShrink: 0 }}
        >
          {!!isLoading && <CircularProgress size={12} />}
          {t('create-issue-dialog_confirm-save-draft-button-label', 'Save Draft')}
        </Button>
        <Button
          id="CreateIssueDialogConfirmCreatePublishButton"
          variant="contained"
          color="primary"
          onClick={onClickCreateAndPublish}
          disabled={isLoading || !formState?.title?.trim().length}
          sx={{ gap: 1, flexShrink: 0 }}
        >
          {!!isLoading && <CircularProgress size={12} />}
          {t('create-issue-dialog_confirm-create-and-publish-button-label', 'Create & Publish')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
