import React, { Suspense, useCallback, useState } from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';
import { CircularProgress, Link, useTheme } from '@mui/material';
import { Translation } from 'react-i18next';
import ConfirmRemoveCollaboratorDialog from 'collaborators/components/ConfirmRemoveCollaboratorDialog';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';

export default function RemoveCell({
  data,
}: ICellRendererParams<CollaboratorsDataGridRow, string>) {
  const currentUserRole = useCurrentCollaboratorRole();
  const theme = useTheme();
  const [isConfirmRemoveCollaboratorDialogOpen, setIsConfirmRemoveCollaboratorDialogOpen] = useState(false);
  const onClickRemove = useCallback(() => setIsConfirmRemoveCollaboratorDialogOpen(true), []);
  const onCloseConfirmDialog = useCallback(() => setIsConfirmRemoveCollaboratorDialogOpen(false), []);
  if (!data?.userGroups || !currentUserRole?.allowedActions?.has(RoleAction.Project_EditMembers)) return null;
  return (
    <Suspense fallback={<CircularProgress size={12} />}>
      <Translation ns="collaborators">
        {(t) => (
          <Link color={theme.palette.text.secondary} onClick={onClickRemove}>{t('collaborators-data-grid_remove-cell-button-label', 'Remove user')}</Link>
        )}
      </Translation>
      {isConfirmRemoveCollaboratorDialogOpen && <ConfirmRemoveCollaboratorDialog collaborator={data} onClose={onCloseConfirmDialog} />}
    </Suspense>
  );
}
