import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import CollaboratorDto from 'collaborators/types/CollaboratorDto';
import HorizontalDivider from 'common/styled/HorizontalDivider';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DocumentListAccessIds } from 'documents-lists/components/PlanlistPermissionsPanel';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';

interface CollaboratorAccessRightItem {
  collaborator: CollaboratorDto,
  hasWriteAccess: boolean,
  isUserLevelReadOnly: boolean,
  readOnlyGroups: UserGroupDto[],
  isUserLevelAccessGranted?: boolean,
  readWriteGroups?: UserGroupDto[],
}

interface EffectiveAccessRightsDialogProps {
  accessIds: DocumentListAccessIds,
  onClose: () => void,
}

function VisibilityChipLabel({
  caption,
  readOnly,
}: {
  caption: string,
  readOnly?: boolean
}) {
  return (
    <Box sx={{ display: 'flex' }}>
      {caption}
      <VisibilityIcon sx={{
        width: '12px', height: '12px', ml: 1, mt: '1px',
      }}
      />
      {!readOnly && <EditIcon sx={{ width: '12px', height: '12px' }} />}
    </Box>
  );
}

export default function EffectiveAccessRightsDialog({
  accessIds,
  onClose,
}: EffectiveAccessRightsDialogProps) {
  const { t } = useTranslation('documents-lists');
  const { data: collaborators } = useCollaboratorsQuery();
  const { data: userGroupsData } = useUserGroupsQuery();

  const groupsById = useMemo(() => new Map<string, UserGroupDto>(userGroupsData ? userGroupsData.map((g) => [g.id, g]) : undefined), [userGroupsData]);
  const accessGroupIdsSet = useMemo(() => new Set(accessIds.readAccessGroupIds), [accessIds.readAccessGroupIds]);
  const accessCollaboratorIdsSet = useMemo(() => new Set(accessIds.readAccessCollaboratorIds), [accessIds.readAccessCollaboratorIds]);
  const writeAccessGroupIdsSet = useMemo(() => new Set(accessIds.writeAccessGroupIds), [accessIds.writeAccessGroupIds]);
  const writeAccessCollaboratorIdsSet = useMemo(() => new Set(accessIds.writeAccessCollaboratorIds), [accessIds.writeAccessCollaboratorIds]);
  const readOnlyAccessGroupIdsSet = useMemo(() => new Set(accessIds.readAccessGroupIds.filter((id) => !writeAccessGroupIdsSet.has(id))), [accessIds.readAccessGroupIds, writeAccessGroupIdsSet]);
  const readOnlyaccessCollaboratorIdsSet = useMemo(() => new Set(accessIds.readAccessCollaboratorIds.filter((id) => !writeAccessCollaboratorIdsSet.has(id))), [accessIds.readAccessCollaboratorIds, writeAccessCollaboratorIdsSet]);

  const readOnlyGroupsByUserIdsMap = useMemo(() => {
    if (!userGroupsData || !collaborators) return undefined; // init
    const map = new Map<string, Set<string>>(collaborators.map((c) => [c.id, new Set<string>()]));
    userGroupsData.filter((g) => readOnlyAccessGroupIdsSet?.has(g.id)).forEach((g) => g.collaboratorIds.map((userId) => map.get(userId)?.add(g.id)));
    return map;
  }, [collaborators, readOnlyAccessGroupIdsSet, userGroupsData]);

  const accessGroupIdsByUserIdsMap = useMemo(() => {
    if (!userGroupsData || !collaborators) return undefined; // init
    const map = new Map<string, Set<string>>(collaborators.map((c) => [c.id, new Set<string>()]));
    userGroupsData.filter((g) => accessGroupIdsSet?.has(g.id)).forEach((g) => g.collaboratorIds.map((userId) => map.get(userId)?.add(g.id)));
    return map;
  }, [accessGroupIdsSet, collaborators, userGroupsData]);

  const collaboratorAccessRightItems = useMemo<CollaboratorAccessRightItem[] | undefined>(() => {
    if (!collaborators || !userGroupsData || !readOnlyaccessCollaboratorIdsSet || !readOnlyAccessGroupIdsSet || !readOnlyGroupsByUserIdsMap) return undefined; // init
    if (!accessGroupIdsByUserIdsMap || !accessCollaboratorIdsSet) return undefined;
    return collaborators
      .filter((collaborator) => !!accessGroupIdsByUserIdsMap.get(collaborator.id)?.size || accessCollaboratorIdsSet.has(collaborator.id))
      .map((collaborator) => {
        const isUserLevelAccessGranted = accessCollaboratorIdsSet.has(collaborator.id);
        const isUserLevelReadOnly = readOnlyaccessCollaboratorIdsSet.has(collaborator.id);
        const readOnlyGroups = Array.from(accessGroupIdsByUserIdsMap.get(collaborator.id) ?? []).filter((groupId) => !!readOnlyAccessGroupIdsSet.has(groupId)).map((groupId) => groupsById.get(groupId)!);
        const readWriteGroups = Array.from(accessGroupIdsByUserIdsMap.get(collaborator.id) ?? []).filter((groupId) => !readOnlyAccessGroupIdsSet.has(groupId)).map((groupId) => groupsById.get(groupId)!);
        const hasWriteAccess = (isUserLevelAccessGranted && !isUserLevelReadOnly) || !!readWriteGroups.length;
        const item: CollaboratorAccessRightItem = {
          collaborator,
          hasWriteAccess,
          isUserLevelAccessGranted,
          isUserLevelReadOnly,
          readOnlyGroups,
          readWriteGroups,
        };
        return item;
      });
  }, [collaborators, userGroupsData, readOnlyaccessCollaboratorIdsSet, readOnlyAccessGroupIdsSet, readOnlyGroupsByUserIdsMap, accessGroupIdsByUserIdsMap, accessCollaboratorIdsSet, groupsById]);

  return (
    <Dialog open PaperProps={{ sx: { maxWidth: '1000px' } }}>
      <DialogTitle component="div">
        <Typography variant="h2">{t('effective-access-rights-dialog_title', 'Effective Access Rights')}</Typography>
        <Typography sx={{ mt: 1 }}>{t('effective-access-rights-dialog_subtitle', 'With the current access right configuration, the following collaborators will have access to this plan list.')}</Typography>
      </DialogTitle>
      <HorizontalDivider />
      <DialogContent>
        {collaboratorAccessRightItems?.length === 0 && <Alert severity="info">{t('effective-access-rights-dialog_no-collaborator-has-access-message', 'With the current access right configuration, none of the project collaborators will have effective access to this plan list.')}</Alert>}
        {collaboratorAccessRightItems && collaboratorAccessRightItems.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: '200px' }}>{t('effective-access-rights-dialog_name-column-header', 'Name')}</TableCell>
              <TableCell sx={{ minWidth: '150px' }}>{t('effective-access-rights-dialog_effective-rights-column-header', 'Access rights')}</TableCell>
              <TableCell sx={{ minWidth: '200px' }}>
                {t('effective-access-rights-dialog_granted-through-column-header', 'Rights granted through')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collaboratorAccessRightItems?.map((item) => (
              <TableRow key={item.collaborator.id}>
                <TableCell>{`${item.collaborator.firstName} ${item.collaborator.lastName}`}</TableCell>
                <TableCell>
                  {item.hasWriteAccess
                    ? <Chip sx={{ borderRadius: '16px' }} variant="outlined" color="info" label={t('effective-access-rights-dialog_write-right-chip-label', 'Read & Write')} />
                    : <Chip sx={{ borderRadius: '16px' }} variant="outlined" label={t('effective-access-rights-dialog_read-right-chip-label', 'Read')} />}
                </TableCell>
                <TableCell sx={{
                  display: 'flex', alignItems: 'center', columnGap: 1, py: 0, height: '44px',
                }}
                >
                  {!!item.isUserLevelReadOnly && (
                    <Chip
                      size="small"
                      color="info"
                      icon={<PersonIcon sx={{ width: '12px', height: '12px' }} />}
                      label={<VisibilityChipLabel caption={`${item.collaborator.firstName} ${item.collaborator.lastName}`} readOnly />}
                    />
                  )}
                  {!!item.isUserLevelAccessGranted && !item.isUserLevelReadOnly && (
                    <Chip
                      size="small"
                      color="info"
                      icon={<PersonIcon sx={{ width: '12px', height: '12px' }} />}
                      label={<VisibilityChipLabel caption={`${item.collaborator.firstName} ${item.collaborator.lastName}`} />}
                    />
                  )}
                  {!!item.readWriteGroups && item.readWriteGroups.map((g) => (
                    <Chip
                      size="small"
                      color="default"
                      key={g.id}
                      icon={<GroupIcon sx={{ width: '12px', height: '12px' }} />}
                      label={<VisibilityChipLabel caption={g.name} />}
                    />
                  ))}
                  {!!item.readOnlyGroups && item.readOnlyGroups.map((g) => (
                    <Chip
                      size="small"
                      color="default"
                      key={g.id}
                      icon={<GroupIcon sx={{ width: '12px', height: '12px' }} />}
                      label={<VisibilityChipLabel caption={g.name} readOnly />}
                    />
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          {t('effective-access-rights-dialog_close-button-label', 'Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
