import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import useLabelsOdataQuery from 'labels/hooks/useLabelsOdataQuery';
import ProjectNamingGroupSeparatorType from 'naming-schemes/types/ProjectNamingGroupSeparatorType';
import NamingSchemeGroupType from 'naming-schemes/types/NamingSchemeGroupType';
import { useTranslation } from 'react-i18next';
import EditNamingSchemeGroupDialog from 'naming-schemes/components/EditNamingSchemeGroupDialog';
import EditNamingSchemeGroupSeparatorDialog from 'naming-schemes/components/EditNamingSchemeGroupSeparatorDialog';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import useNamingSchemeDateTypeTranslations from 'naming-schemes/hooks/useNamingSchemeDateTypeTranslations';
import { range } from 'lodash';

interface NamingSchemeGroupsPanelProps extends ISxProps {
  groupItems: NamingSchemeGroupItem[],
  onChange: (groups: NamingSchemeGroupItem[]) => void | Promise<void>,
}

export default function NamingSchemeGroupsPanel({
  sx,
  groupItems,
  onChange,
}: NamingSchemeGroupsPanelProps) {
  const { t } = useTranslation('naming-schemes');
  const theme = useTheme();
  const { data: labels } = useLabelsOdataQuery({ filter: { isDeleted: false }, orderBy: 'order asc' });
  const labelsById = useMemo(() => (labels ? new Map() : undefined), [labels]);

  const [groupItemUuidCurrentlyEditing, setGroupItemUuidCurrentlyEditing] = useState<string | undefined>(undefined);
  const groupItemCurrentlyEditing = useMemo<NamingSchemeGroupItem | undefined>(() => (groupItemUuidCurrentlyEditing !== undefined ? groupItems.find(({ uuid }) => uuid === groupItemUuidCurrentlyEditing) : undefined), [groupItemUuidCurrentlyEditing, groupItems]);
  const onCloseEditGroupDialog = useCallback(async (groupItem: NamingSchemeGroupItem | undefined) => {
    if (!groupItem || groupItemUuidCurrentlyEditing === undefined) {
      setGroupItemUuidCurrentlyEditing(undefined);
      return;
    }
    const nextGroupItems = [...groupItems];
    const index = groupItems.findIndex((item) => item.uuid === groupItemUuidCurrentlyEditing);
    if (index === -1) throw new Error('invalid index');
    nextGroupItems[index] = groupItem;
    await onChange(nextGroupItems);
    setGroupItemUuidCurrentlyEditing(undefined);
  }, [groupItemUuidCurrentlyEditing, groupItems, onChange]);

  const onRemoveGroup = useCallback(async () => {
    await onChange(groupItems.filter((item) => item.uuid !== groupItemUuidCurrentlyEditing));
    setGroupItemUuidCurrentlyEditing(undefined);
  }, [groupItemUuidCurrentlyEditing, groupItems, onChange]);

  const [separatorGroupItemUuidCurrentlyEditing, setSeparatorGroupItemUuidCurrentlyEditing] = useState<string | undefined>(undefined);
  const separatorCurrentlyEditing = useMemo<ProjectNamingGroupSeparatorType | undefined>(() => (separatorGroupItemUuidCurrentlyEditing !== undefined ? groupItems.find((groupItem) => groupItem.uuid === separatorGroupItemUuidCurrentlyEditing)?.separator : undefined), [groupItems, separatorGroupItemUuidCurrentlyEditing]);
  const onCloseEditSeparatorDialog = useCallback(async (value: ProjectNamingGroupSeparatorType | undefined) => {
    if (value === undefined || separatorGroupItemUuidCurrentlyEditing === undefined) {
      setSeparatorGroupItemUuidCurrentlyEditing(undefined);
      return;
    }
    const nextGroupItems = [...groupItems];
    const index = groupItems.findIndex((item) => item.uuid === separatorGroupItemUuidCurrentlyEditing);
    if (index === -1) throw new Error('invalid index');
    nextGroupItems[index].separator = value;
    await onChange(nextGroupItems);
    setSeparatorGroupItemUuidCurrentlyEditing(undefined);
  }, [groupItems, onChange, separatorGroupItemUuidCurrentlyEditing]);

  const namingSchemeDateTypeTranslations = useNamingSchemeDateTypeTranslations();
  const groupListItems = useMemo(() => groupItems.map((groupItem) => {
    let preview = ' ';
    if (groupItem.type === NamingSchemeGroupType.CreationDate) {
      if (groupItem.groupElements?.[0]?.dateType !== undefined) {
        preview = namingSchemeDateTypeTranslations[groupItem.groupElements?.[0]?.dateType];
      }
    } else if (groupItem.type === NamingSchemeGroupType.Version) {
      const length = groupItem.groupElements?.[0]?.length;
      const versionAsLetter = groupItem.groupElements?.[0]?.versionAsLetter;
      if (length !== undefined) {
        preview = range(0, length).map(() => (versionAsLetter ? 'A' : '0')).join('');
      }
    } else if (groupItem.type === NamingSchemeGroupType.FreeText) {
      preview = t('naming-scheme-groups-panel_free-text-preview', 'Example');
    } else if (groupItem.type === NamingSchemeGroupType.FreeNumber) {
      preview = t('naming-scheme-groups-panel_free-number-preview', '12345');
    } else if (groupItem.type === NamingSchemeGroupType.Number) {
      if (groupItem.length) {
        preview = range(0, groupItem.length).map(() => '0').join('');
      }
    } else if (groupItem.type === NamingSchemeGroupType.Chars) {
      if (groupItem.length) {
        preview = range(0, groupItem.length).map(() => 'A').join('');
      }
    } else {
      preview = groupItem.groupElements?.[0]?.abbreviation ?? '';
    }
    return {
      groupItem,
      preview,
      onClickEdit: () => setGroupItemUuidCurrentlyEditing(groupItem.uuid),
      onClickEditSeparator: () => setSeparatorGroupItemUuidCurrentlyEditing(groupItem.uuid),
    };
  }), [groupItems, namingSchemeDateTypeTranslations, t]);

  const alreadyExistingGroupTypes = useMemo(() => {
    if (!groupItemCurrentlyEditing) return [];
    const otherUsedTypes = groupItems.filter((item) => item.uuid !== groupItemCurrentlyEditing.uuid).map((item) => item.type);
    return otherUsedTypes;
  }, [groupItemCurrentlyEditing, groupItems]);

  return (
    <Box id="NamingSchemeGroupsPanel" sx={sx}>
      {(!labels || !labelsById) && <CenteredCircularProgress />}
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2, p: 2 }}>
        {labels && labelsById && groupListItems && groupListItems.map(({ groupItem, preview, onClickEdit, onClickEditSeparator }, index) => (
          <Fragment key={groupItem.uuid}>
            <Box
              sx={{
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                backgroundColor: theme.palette.background.default,
                p: 2,
                borderRadius: '8px',
                boxShadow: '0px 0px 16px -4px rgba(0,0,0,0.4)',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5">{groupItem.name}</Typography>
                <Typography>{NamingSchemeGroupType[groupItem.type]}</Typography>
              </Box>
              <Typography sx={{ fontSize: 24, px: 1, textAlign: 'center', fontWeight: 'bold', border: `2px dotted ${theme.palette.grey[400]}` }}>
                {preview}
              </Typography>
              <Button
                onClick={onClickEdit}
                size="small"
                variant="contained"
                color="secondary"
                sx={{ mb: 'auto' }}
              >
                {t('naming-scheme-groups-panel_separator-edit-button-label', 'Edit')}
              </Button>
              {groupItem.groupElements.length > 0
              && groupItem.type !== NamingSchemeGroupType.Version
              && groupItem.type !== NamingSchemeGroupType.CreationDate
              && groupItem.type !== NamingSchemeGroupType.Project
              && (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Typography variant="h5" sx={{ mt: 2, textAlign: 'center' }}>
                    {`${t('naming-scheme-groups-panel_values-table-title', 'Possible Values')}:`}
                  </Typography>
                  <Box sx={{ display: 'grid', gridTemplateColumns: '1fr auto', columnGap: 2 }}>
                    {groupItem.groupElements.map((element) => (
                      <Fragment key={element.uuid}>
                        <Typography sx={{ fontFamily: 'monospace', fontWeight: 600 }}>{element.abbreviation}</Typography>
                        <Typography sx={{ color: theme.palette.text.secondary }}>{element.name}</Typography>
                      </Fragment>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
            {(index !== groupItems.length - 1) && (
            <Box
              sx={{
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                backgroundColor: theme.palette.background.default,
                p: 2,
                borderRadius: '8px',
                boxShadow: '0px 0px 16px -4px rgba(0,0,0,0.4)',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5">
                  {groupItem.separator === ProjectNamingGroupSeparatorType.Dash && t('naming-scheme-groups-panel_separator-dash', 'Dash')}
                  {groupItem.separator === ProjectNamingGroupSeparatorType.Underscore && t('naming-scheme-groups-panel_separator-underscore', 'Underscore')}
                  {groupItem.separator === ProjectNamingGroupSeparatorType.Dot && t('naming-scheme-groups-panel_separator-dot', 'Dot')}
                </Typography>
                <Typography>
                  {t('naming-scheme-groups-panel_separator-header', 'Separator')}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: 24, minWidth: 32, textAlign: 'center', fontWeight: 'bold', border: `2px dotted ${theme.palette.grey[400]}` }}>
                {groupItem.separator === ProjectNamingGroupSeparatorType.Dash && '-'}
                {groupItem.separator === ProjectNamingGroupSeparatorType.Underscore && '_'}
                {groupItem.separator === ProjectNamingGroupSeparatorType.Dot && '.'}
              </Typography>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                sx={{ mb: 'auto' }}
                onClick={onClickEditSeparator}
              >
                {t('naming-scheme-groups-panel_separator-edit-button-label', 'Edit')}
              </Button>
            </Box>
            )}
          </Fragment>
        ))}
      </Box>
      {!!groupItemCurrentlyEditing && <EditNamingSchemeGroupDialog value={groupItemCurrentlyEditing} onClose={onCloseEditGroupDialog} onRemove={onRemoveGroup} removeDisabled={groupItems.length <= 2} alreadyExistingGroupTypes={alreadyExistingGroupTypes} />}
      {separatorCurrentlyEditing !== undefined && <EditNamingSchemeGroupSeparatorDialog initialValue={separatorCurrentlyEditing} onClose={onCloseEditSeparatorDialog} />}
    </Box>
  );
}
