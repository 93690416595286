import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import { mdiAccountHardHat, mdiAccountCheck, mdiEye } from '@mdi/js';
import Icon from '@mdi/react';
import IssueAssignmentActionButtons from 'issues/components/IssueAssignmentActionButtons';
import ReviewActionButtons from 'issues/components/ReviewActionButtons';
import PublishActionButtons from 'issues/components/PublishActionButtons';
import IssueAssignActionButton from 'issues/components/IssueAssignActionButton';
import ReopenActionButton from 'issues/components/ReopenActionButton';
import IssueVisibility from 'issues/types/IssueVisibility';
import useUserGroupsOdataQuery from 'users-groups/hooks/useUserGroupsOdataQuery';
import useIssueVisibilityTranslations from 'issues/hooks/useIssueVisibilityTranslations';
import EditVisibilityDialog from 'issues/components/EditVisibilityDialog';
import useIssueStatusEditingRoleRight from 'issues/hooks/useIssueStatusEditingRoleRight';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import useIssueEditingRoleRight from 'issues/hooks/useIssueEditingRoleRight';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';

interface DocumentDetailsGeneralSectionProps {
  issueId: string,
  onClickCompleteAssignment: () => void,
  onClickRejectAssignment: () => void,
  onClickRejectReview: () => void,
  onClickApproveReview: () => void,
  onClickPublish: () => void,
  onClickAssign: () => void,
  onClickReopen: () => void,
  nonInteractive?: boolean | undefined,
}

export default function IssueResponsibilityPanel({
  issueId,
  onClickCompleteAssignment,
  onClickRejectAssignment,
  onClickRejectReview,
  onClickApproveReview,
  onClickPublish,
  onClickAssign,
  onClickReopen,
  nonInteractive,
}: DocumentDetailsGeneralSectionProps) {
  const theme = useTheme();
  const { t } = useTranslation('issues');
  const { data: issue } = useIssueQuery(issueId);
  const { data: groups } = useUserGroupsOdataQuery(issue?.visibility === IssueVisibility.Restricted ? { filter: { id: { in: issue.allowedUserGroups }, isDeleted: false } } : undefined);
  const visibilityTranslations = useIssueVisibilityTranslations();
  const visibilityString = useMemo(() => {
    if (!issue) return undefined;
    if (issue.visibility === IssueVisibility.Restricted) {
      if (!groups) return undefined;
      return `${visibilityTranslations[issue.visibility]} (${groups.map((g) => g.name).join(', ')})`;
    }
    return visibilityTranslations[issue.visibility];
  }, [groups, issue, visibilityTranslations]);
  const [editVisibilityDialogOpen, setEditVisibilityDialogOpen] = useState(false);
  const onClickEditVisibility = useCallback(() => setEditVisibilityDialogOpen(true), []);
  const onCloseEditVisiblityDialog = useCallback(() => setEditVisibilityDialogOpen(false), []);

  const canEdit = useIssueEditingRoleRight(issueId);
  const canEditStatus = useIssueStatusEditingRoleRight(issueId);
  const allowedActions = useAllowedActions();
  const canCreateComments = useMemo(() => allowedActions?.has(RoleAction.IssueManagement_CreatingComments), [allowedActions]);

  const closedIssueStatus = useClosedIssueStatus();
  const visibilityButtonDisabled = useMemo(() => issue?.issueStatus === closedIssueStatus?.id, [closedIssueStatus, issue?.issueStatus]);

  if (!issue) return null;
  return (
    <Box id="IssueResponsibilityPanel">
      <Box sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      >
        <Typography variant="h4">{t('issue-responsibility-panel_header', 'Responsibility')}</Typography>
        {!nonInteractive && !!canEditStatus && !!canCreateComments && (
          <>
            <IssueAssignmentActionButtons issueId={issueId} onClickCompleteAssignment={onClickCompleteAssignment} onClickRejectAssignment={onClickRejectAssignment} />
            <IssueAssignActionButton issueId={issueId} onClickAssign={onClickAssign} />
            <ReopenActionButton issueId={issueId} onClickReopen={onClickReopen} />
            <ReviewActionButtons issueId={issueId} onClickRejectReview={onClickRejectReview} onClickApproveReview={onClickApproveReview} />
            <PublishActionButtons issueId={issueId} onClickPublish={onClickPublish} />
          </>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {!!issue.assignedUsers?.length && issue.assignedUsers.map((assignedUser) => (
            <Box key={assignedUser.id} sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Box sx={{ backgroundColor: theme.palette.grey[200], borderRadius: 99, width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon path={mdiAccountHardHat} size={0.8} />
              </Box>
              <Box>
                <Typography sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.25,
                }}
                >
                  {`${assignedUser.firstName} ${assignedUser.lastName}`}
                </Typography>
                <Typography sx={{ color: theme.palette.grey[800], lineHeight: 1.25 }}>{t('linked-issue-item_assignee-label', 'Assigned')}</Typography>
              </Box>
            </Box>
          ))}
          {(issue?.assignedUsers?.length === 0) && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Box sx={{ backgroundColor: theme.palette.grey[100], borderRadius: 99, width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon path={mdiAccountHardHat} size={0.8} color={theme.palette.grey[600]} />
              </Box>
              <Box>
                <Typography sx={{
                  fontSize: 14,
                  lineHeight: 1.25,
                  color: theme.palette.grey[600],
                }}
                >
                  {t('issue-responsibility-panel_unassigned-label', 'Not assigned')}
                </Typography>
              </Box>
            </Box>
          )}
          {!!issue.assignedUsers?.length && !!issue.reviewer && (
            <Divider />
          )}
          {!!issue.reviewer && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              <Box sx={{ backgroundColor: theme.palette.grey[200], borderRadius: 99, width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Icon path={mdiAccountCheck} size={0.8} />
              </Box>
              <Box>
                <Typography sx={{
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: 1.25,
                }}
                >
                  {`${issue.reviewer.firstName} ${issue.reviewer.lastName}`}
                </Typography>
                <Typography sx={{ color: theme.palette.grey[800], lineHeight: 1.25 }}>{t('linked-issue-item_reviewer-label', 'Reviewer')}</Typography>
              </Box>
            </Box>
          )}
          <Divider />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
              <Typography variant="h5">{t('issue-responsibility-panel_visibility-subheader', 'Visibility')}</Typography>
              {!!canEdit && !nonInteractive && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  sx={{ pl: 1, gap: 0.5 }}
                  onClick={onClickEditVisibility}
                  disabled={visibilityButtonDisabled}
                >
                  <Icon path={mdiEye} size={0.75} />
                  {t('issue-responsibility-panel_edit-button-label', 'Change')}
                </Button>
              )}
            </Box>
            <Typography>{visibilityString}</Typography>
            {!!editVisibilityDialogOpen && <EditVisibilityDialog issueId={issueId} onClose={onCloseEditVisiblityDialog} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
