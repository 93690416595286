import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Alert, Box, Checkbox, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import NamingSchemeGroupType from 'naming-schemes/types/NamingSchemeGroupType';
import MutateNamingSchemeGroupLabelElementsPanel, { LABEL_TYPE_BY_GROUP_TYPE } from 'naming-schemes/components/MutateNamingSchemeGroupLabelElementsPanel';
import NamingSchemeGroupElementItem from 'naming-schemes/types/NamingSchemeGroupElementItem';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import MutateNamingSchemeDateGroupPanel from 'naming-schemes/components/MutateNamingSchemeDateGroupPanel';
import MutateNamingSchemeVersionGroupPanel from 'naming-schemes/components/MutateNamingSchemeVersionGroupPanel';
import MutateNamingSchemeCharactersGroupPanel from 'naming-schemes/components/MutateNamingSchemeCharactersGroupPanel';
import MutateNamingSchemeProjectGroupPanel from 'naming-schemes/components/MutateNamingSchemeProjectGroupPanel';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';

interface MutateNamingSchemeGroupPanelProps extends ISxProps {
  formState: NamingSchemeGroupItem,
  onChange: (value: NamingSchemeGroupItem) => void,
  alreadyExistingGroupTypes: NamingSchemeGroupType[],
}

export default function MutateNamingSchemeGroupPanel({
  sx,
  formState,
  alreadyExistingGroupTypes,
  onChange,
}: MutateNamingSchemeGroupPanelProps) {
  const { t } = useTranslation('naming-schemes');
  const { data: currentProject } = useCurrentProjectQuery();

  const [groupElementItemsByType, setGroupElementItemsByType] = useState<Map<NamingSchemeGroupType, NamingSchemeGroupElementItem[]>>(new Map(formState.type !== undefined && formState.groupElements ? [[formState.type, formState.groupElements]] : []));

  const onChangeElements = useCallback((value: NamingSchemeGroupElementItem[]) => {
    const nextMap = new Map(groupElementItemsByType);
    nextMap.set(formState.type, value);
    setGroupElementItemsByType(nextMap);
    onChange({ ...formState, groupElements: value });
  }, [formState, groupElementItemsByType, onChange]);

  const {
    buildingTypeDisabled,
    floorTypeDisabled,
    workPhaseTypeDisabled,
    creationDateTypeDisabled,
    docStatusTypeDisabled,
    versionTypeDisabled,
  } = useMemo(() => {
    const alreadyExistingTypesSet = new Set(alreadyExistingGroupTypes);
    return {
      buildingTypeDisabled: alreadyExistingTypesSet.has(NamingSchemeGroupType.Building),
      floorTypeDisabled: alreadyExistingTypesSet.has(NamingSchemeGroupType.Floor),
      workPhaseTypeDisabled: alreadyExistingTypesSet.has(NamingSchemeGroupType.WorkPhases),
      creationDateTypeDisabled: alreadyExistingTypesSet.has(NamingSchemeGroupType.CreationDate),
      docStatusTypeDisabled: alreadyExistingTypesSet.has(NamingSchemeGroupType.DocStatus),
      versionTypeDisabled: alreadyExistingTypesSet.has(NamingSchemeGroupType.Version),
    };
  }, [alreadyExistingGroupTypes]);

  const onChangeType = useCallback(async (event: SelectChangeEvent<NamingSchemeGroupType>) => {
    if (!currentProject) throw new Error('dependency error');
    const nextType = typeof event.target.value === 'string' ? (parseInt(event.target.value, 10) as NamingSchemeGroupType) : event.target.value;
    const nextFormState = { ...formState, type: nextType };

    if (!groupElementItemsByType.has(nextType)) {
      nextFormState.groupElements = nextType === NamingSchemeGroupType.Project ? [{ ...formState.groupElements[0], abbreviation: currentProject.abbreviation }] : [];
      const nextMap = new Map(groupElementItemsByType);
      nextMap.set(nextType, nextFormState.groupElements);
      setGroupElementItemsByType(nextMap);
    }

    nextFormState.excludeFromCleanName = nextType === NamingSchemeGroupType.CreationDate || nextType === NamingSchemeGroupType.Version || nextType === NamingSchemeGroupType.DocStatus;
    onChange(nextFormState);
  }, [currentProject, formState, groupElementItemsByType, onChange]);

  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => onChange({ ...formState, name: event.target.value }), [formState, onChange]);
  const onChangeIsOptional = useCallback((event: ChangeEvent<HTMLInputElement>) => onChange({ ...formState, isOptional: event.target.checked }), [formState, onChange]);
  const onChangeExcludeFromCleanName = useCallback((event: ChangeEvent<HTMLInputElement>) => onChange({ ...formState, excludeFromCleanName: event.target.checked }), [formState, onChange]);
  const onChangeLength = useCallback((value: number | undefined) => onChange({ ...formState, length: value }), [formState, onChange]);

  const onChangeAbbreviation = useCallback((value: string) => onChangeElements([{ ...formState.groupElements[0], abbreviation: value }]), [formState.groupElements, onChangeElements]);

  const isLabelGroupType = useMemo(() => (formState.type !== undefined ? LABEL_TYPE_BY_GROUP_TYPE.has(formState.type) : undefined), [formState.type]);
  const typeGroupElements = useMemo(() => (formState.type !== undefined ? (groupElementItemsByType.get(formState.type) ?? []) : undefined), [formState.type, groupElementItemsByType]);

  return (
    <Box id="MutateNamingSchemeGroupPanel" sx={{ display: 'flex', gap: 4, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Typography variant="h4">{t('mutate-naming-scheme-group-panel_title', 'General Group Options')}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label={t('mutate-naming-scheme-group-panel_name-textfield-label', 'Name')}
            id="mutate-naming-scheme-group-panel_name-textfield"
            value={formState.name}
            onChange={onChangeName}
          />
          <FormControl>
            <InputLabel id="mutate-naming-scheme-group-panel_group-type-select-label">{t('mutate-naming-scheme-group-panel_group-type-select-label', 'Group Type')}</InputLabel>
            <Select<NamingSchemeGroupType>
              value={formState.type}
              label={t('mutate-naming-scheme-group-panel_group-type-select-label', 'Group Type')}
              onChange={onChangeType}
            >
              <MenuItem value={NamingSchemeGroupType.Disciplines}>{t('mutate-naming-scheme-group-panel_group-type-disciplines', 'Disciplines')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.Building} disabled={buildingTypeDisabled}>{t('mutate-naming-scheme-group-panel_group-type-building', 'Building')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.Floor} disabled={floorTypeDisabled}>{t('mutate-naming-scheme-group-panel_group-type-floor', 'Floor')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.WorkPhases} disabled={workPhaseTypeDisabled}>{t('mutate-naming-scheme-group-panel_group-type-WorkPhases', 'Work Phases')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.CreationDate} disabled={creationDateTypeDisabled}>{t('mutate-naming-scheme-group-panel_group-type-CreationDate', 'Creation Date')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.Version} disabled={versionTypeDisabled}>{t('mutate-naming-scheme-group-panel_group-type-Version', 'Version')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.FreeText}>{t('mutate-naming-scheme-group-panel_group-type-FreeText', 'Free Text')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.FreeNumber}>{t('mutate-naming-scheme-group-panel_group-type-FreeNumber', 'Free Text (Numeric)')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.Number}>{t('mutate-naming-scheme-group-panel_group-type-Number', 'Number')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.Chars}>{t('mutate-naming-scheme-group-panel_group-type-Chars', 'Characters')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.DocStatus} disabled={docStatusTypeDisabled}>{t('mutate-naming-scheme-group-panel_group-type-DocStatus', 'Document Status')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.Project}>{t('mutate-naming-scheme-group-panel_group-type-Project', 'Project')}</MenuItem>
              <MenuItem value={NamingSchemeGroupType.Custom}>{t('mutate-naming-scheme-group-panel_group-type-Custom', 'Custom')}</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={<Checkbox checked={formState.excludeFromCleanName} onChange={onChangeExcludeFromCleanName} />}
            label={t('mutate-naming-scheme-group-panel_exclude-from-clean-name-checkbox-label', 'Exclude from clean name')}
          />
          <FormControlLabel
            control={<Checkbox checked={formState.isOptional} onChange={onChangeIsOptional} />}
            label={t('mutate-naming-scheme-group-panel_is-optional-checkbox-label', 'Optional')}
          />
        </Box>
      </Box>
      {formState.type !== undefined && formState.type !== NamingSchemeGroupType.FreeNumber && <Divider flexItem orientation="vertical" />}
      {!!isLabelGroupType && formState.type !== undefined && !!typeGroupElements && (
        <MutateNamingSchemeGroupLabelElementsPanel
          elements={typeGroupElements}
          groupType={formState.type}
          onChange={onChangeElements}
        />
      )}
      {formState.type === NamingSchemeGroupType.CreationDate && !!typeGroupElements && (
        <MutateNamingSchemeDateGroupPanel
          elementItems={typeGroupElements}
          onChange={onChangeElements}
          sx={{ minWidth: 200 }}
        />
      )}
      {formState.type === NamingSchemeGroupType.Version && !!typeGroupElements && (
        <MutateNamingSchemeVersionGroupPanel
          elementItems={typeGroupElements}
          onChange={onChangeElements}
          sx={{ minWidth: 200 }}
        />
      )}
      {formState.type === NamingSchemeGroupType.FreeText && (
        <Box sx={{ maxWidth: 400 }}>
          <Alert severity="info">
            {t('mutate-naming-scheme-group-panel_free-text-hint', 'In order for a free text group to match correctly, it must not contain any of the separator characters.')}
          </Alert>
        </Box>
      )}
      {formState.type === NamingSchemeGroupType.Number && (
        <MutateNamingSchemeCharactersGroupPanel
          length={formState.length}
          onChange={onChangeLength}
          title={t('mutate-naming-scheme-group-panel_digits-group-panel-title', 'Digits Group Options')}
          sx={{ minWidth: 200 }}
        />
      )}
      {formState.type === NamingSchemeGroupType.Chars && (
        <MutateNamingSchemeCharactersGroupPanel
          length={formState.length}
          onChange={onChangeLength}
          title={t('mutate-naming-scheme-group-panel_letters-group-panel-title', 'Letters Group Options')}
          sx={{ minWidth: 200 }}
        />
      )}
      {formState.type === NamingSchemeGroupType.Project && (typeGroupElements?.[0]?.abbreviation !== undefined) && (
        <MutateNamingSchemeProjectGroupPanel
          abbreviation={typeGroupElements[0].abbreviation}
          onChange={onChangeAbbreviation}
          sx={{ minWidth: 200 }}
        />
      )}
    </Box>
  );
}
