import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ProjectUpdateDefaultLabelsDto from 'projects/types/ProjectUpdateDefaultLabelsDto';
import ProjectDto from 'projects/types/ProjectDto';

export default function useProjectUpdateDefaultLabelsMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.ProjectLabels);
  const mutationFn = useCallback(async (dto: ProjectUpdateDefaultLabelsDto) => {
    const { data } = await axiosInstance.patch<ProjectDto>(`${ApiEndpoint.ProjectLabels}`, dto);
    return data;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<ProjectDto, unknown, ProjectUpdateDefaultLabelsDto>({
    mutationFn,
    onSuccess,
  });
}
