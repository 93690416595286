import React, { useMemo } from 'react';
import useProjectDocumentStatusesQuery from 'documents/hooks/useProjectDocumentStatusesQuery';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import LabelSettingsPanel from 'settings/components/LabelSettingsPanel';
import LabelType from 'labels/types/LabelType';

export default function DocumentSettingsStatusesSection() {
  const allowedActions = useAllowedActions();
  const canCreate = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Create), [allowedActions]);
  const canEdit = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Update), [allowedActions]);
  const canDelete = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Delete), [allowedActions]);
  const { data: statuses } = useProjectDocumentStatusesQuery();

  return (
    <LabelSettingsPanel
      labels={statuses}
      labelType={LabelType.DocumentStatus}
      canCreate={canCreate}
      canEdit={canEdit}
      canDelete={canDelete}
      variant="default"
    />
  );
}
