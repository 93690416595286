import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import IChildren from 'common/types/IChildren';
import useProjectCopyMutation from 'projects/hooks/useProjectCopyMutation';
import WorkPhaseKind from 'projects/types/WorkPhaseKind';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCurrentProjectId from 'projects/hooks/useCurrentProjectId';
import useProjectDetailsQuery from 'projects/hooks/useProjectDetailsQuery';
import InlineTypography from 'common/components/InlineTypography';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';
import ProjectCopyDto from 'projects/types/ProjectCopyDto';

enum CopyOption {
  Tags = 'tags',
  IssueStatus = 'issue-status',
  IssuePriorities = 'issue-priorities',
  IssueTypes = 'issue-types',
  DocumentStatus = 'document-status',
  Disciplines = 'disciplines',
  Buildings = 'buildings',
  Floors = 'floors',
  CollaboratorRoles = 'collaborator-roles',
  Collaborators = 'collaborators',
  Groups = 'groups',
  Folders = 'folders',
  WorkPhases = 'work-phases',
  NamingSchemes = 'naming-schemes',
}

interface CopyOptionsSectionProps extends IChildren {
  label: string,
}

function CopyOptionsSection({
  label,
  children,
}: CopyOptionsSectionProps) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant="h5">{label}</Typography>
      <FormGroup sx={{ gap: 1, pr: 1 }}>
        {children}
      </FormGroup>
    </Box>
  );
}

interface CopyOptionSwitchProps {
  option: CopyOption,
  label: string,
  checked: boolean,
  disabled?: boolean | undefined,
  onChange: (option: CopyOption, checked: boolean) => void,
}

function CopyOptionSwitch({
  option,
  label,
  checked,
  disabled,
  onChange,
}: CopyOptionSwitchProps) {
  const onChangeSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(option, event.target.checked);
  }, [onChange, option]);
  return (
    <FormControlLabel
      labelPlacement="start"
      control={<Switch checked={checked} disabled={disabled} onChange={onChangeSwitch} />}
      label={label}
      sx={{ gap: 2 }}
    />
  );
}

interface CopyProjectDialogProps {
  open: boolean,
  onClose: (confirmed: boolean) => void,
}

export default function CopyProjectDialog({
  open,
  onClose,
}: CopyProjectDialogProps) {
  const { t } = useTranslation('projects');
  const { data: currentCollaborators } = useCurrentCollaboratorQuery();
  const [selectedOptions, setSelectedOptions] = useState(new Set<CopyOption>(Object.values(CopyOption)));
  const [selectedWorkPhaseKind, setSelectedWorkPhaseKind] = useState<WorkPhaseKind | undefined>(undefined);
  const onChangeSelectedWorkPhaseKind = useCallback((event: SelectChangeEvent<string>) => setSelectedWorkPhaseKind(event.target.value?.length ? (Number.parseInt(event.target.value, 10) as WorkPhaseKind) : undefined), []);

  const [customNewProjectName, setCustomNewProjectName] = useState<string | undefined>(undefined);
  const onChangeNewProjectName = useCallback((event: ChangeEvent<HTMLInputElement>) => setCustomNewProjectName(event.target.value), []);
  const [customNewProjectAbbreviation, setCustomNewProjectAbbreviation] = useState<string | undefined>(undefined);
  const onChangeNewProjectAbbreviation = useCallback((event: ChangeEvent<HTMLInputElement>) => setCustomNewProjectAbbreviation(event.target.value), []);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const getRequestErrorMessage = useRequestErrorMessage();

  const currentProjectId = useCurrentProjectId();
  const { data: currentProject, isLoading: isLoadingCurrentProject } = useProjectDetailsQuery(currentProjectId);
  const { data: sourceDisciplines } = useProjectDisciplinesQuery();
  const { data: sourceBuildings } = useProjectBuildingsQuery();
  const { data: sourceFloors } = useProjectFloorsQuery();
  const { mutateAsync: mutateCopyProjectAsync } = useProjectCopyMutation();

  const defaultNewProjectName = useMemo(() => (currentProject ? `${currentProject.name} (Copy)` : undefined), [currentProject]);
  const newProjectName = useMemo(() => customNewProjectName ?? defaultNewProjectName, [customNewProjectName, defaultNewProjectName]);
  const defaultNewProjectAbbreviation = useMemo(() => (currentProject ? `${currentProject.abbreviation}_C` : undefined), [currentProject]);
  const newProjectAbbreviation = useMemo(() => customNewProjectAbbreviation ?? defaultNewProjectAbbreviation, [customNewProjectAbbreviation, defaultNewProjectAbbreviation]);

  const onChangeSwitch = useCallback((option: CopyOption, checked: boolean) => {
    setSelectedOptions((prev) => new Set(checked ? [...Array.from(prev), option] : Array.from(prev).filter((o) => o !== option)));
  }, []);

  const onClickCancel = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const canConfirm = useMemo(() => (
    currentProject
      && newProjectName?.trim().length
      && newProjectAbbreviation?.trim().length
      && (sourceDisciplines || !selectedOptions.has(CopyOption.Disciplines))
      && (sourceBuildings || !selectedOptions.has(CopyOption.Buildings))
      && (sourceFloors || !selectedOptions.has(CopyOption.Floors))
  ), [currentProject, newProjectName, newProjectAbbreviation, sourceDisciplines, selectedOptions, sourceBuildings, sourceFloors]);

  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const isLoading = useMemo(() => isCreatingProject || isLoadingCurrentProject, [isCreatingProject, isLoadingCurrentProject]);

  const onClickConfirm = useCallback(async () => {
    if (!currentProject || !currentCollaborators || !newProjectName?.trim() || !newProjectAbbreviation?.trim()) return;
    setIsCreatingProject(true);
    try {
      const projectCopyDto: ProjectCopyDto = {
        name: newProjectName.trim(),
        workPhaseKind: !selectedOptions.has(CopyOption.WorkPhases) ? selectedWorkPhaseKind : undefined,
        abbreviation: newProjectAbbreviation.trim(),
        projectSourceId: currentProject.id,
        copyTags: selectedOptions.has(CopyOption.Tags),
        copyIssueStatus: selectedOptions.has(CopyOption.IssueStatus),
        copyIssuePriorities: selectedOptions.has(CopyOption.IssuePriorities),
        copyIssueTypes: selectedOptions.has(CopyOption.IssueTypes),
        copyDocumentStatus: selectedOptions.has(CopyOption.DocumentStatus),
        copyDisciplines: selectedOptions.has(CopyOption.Disciplines),
        copyBuildings: selectedOptions.has(CopyOption.Buildings),
        copyFloors: selectedOptions.has(CopyOption.Floors),
        copyCollaboratorRoles: selectedOptions.has(CopyOption.CollaboratorRoles),
        copyCollaborators: selectedOptions.has(CopyOption.Collaborators),
        copyGroups: selectedOptions.has(CopyOption.Groups),
        copyFolders: selectedOptions.has(CopyOption.Folders),
        copyWorkPhases: selectedOptions.has(CopyOption.WorkPhases),
        copyNamingSchemes: selectedOptions.has(CopyOption.NamingSchemes),
      };
      await mutateCopyProjectAsync(projectCopyDto);
      onClose(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    } finally {
      setIsCreatingProject(false);
    }
  }, [currentProject, currentCollaborators, newProjectName, newProjectAbbreviation, selectedOptions, selectedWorkPhaseKind, mutateCopyProjectAsync, onClose, getRequestErrorMessage]);

  return (
    <Dialog
      id="CopyProjectDialog"
      open={open}
      PaperProps={{ sx: { maxWidth: 'unset', minWidth: 480 } }}
    >
      <DialogTitle component="div" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h2">
          {t('copy-project-dialog_title', 'Copy Project')}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          {t('copy-project-dialog_source-project-select-label', 'Source Project')}
          {': '}
          <InlineTypography sx={{ fontWeight: 600 }}>{currentProject?.name}</InlineTypography>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box style={{ display: 'flex', gap: '16px' }}>
            <TextField
              label={t('copy-project-dialog_target-project-name-label', 'New Project Name')}
              id="copy-project-dialog_target-project-name"
              placeholder={defaultNewProjectName}
              value={customNewProjectName ?? ''}
              onChange={onChangeNewProjectName}
              InputLabelProps={{ shrink: !!defaultNewProjectName }}
              sx={{ flex: '1 1 0' }}
            />
            <TextField
              label={t('copy-project-dialog_target-project-abbreviation-label', 'New Project Abbreviation')}
              id="copy-project-dialog_target-project-abbreviation"
              placeholder={defaultNewProjectAbbreviation}
              value={customNewProjectAbbreviation ?? ''}
              onChange={onChangeNewProjectAbbreviation}
              InputLabelProps={{ shrink: !!defaultNewProjectAbbreviation }}
            />
          </Box>
          <Typography variant="h3">{t('copy-project-dialog_copy-options-subheader', 'Project settings to copy')}</Typography>
          <Box id="CopyOptionSwitchBox" sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <CopyOptionsSection label={t('copy-project-dialog_project-metadata-section-header', 'Project Metadata')}>
              <CopyOptionSwitch option={CopyOption.Tags} label={t('copy-project-dialog_switch-label_tags', 'Tags')} checked={selectedOptions.has(CopyOption.Tags)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.Disciplines} label={t('copy-project-dialog_switch-label-disciplines', 'Disciplines')} checked={selectedOptions.has(CopyOption.Disciplines)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.Buildings} label={t('copy-project-dialog_switch-label-buildings', 'Buildings')} checked={selectedOptions.has(CopyOption.Buildings)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.Floors} label={t('copy-project-dialog_switch-label-floors', 'Floors')} checked={selectedOptions.has(CopyOption.Floors)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.WorkPhases} label={t('copy-project-dialog_switch-label-work-phases', 'Work Phases')} checked={selectedOptions.has(CopyOption.WorkPhases)} onChange={onChangeSwitch} />
              {!selectedOptions.has(CopyOption.WorkPhases) && (
                <FormControl sx={{ mt: 1, mb: 2, alignSelf: 'stretch' }}>
                  <InputLabel id="copy-project-dialog_work-phase-kind-select-label">{t('copy-project-dialog_work-phase-kind-select-label', 'Create Work Phases')}</InputLabel>
                  <Select<string>
                    value={`${selectedWorkPhaseKind ?? ''}`}
                    label={t('copy-project-dialog_work-phase-kind-select-label', 'Create Work Phases')}
                    onChange={onChangeSelectedWorkPhaseKind}
                    disabled={selectedOptions.has(CopyOption.WorkPhases)}
                  >
                    <MenuItem value={`${WorkPhaseKind.Hoai}`}>{t('create-project-dialog_new-project-workphase-kind-hoai', 'HOAI 2021')}</MenuItem>
                    <MenuItem value={`${WorkPhaseKind.Sia}`}>{t('create-project-dialog_new-project-workphase-kind-sia', 'SIA 102')}</MenuItem>
                    <MenuItem value={`${WorkPhaseKind.Riba}`}>{t('create-project-dialog_new-project-workphase-kind-riba', 'RIBA Plan of Work')}</MenuItem>
                    <MenuItem value={`${WorkPhaseKind.None}`}>
                      <i>
                        {selectedOptions.has(CopyOption.WorkPhases)
                          ? t('create-project-dialog_new-project-workphase-kind-copy', 'Copy Work Phases')
                          : t('create-project-dialog_new-project-workphase-kind-none', 'Do not create Work Phases')}
                      </i>
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              <CopyOptionSwitch option={CopyOption.IssueStatus} label={t('copy-project-dialog_switch-label-issue-status', 'Issue Status Values')} checked={selectedOptions.has(CopyOption.IssueStatus)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.IssuePriorities} label={t('copy-project-dialog_switch-label-issue-priorities', 'Issue Priorities')} checked={selectedOptions.has(CopyOption.IssuePriorities)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.IssueTypes} label={t('copy-project-dialog_switch-label-issue-types', 'Issue Types')} checked={selectedOptions.has(CopyOption.IssueTypes)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.DocumentStatus} label={t('copy-project-dialog_switch-label-document-status', 'Document Status')} checked={selectedOptions.has(CopyOption.DocumentStatus)} onChange={onChangeSwitch} />
            </CopyOptionsSection>
            <CopyOptionsSection label={t('copy-project-dialog_user-management-section-header', 'User Management')}>
              <CopyOptionSwitch option={CopyOption.CollaboratorRoles} label={t('copy-project-dialog_switch-label-collaborator-roles', 'Collaborator Roles')} checked={selectedOptions.has(CopyOption.CollaboratorRoles)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.Collaborators} disabled={!selectedOptions.has(CopyOption.CollaboratorRoles)} label={t('copy-project-dialog_switch-label-collaborators', 'Collaborators')} checked={selectedOptions.has(CopyOption.Collaborators) && selectedOptions.has(CopyOption.CollaboratorRoles)} onChange={onChangeSwitch} />
              <CopyOptionSwitch option={CopyOption.Groups} label={t('copy-project-dialog_switch-label-groups', 'Groups')} checked={selectedOptions.has(CopyOption.Groups)} onChange={onChangeSwitch} />
            </CopyOptionsSection>
            <CopyOptionsSection label={t('copy-project-dialog_folder-section-header', 'Folder')}>
              <CopyOptionSwitch option={CopyOption.Folders} label={t('copy-project-dialog_switch-label-folders', 'Folders')} checked={selectedOptions.has(CopyOption.Folders)} onChange={onChangeSwitch} />
            </CopyOptionsSection>
            <CopyOptionsSection label={t('copy-project-encoding_tags-section-header', 'Encoding')}>
              <CopyOptionSwitch option={CopyOption.NamingSchemes} label={t('copy-project-dialog_switch-label-naming-schemes', 'Naming Schemes')} checked={selectedOptions.has(CopyOption.NamingSchemes)} onChange={onChangeSwitch} />
            </CopyOptionsSection>
          </Box>
        </Box>
        {errorMessage && <Alert onClose={onCloseErrorMessage} severity="error" sx={{ width: '100%', mt: 2 }}>{errorMessage}</Alert>}
      </DialogContent>
      <DialogActions sx={{ gap: 2 }}>
        <Button id="CopyProjectDialogCancelButton" onClick={onClickCancel} variant="contained" color="secondary">{t('copy-project-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button id="CopyProjectDialogConfirmButton" onClick={onClickConfirm} variant="contained" color="primary" sx={{ ml: 'auto' }} disabled={isLoading || !canConfirm}>
          {isLoading && <CircularProgress size={12} sx={{ mr: 1 }} />}
          {t('copy-project-dialog_confirm-button-label', 'Create Project')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
