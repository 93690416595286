import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import activityDE from 'locales/de-DE/activity.json';
import collaboratorsDE from 'locales/de-DE/collaborators.json';
import commonDE from 'locales/de-DE/common.json';
import dashboardDE from 'locales/de-DE/dashboard.json';
import docFieldsDE from 'locales/de-DE/docFields.json';
import documentDetailsDE from 'locales/de-DE/document-details.json';
import documentsDetailsDE from 'locales/de-DE/documents-details.json';
import documentsFilterDE from 'locales/de-DE/documents-filter.json';
import documentsFoldersDE from 'locales/de-DE/documents-folders.json';
import documentsListsDE from 'locales/de-DE/documents-lists.json';
import documentsDE from 'locales/de-DE/documents.json';
import emailsDE from 'locales/de-DE/emails.json';
import errorDE from 'locales/de-DE/error.json';
import issueFieldsDE from 'locales/de-DE/issueFields.json';
import issuesDE from 'locales/de-DE/issues.json';
import labelsDE from 'locales/de-DE/labels.json';
import modelsDE from 'locales/de-DE/models.json';
import namingSchemesDE from 'locales/de-DE/naming-schemes.json';
import pdfPreviewDE from 'locales/de-DE/pdfPreview.json';
import projectsDE from 'locales/de-DE/projects.json';
import queryFiltersDE from 'locales/de-DE/query-filters.json';
import roleActionDE from 'locales/de-DE/roleAction.json';
import settingsDE from 'locales/de-DE/settings.json';
import shareDE from 'locales/de-DE/share.json';
import tagsDE from 'locales/de-DE/tags.json';
import timelineDE from 'locales/de-DE/timeline.json';
import uploadDE from 'locales/de-DE/upload.json';
import usersGroupsDE from 'locales/de-DE/users-groups.json';
import usersDE from 'locales/de-DE/users.json';

import activityEN from 'locales/en-US/activity.json';
import collaboratorsEN from 'locales/en-US/collaborators.json';
import commonEN from 'locales/en-US/common.json';
import dashboardEN from 'locales/en-US/dashboard.json';
import docFieldsEN from 'locales/en-US/docFields.json';
import documentDetailsEN from 'locales/en-US/document-details.json';
import documentsDetailsEN from 'locales/en-US/documents-details.json';
import documentsFilterEN from 'locales/en-US/documents-filter.json';
import documentsFoldersEN from 'locales/en-US/documents-folders.json';
import documentsListsEN from 'locales/en-US/documents-lists.json';
import documentsEN from 'locales/en-US/documents.json';
import emailsEN from 'locales/en-US/emails.json';
import errorEN from 'locales/en-US/error.json';
import issueFieldsEN from 'locales/en-US/issueFields.json';
import issuesEN from 'locales/en-US/issues.json';
import labelsEN from 'locales/en-US/labels.json';
import modelsEN from 'locales/en-US/models.json';
import namingSchemesEN from 'locales/en-US/naming-schemes.json';
import pdfPreviewEN from 'locales/en-US/pdfPreview.json';
import projectsEN from 'locales/en-US/projects.json';
import queryFiltersEN from 'locales/en-US/query-filters.json';
import roleActionEN from 'locales/en-US/roleAction.json';
import settingsEN from 'locales/en-US/settings.json';
import shareEN from 'locales/en-US/share.json';
import tagsEN from 'locales/en-US/tags.json';
import timelineEN from 'locales/en-US/timeline.json';
import uploadEN from 'locales/en-US/upload.json';
import usersGroupsEN from 'locales/en-US/users-groups.json';
import usersEN from 'locales/en-US/users.json';

import 'moment/locale/de';
import moment from 'moment';

// TODO: remove TimeAgo, we want to stick to a single date time library (moment.js)
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import de from 'javascript-time-ago/locale/de';

TimeAgo.addDefaultLocale(de);
TimeAgo.addLocale(en);

moment.updateLocale('en', {
  week: { dow: 1 },
});

i18n
  .use(initReactI18next)
  .init({
    // debug: true,
    compatibilityJSON: 'v3',
    ns: [
      'activity',
      'collaborators',
      'common',
      'dashboard',
      'docFields',
      'document-details',
      'documents-details',
      'documents-filter',
      'documents-folders',
      'documents-lists',
      'documents',
      'emails',
      'error',
      'issueFields',
      'issues',
      'labels',
      'models',
      'naming-schemes',
      'pdfPreview',
      'projects',
      'query-filters',
      'roleAction',
      'settings',
      'share',
      'tags',
      'timeline',
      'upload',
      'users-groups',
      'users',
    ],
    defaultNS: 'common',
    lng: 'de-DE',
    fallbackLng: 'en-US',
    resources: {
      'de-DE': {
        activity: activityDE,
        collaborators: collaboratorsDE,
        common: commonDE,
        dashboard: dashboardDE,
        docFields: docFieldsDE,
        'document-details': documentDetailsDE,
        'documents-details': documentsDetailsDE,
        'documents-filter': documentsFilterDE,
        'documents-folders': documentsFoldersDE,
        'documents-lists': documentsListsDE,
        documents: documentsDE,
        emails: emailsDE,
        error: errorDE,
        issueFields: issueFieldsDE,
        issues: issuesDE,
        labels: labelsDE,
        models: modelsDE,
        'naming-schemes': namingSchemesDE,
        pdfPreview: pdfPreviewDE,
        projects: projectsDE,
        'query-filters': queryFiltersDE,
        roleAction: roleActionDE,
        settings: settingsDE,
        share: shareDE,
        tags: tagsDE,
        timeline: timelineDE,
        upload: uploadDE,
        'users-groups': usersGroupsDE,
        users: usersDE,
      },
      'en-US': {
        activity: activityEN,
        collaborators: collaboratorsEN,
        common: commonEN,
        dashboard: dashboardEN,
        docFields: docFieldsEN,
        'document-details': documentDetailsEN,
        'documents-details': documentsDetailsEN,
        'documents-filter': documentsFilterEN,
        'documents-folders': documentsFoldersEN,
        'documents-lists': documentsListsEN,
        documents: documentsEN,
        emails: emailsEN,
        error: errorEN,
        issueFields: issueFieldsEN,
        issues: issuesEN,
        labels: labelsEN,
        models: modelsEN,
        'naming-schemes': namingSchemesEN,
        pdfPreview: pdfPreviewEN,
        projects: projectsEN,
        'query-filters': queryFiltersEN,
        roleAction: roleActionEN,
        settings: settingsEN,
        share: shareEN,
        tags: tagsEN,
        timeline: timelineEN,
        upload: uploadEN,
        'users-groups': usersGroupsEN,
        users: usersEN,
      },
    },
    react: {
      bindI18n: 'loaded languageChanged',
      useSuspense: false,
    },
    keySeparator: false, // we do not use keys in form messages.
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
  });

export default i18n;
