import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LabelDto from 'labels/types/LabelDto';
import { Alert, Box, Button, Divider, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LabelType from 'labels/types/LabelType';
import useLabelUpdateOrderMutation from 'labels/hooks/useLabelUpdateOrderMutation';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import ReorderLabelDialog, { ReorderLabelsDialogResult } from 'settings/components/ReorderLabelsDialog';
import useLabelTypeProperties from 'settings/hooks/useLabelTypeProperties';
import Icon from '@mdi/react';
import { mdiPlus, mdiSort, mdiUndo } from '@mdi/js';
import useLabelTypeTranslationPlural from 'labels/hooks/useLabelTypeTranslationPlural';
import MutateLabelDialog, { MutateLabelDialogResult } from 'settings/components/MutateLabelDialog';
import CreateLabelPanel from 'settings/components/CreateLabelPanel';
import useLabelTypeTranslation from 'labels/hooks/useLabelTypeTranslation';
import LabelSettingsTableItem from 'settings/components/LabelSettingsTableItem';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import ISxProps from 'common/types/ISxProps';
import RestoreLabelDialog from 'settings/components/RestoreLabelDialog';

interface LabelSettingsPanelProps extends ISxProps {
  labels: LabelDto[] | undefined;
  labelType: LabelType;
  canCreate: boolean,
  canEdit: boolean,
  canDelete: boolean,
  variant?: 'card' | 'default',
}

export default function LabelSettingsPanel({
  sx,
  labels,
  labelType,
  canCreate,
  canEdit,
  canDelete,
  variant,
}: LabelSettingsPanelProps) {
  const { t } = useTranslation('settings');
  const theme = useTheme();
  const getLabelTypeName = useLabelTypeTranslation();
  const labelTypeName = useMemo(() => getLabelTypeName(labelType), [getLabelTypeName, labelType]);

  const [labelsDisplayValue, setLabelsDisplayValue] = useState<LabelDto[]>(labels ?? []);
  useEffect(() => {
    setLabelsDisplayValue(labels ?? []);
  }, [labels]);

  const title = useLabelTypeTranslationPlural(labelType);
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const onCloseSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);

  const [labelIdCurrentlyEditing, setLabelIdCurrentlyEditing] = useState<string | undefined>(undefined);
  const onClickEdit = useCallback((id: string) => setLabelIdCurrentlyEditing(id), []);
  const onCloseEditDialog = useCallback((result: MutateLabelDialogResult) => {
    setLabelIdCurrentlyEditing(undefined);
    if (result === MutateLabelDialogResult.Updated) {
      setSuccessMessage(t('label-settings-panel_edit-label-success-message', 'The changes to the label have been saved'));
    } else if (result === MutateLabelDialogResult.Deleted) {
      setSuccessMessage(t('label-settings-panel_delete-label-success-message', '{{labelTypeName}} deleted', { labelTypeName }));
    }
  }, [labelTypeName, t]);

  const [createLabelPanelOpen, setCreateLabelPanelOpen] = useState<boolean>(false);
  const onClickAdd = useCallback(() => setCreateLabelPanelOpen(true), []);
  const onCloseCreateLabelPanel = useCallback(() => setCreateLabelPanelOpen(false), []);
  const onCreateLabel = useCallback((label: LabelDto) => {
    setSuccessMessage(t('label-settings-panel_create-label-success-message', '{{labelTypeName}} {{labelName}} created.', { labelTypeName, labelName: label.name }));
  }, [labelTypeName, t]);

  const [reorderDialogOpen, setReorderDialogOpen] = useState<boolean>(false);
  const onClickReorder = useCallback(() => setReorderDialogOpen(true), []);
  const onCloseReorderDialog = useCallback((result: ReorderLabelsDialogResult) => {
    setReorderDialogOpen(false);
    if (result === ReorderLabelsDialogResult.Updated) {
      setSuccessMessage(t('label-settings-panel_reorder-labels-success-message', 'The changes to the label order have been successfully saved'));
    }
  }, [t]);

  const [restoreDialogOpen, setRestoreDialogOpen] = useState<boolean>(false);
  const onClickRestore = useCallback(() => setRestoreDialogOpen(true), []);
  const onCloseRestoreDialog = useCallback(() => setRestoreDialogOpen(false), []);

  const { mutateAsync: updateLabelOrderAsync } = useLabelUpdateOrderMutation();

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setLabelsDisplayValue((prev) => {
      if (!prev) return prev;
      const newItems = [...prev]; // create a copy of the array
      const draggedItem = newItems[dragIndex]; // get the item to move

      // remove the item at the dragIndex
      newItems.splice(dragIndex, 1);

      // insert the dragged item at the hoverIndex
      newItems.splice(hoverIndex, 0, draggedItem);

      return newItems;
    });
  }, []);

  const onDrop = useCallback(async () => {
    if (!labelsDisplayValue) return;
    try {
      await updateLabelOrderAsync({ type: labelType, labelIds: labelsDisplayValue.map((item) => item.id) });
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, labelType, labelsDisplayValue, updateLabelOrderAsync]);

  const { hasAbbreviation } = useLabelTypeProperties(labelType);

  if (!labels) return <CenteredCircularProgress />;
  return (
    <Box id="LabelSettingsPanel" sx={{ backgroundColor: theme.palette.background.default, borderRadius: '16px', pb: 3, boxShadow: variant === 'card' ? '0 0 8px -2px rgba(0,0,0,0.3)' : undefined, ...sx }}>
      <Box sx={{ display: 'flex', gap: 1, p: 3 }}>
        <Typography variant="h4" sx={{ mr: 'auto' }}>{title}</Typography>
        <Button
          id="ModelSettingsDisciplinesPanelAddButton"
          variant="contained"
          color="secondary"
          size="small"
          onClick={onClickAdd}
          sx={{ ml: 'auto', gap: 0.5, pl: 0.5, visibility: canCreate && !createLabelPanelOpen ? 'visible' : 'hidden' }}
        >
          <Icon path={mdiPlus} size={1} />
          {t('label-settings-panel_add-button-label', 'Add')}
        </Button>
        <Button
          id="LabelSettingsPanelReorderButton"
          variant="outlined"
          onClick={onClickReorder}
          sx={{ gap: 1, pl: 1, whiteSpace: 'nowrap', visibility: canEdit && !createLabelPanelOpen ? 'visible' : 'hidden' }}
          size="small"
        >
          <Icon path={mdiSort} size={0.75} />
          {t('label-settings-panel_reorder-button-label', 'Reorder')}
        </Button>
        <Button
          id="LabelSettingsPanelDeletedLabelsButton"
          variant="outlined"
          onClick={onClickRestore}
          sx={{ gap: 1, pl: 1, visibility: canDelete && !createLabelPanelOpen ? 'visible' : 'hidden' }}
          size="small"
        >
          <Icon path={mdiUndo} size={0.75} />
          {t('label-settings-panel_deleted-labels-button-label', 'Restore')}
        </Button>
      </Box>
      {!!createLabelPanelOpen && (
      <>
        {variant === 'card' && <Divider />}
        <Box sx={variant === 'card' ? { p: 4 } : { p: 4, mx: 2, my: 4, boxShadow: '0 0 12px -2px rgba(0,0,0,0.3)', borderRadius: '16px' }}>
          <CreateLabelPanel labelType={labelType} onClose={onCloseCreateLabelPanel} onCreate={onCreateLabel} />
        </Box>
      </>
      )}
      {variant === 'card' && <Divider />}
      <DndProvider backend={HTML5Backend}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 50 }} />
              <TableCell align="left">{t('label-settings-panel_name-column-header', 'Name')}</TableCell>
              {hasAbbreviation && <TableCell sx={{ textAlign: 'center' }}>{t('label-settings-panel_abbreviation-column-header', 'Abbr.')}</TableCell>}
              {labelType === LabelType.DocumentStatus && <TableCell sx={{ textAlign: 'center' }}>{t('label-settings-panel_upload-status-column-header', 'Upload status')}</TableCell>}
              {labelType === LabelType.Discipline && <TableCell sx={{ textAlign: 'center' }}>{t('label-settings-panel_2d-map-column-header', '2D Map')}</TableCell>}
              <TableCell sx={{ width: 35 }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {labelsDisplayValue.map((label, index) => (
              <LabelSettingsTableItem
                key={label.id}
                label={label}
                index={index}
                moveItem={moveItem}
                onDrop={onDrop}
                onClickEdit={onClickEdit}
                canEdit={canEdit && !createLabelPanelOpen}
              />
            ))}
            {!!errorMessage && <TableRow><TableCell><Alert style={{ position: 'absolute' }} severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert></TableCell></TableRow>}
          </TableBody>
        </Table>
      </DndProvider>
      {variant === 'card' && <Divider />}
      {reorderDialogOpen && <ReorderLabelDialog onClose={onCloseReorderDialog} labelType={labelType} />}
      {restoreDialogOpen && <RestoreLabelDialog onClose={onCloseRestoreDialog} labelType={labelType} />}
      {!!labelIdCurrentlyEditing && <MutateLabelDialog labelType={labelType} labelId={labelIdCurrentlyEditing} onClose={onCloseEditDialog} canDelete={canDelete} canEdit={canEdit} />}
      {!!successMessage && (
      <Snackbar open onClose={onCloseSuccessMessage} autoHideDuration={6000}>
        <Alert severity="success" onClose={onCloseSuccessMessage}>{successMessage}</Alert>
      </Snackbar>
      )}
    </Box>
  );
}
