import { useCallback, useEffect } from 'react';
import { QueryFunctionContext, QueryKey, useQueryClient } from '@tanstack/react-query';
import buildQuery from 'odata-query';
import { OdataQueryKey, DetailsByIdQueryKey, odataQueryKeyRoot, detailsByIdQueryKeyRoot, ListByIdsQueryKey, listByIdsQueryKeyRoot, idsQueryKeyRoot, odataInfiniteQueryKeyRoot } from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import { chunk, map } from 'lodash';
import useAxiosInstance from 'api/hooks/useAxiosInstance';

const INFINITE_QUERY_ITEM_COUNT_PER_PAGE = 100;

export default function useVisoplanApiQueryDefaults() {
  const queryClient = useQueryClient();
  const axiosInstance = useAxiosInstance();

  const defaultOdataQueryFn = useCallback(async ({ queryKey, pageParam }: Partial<QueryFunctionContext<QueryKey, never>>) => {
    const topSkip = pageParam !== undefined ? { top: INFINITE_QUERY_ITEM_COUNT_PER_PAGE, skip: (pageParam as number) * INFINITE_QUERY_ITEM_COUNT_PER_PAGE } : undefined;
    const [, , endpoint, odataQuery, args] = queryKey as OdataQueryKey<ApiEndpoint, {}>;
    const odataQueryString = buildQuery({ ...odataQuery, ...topSkip });
    const argsArray = map(args, (value, key) => ({ key, value }));
    const queryArgsString = argsArray.length ? `${odataQueryString.length ? '&' : '?'}${argsArray.map(({ value, key }) => `${key}=${value}`).join('&')}` : '';
    const url = `${endpoint}${odataQueryString}${queryArgsString}`;
    const response = await axiosInstance.get<{}[]>(url);
    return response.data;
  }, [axiosInstance]);

  useEffect(() => {
    queryClient.setQueryDefaults(odataQueryKeyRoot, { queryFn: defaultOdataQueryFn });
    queryClient.setQueryDefaults(odataInfiniteQueryKeyRoot, { queryFn: defaultOdataQueryFn });
    queryClient.setQueryDefaults(idsQueryKeyRoot, { queryFn: defaultOdataQueryFn });
  }, [defaultOdataQueryFn, queryClient]);

  const defaultDetailsQueryFn = useCallback(async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, , endpoint, id] = queryKey as DetailsByIdQueryKey<ApiEndpoint>;
    const odataQueryKey = [...odataQueryKeyRoot, endpoint, { filter: { id: { eq: id } } }];
    const odataResult = await defaultOdataQueryFn({ queryKey: odataQueryKey });
    if (!odataResult.length) throw new Error('Requested issue not part of API response');
    return odataResult[0];
  }, [defaultOdataQueryFn]);

  useEffect(() => {
    queryClient.setQueryDefaults(detailsByIdQueryKeyRoot, { queryFn: defaultDetailsQueryFn });
  }, [defaultDetailsQueryFn, queryClient]);

  const defaultListByIdsQueryFn = useCallback(async ({ queryKey }: { queryKey: QueryKey }) => {
    const [, , endpoint, ids] = queryKey as ListByIdsQueryKey<ApiEndpoint>;
    if (!ids) throw new Error('Ids array is undefined');
    if (ids.length === 0) return [];
    const requestedDtosById = new Map<string, any>();
    const idChunks = chunk(ids, 300); // chunking into smaller requests limits URL length for large lists
    const requestPromises = idChunks.map(async (idsChunk) => {
      const response = await axiosInstance.get<any[]>(`/${endpoint}/${idsChunk.join(',')}`);
      const dtos = response.data;
      if (dtos.length < idsChunk.length) {
        throw new Error('API response incomplete');
      }
      dtos.forEach((dto) => requestedDtosById.set(dto.id, dto)); // collect result dtos in a map for later assembly of the result array
    });
    await Promise.all(requestPromises);
    const resultEntities = ids.map((id) => requestedDtosById.get(id)!);
    return resultEntities;
  }, [axiosInstance]);

  useEffect(() => {
    queryClient.setQueryDefaults(listByIdsQueryKeyRoot, { queryFn: defaultListByIdsQueryFn });
  }, [defaultListByIdsQueryFn, queryClient]);
}
