enum NamingSchemeGroupType {
  Disciplines,
  Building,
  Floor,
  WorkPhases,
  CreationDate,
  Version,
  FreeText,
  Project,
  Custom,
  DocStatus,
  Number,
  Chars,
  FreeNumber,
}

export default NamingSchemeGroupType;
