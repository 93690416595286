import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, CircularProgress, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useNamingSchemesOdataQuery from 'naming-schemes/hooks/useNamingSchemesOdataQuery';
import CreateNamingSchemeDialog from 'naming-schemes/components/CreateNamingSchemeDialog';
import NamingSchemeGroupsPanel from 'naming-schemes/components/NamingSchemeGroupsPanel';
import { mdiCog, mdiPlus, mdiPlusThick, mdiSwapHorizontalHidden, mdiTune } from '@mdi/js';
import Icon from '@mdi/react';
import EditNamingSchemeSettingsDialog from 'naming-schemes/components/EditNamingSchemeSettingsDialog';
import useNamingSchemeUpdateMutation from 'naming-schemes/hooks/useNamingSchemeUpdateMutation';
import UpdateNamingSchemeDto from 'naming-schemes/types/UpdateNamingSchemeDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import NamingSchemeGroupItem from 'naming-schemes/types/NamingSchemeGroupItem';
import useNamingSchemeDeleteMutation from 'naming-schemes/hooks/useNamingSchemeDeleteMutation';
import GeneralEncodingSettingsDialog from 'naming-schemes/components/GeneralEncodingSettingsDialog';
import CreateNamingSchemeGroupDialog from 'naming-schemes/components/CreateNamingSchemeGroupDialog';
import NamingSchemeExportButton from 'naming-schemes/components/NamingSchemeExportButton';
import NamingSchemeGroupsReorderDialog from 'naming-schemes/components/NamingSchemeGroupsReorderDialog';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import useNamingSchemeItemToDtoConversion from 'naming-schemes/hooks/useNamingSchemeItemToDtoConversion';

export default function EncodingSettingsTabPane() {
  const { t } = useTranslation('settings');
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const onCloseSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);
  const { mutateAsync: updateNamingScheme } = useNamingSchemeUpdateMutation();
  const { data: namingSchemes } = useNamingSchemesOdataQuery({});
  const [createNamingSchemeDialogOpen, setCreateNamingSchemeDialogOpen] = useState<boolean>(false);
  const onClickCreateNamingScheme = useCallback(() => setCreateNamingSchemeDialogOpen(true), []);
  const onCloseCreateNamingSchemeDialog = useCallback(() => setCreateNamingSchemeDialogOpen(false), []);
  const [selectedNamingSchemeId, setSelectedNamingSchemeId] = useState<string | undefined>(undefined);
  const selectedNamingScheme = useMemo(() => (selectedNamingSchemeId ? namingSchemes?.find((scheme) => scheme.id === selectedNamingSchemeId) : undefined), [namingSchemes, selectedNamingSchemeId]);
  const allowedActions = useAllowedActions();
  useEffect(() => {
    if (!namingSchemes?.length) return;
    setSelectedNamingSchemeId((prev) => prev ?? namingSchemes[0].id);
  }, [namingSchemes]);
  const onChangeTabs = useCallback((event: SyntheticEvent, value: string) => setSelectedNamingSchemeId(value), []);
  const namingSchemeItems = useMemo(() => namingSchemes?.map((namingScheme) => ({
    namingScheme,
    onClick: () => setSelectedNamingSchemeId(namingScheme.id),
    selected: selectedNamingSchemeId === namingScheme.id,
  })), [namingSchemes, selectedNamingSchemeId]);
  const [editNamingSchemeSettingsDialogOpen, setEditNamingSchemeSettingsDialogOpen] = useState<boolean>(false);
  const onClickOpenNamingSchemeSettings = useCallback(() => setEditNamingSchemeSettingsDialogOpen(true), []);
  const onCloseEditNamingSchemeSettingsDialog = useCallback(() => setEditNamingSchemeSettingsDialogOpen(false), []);
  const onExportError = useCallback((error: string) => setErrorMessage(error), []);
  const { mutateAsync: deleteNamingSchemeAsync } = useNamingSchemeDeleteMutation();
  const onDeleteNamingScheme = useCallback(async () => {
    setSuccessMessage(undefined);
    setErrorMessage(undefined);
    if (selectedNamingScheme) {
      try {
        await deleteNamingSchemeAsync(selectedNamingScheme.id);
        setSuccessMessage(t('encoding-settings-tab-pane_encoding-delete-success-message', 'Encoding "{{encodingName}}" deleted.', { encodingName: selectedNamingScheme.name }));
        setTimeout(() => setSuccessMessage(undefined), 6000);
        const firstRemainingNamingSchemeId = namingSchemes?.map((namingScheme) => namingScheme.id).filter((id) => id !== selectedNamingSchemeId)[0];
        if (firstRemainingNamingSchemeId) {
          setSelectedNamingSchemeId(firstRemainingNamingSchemeId);
        }
        setEditNamingSchemeSettingsDialogOpen(false);
      } catch (error) {
        setErrorMessage(getRequestErrorMessage(error));
      }
    }
  }, [deleteNamingSchemeAsync, getRequestErrorMessage, namingSchemes, selectedNamingScheme, selectedNamingSchemeId, t]);
  const groupItems = useMemo<NamingSchemeGroupItem[] | undefined>(() => selectedNamingScheme?.groups.map((group) => ({
    ...group,
    uuid: crypto.randomUUID(),
    groupElements: group.groupElements.map((element) => ({
      uuid: crypto.randomUUID(),
      ...element,
    })),
  })), [selectedNamingScheme?.groups]);

  const convertGroupItemToDto = useNamingSchemeItemToDtoConversion();
  const onChangeGroups = useCallback(async (items: NamingSchemeGroupItem[]) => {
    setSuccessMessage(undefined);
    setErrorMessage(undefined);
    if (!selectedNamingScheme) return;
    try {
      const updateDto: UpdateNamingSchemeDto = {
        id: selectedNamingScheme.id,
        name: selectedNamingScheme.name,
        allowMetaMapping: selectedNamingScheme.allowMetaMapping,
        groups: items.map(convertGroupItemToDto),
        applyFileTypes: selectedNamingScheme.applyFileTypes,
        useFullFileName: selectedNamingScheme.useFullFileName,
      };
      await updateNamingScheme(updateDto);
      setSuccessMessage(t('encoding-settings-tab-pane_groups-update-success-message', 'Your changes to the encoding have been saved.'));
      setTimeout(() => setSuccessMessage(undefined), 6000);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [selectedNamingScheme, convertGroupItemToDto, updateNamingScheme, t, getRequestErrorMessage]);

  const [createGroupDialogOpen, setCreateGroupDialogOpen] = useState<boolean>(false);
  const onClickAddGroup = useCallback(() => setCreateGroupDialogOpen(true), []);
  const onCloseAddGroupDialog = useCallback(async (groupItem: NamingSchemeGroupItem | undefined) => {
    if (!groupItems) return;
    if (groupItem) {
      const nextGroupItems = groupItems.concat(groupItem);
      if (nextGroupItems.length > 2) {
        nextGroupItems[nextGroupItems.length - 2].separator = nextGroupItems[nextGroupItems.length - 3].separator;
      }
      await onChangeGroups(nextGroupItems);
    }
    setCreateGroupDialogOpen(false);
  }, [groupItems, onChangeGroups]);

  const [generalEncodingSettingsDialogOpen, setGeneralEncodingSettingsDialogOpen] = useState<boolean>(false);
  const onClickOpenGeneralEncodingSettingsDialog = useCallback(() => setGeneralEncodingSettingsDialogOpen(true), []);
  const onCloseGeneralEncodingSettingsDialog = useCallback(() => setGeneralEncodingSettingsDialogOpen(false), []);

  const [reorderGroupsDialogOpen, setReorderGroupsDialogOpen] = useState<boolean>(false);
  const onClickReorderGroups = useCallback(() => setReorderGroupsDialogOpen(true), []);
  const onCloseReorderGroupsDialog = useCallback((reorderedGroups: NamingSchemeGroupItem[] | undefined) => {
    if (reorderedGroups) {
      onChangeGroups(reorderedGroups);
    }
    setReorderGroupsDialogOpen(false);
  }, [onChangeGroups]);

  const alreadyExistingGroupTypes = useMemo(() => {
    const usedTypes = groupItems?.map((item) => item.type) ?? [];
    return usedTypes;
  }, [groupItems]);

  const canEdit = useMemo(() => allowedActions?.has(RoleAction.NamingScheme_Update), [allowedActions]);
  const canCreate = useMemo(() => allowedActions?.has(RoleAction.NamingScheme_Create), [allowedActions]);

  return (
    <Box
      id="EncodingSettingsTabPane"
      sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 6, pt: 4, gap: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2">{t('encoding-settings-tab-pane_title', 'Filename Encodings')}</Typography>
          {canEdit && (
          <Button variant="outlined" onClick={onClickOpenGeneralEncodingSettingsDialog} sx={{ pl: 1, gap: 0.5, alignSelf: 'center' }}>
            <Icon path={mdiCog} size={1} />
            {t('encoding-settings-tab-pane_general-encoding-settings-button-label', 'General Encoding Settings')}
          </Button>
          )}
          {!!generalEncodingSettingsDialogOpen && <GeneralEncodingSettingsDialog onClose={onCloseGeneralEncodingSettingsDialog} />}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'bottom', gap: 2, height: 41.5 }}>
          {!namingSchemeItems && <CircularProgress size={12} sx={{ alignSelf: 'center' }} />}
          {!!namingSchemeItems && !!selectedNamingSchemeId && (
            <Tabs onChange={onChangeTabs} value={selectedNamingSchemeId}>
              {namingSchemeItems.map(({ namingScheme }) => (
                <Tab key={namingScheme.id} label={namingScheme.name} value={namingScheme.id} />
              ))}
            </Tabs>
          )}
          {canCreate && (
          <Button variant="outlined" onClick={onClickCreateNamingScheme} sx={{ pl: 1, gap: 0.5, alignSelf: 'center' }}>
            <Icon path={mdiPlus} size={1} />
            {t('encoding-settings-tab-pane_create-button-label', 'New Encoding')}
          </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1, boxShadow: 'inset -4px 0px 16px -4px rgba(0,0,0,0.3)', display: 'flex', flexDirection: 'column', p: 4, gap: 2, overflow: 'hidden' }}>
        <Box sx={{ display: 'flex', minHeight: 50 }}>
          {!!errorMessage && <Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
          {!!successMessage && <Alert severity="success" onClose={onCloseSuccessMessage}>{successMessage}</Alert>}
          <Box sx={{ flexGrow: 1, display: 'flex', gap: 2, justifyContent: 'flex-end', alignItems: 'center' }}>
            {!!selectedNamingScheme && (
              <>
                {canEdit && (
                <>
                  <Button variant="contained" color="secondary" onClick={onClickAddGroup} sx={{ pl: 1, gap: 0.5 }}>
                    <Icon path={mdiPlusThick} size={0.75} />
                    {t('encoding-settings-tab-pane_add-group-button-label', 'Add Group')}
                  </Button>
                  <Button variant="outlined" onClick={onClickOpenNamingSchemeSettings} sx={{ pl: 1, gap: 0.5 }}>
                    <Icon path={mdiTune} size={0.75} />
                    {t('encoding-settings-tab-pane_encoding-settings-button-label', '"{{namingSchemeName}}" Options', { namingSchemeName: selectedNamingScheme.name })}
                  </Button>
                  <Button variant="outlined" onClick={onClickReorderGroups} sx={{ pl: 1, gap: 0.5 }}>
                    <Icon path={mdiSwapHorizontalHidden} size={0.75} />
                    {t('encoding-settings-tab-pane_reorder-groups-button-label', 'Reorder Groups')}
                  </Button>
                </>
                )}
                {!!reorderGroupsDialogOpen && <NamingSchemeGroupsReorderDialog groupItems={groupItems} onClose={onCloseReorderGroupsDialog} />}
                <NamingSchemeExportButton namingSchemeId={selectedNamingScheme.id} onError={onExportError} />
              </>
            )}
          </Box>
        </Box>
        {!!selectedNamingSchemeId && !!editNamingSchemeSettingsDialogOpen && <EditNamingSchemeSettingsDialog namingSchemeId={selectedNamingSchemeId} onClose={onCloseEditNamingSchemeSettingsDialog} onDelete={onDeleteNamingScheme} />}
        <Box sx={{ backgroundColor: theme.palette.grey[300], p: 2, borderRadius: '16px', boxShadow: 'inset 0 0 16px -4px rgba(0,0,0,0.3)', display: 'flex', overflow: 'hidden' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', overflow: 'auto' }}>
            {!!groupItems && (
              <NamingSchemeGroupsPanel groupItems={groupItems} onChange={onChangeGroups} />
            )}
          </Box>
        </Box>
      </Box>
      {!!createNamingSchemeDialogOpen && <CreateNamingSchemeDialog onClose={onCloseCreateNamingSchemeDialog} />}
      {!!createGroupDialogOpen && <CreateNamingSchemeGroupDialog onClose={onCloseAddGroupDialog} alreadyExistingGroupTypes={alreadyExistingGroupTypes} />}
    </Box>
  );
}
