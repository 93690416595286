import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Menu, MenuList, MenuItem, useTheme, Alert, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListOptionsIcon from 'icons/ListOptionsIcon';
import ISxProps from 'common/types/ISxProps';
import ShareDocumentsDialog from 'share/components/ShareDocumentsDialog';
import useDocumentVersionArchiveOperationMutation from 'documents/hooks/useDocumentVersionArchiveOperationMutation';
import ArchiveOperation from 'documents/types/ArchiveOperation';
import RoleAction from 'projects/types/RoleAction';
import PrintWizard from 'documents/components/PrintWizard';
import ConfirmRemoveFromPlanlistDialog from 'documents-lists/components/ConfirmRemoveFromPlanlistDialog';
import AddToPlanlistDialog from 'documents-lists/components/AddToPlanlistDialog';
import useCurrentPlanlistId from 'documents-lists/hooks/useCurrentPlanlistId';
import useDocumentVersionsQuery from 'documents/hooks/useDocumentVersionsQuery';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import LinkIssueModal from 'issues/components/LinkIssueModal';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useIssuesOdataQueryData from 'issues/hooks/useIssuesOdataQueryData';
import { useQueryClient } from '@tanstack/react-query';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useCurrentFolderId from 'documents-folders/hooks/useCurrentFolderId';
import CollaboratorFolderAccessLevel from 'documents-folders/types/CollaboratorFolderAccessLevel';
import DocumentScopeKey from 'documents/types/DocumentScopeKey';
import useDocumentsViewNavigationContext from 'documents/hooks/useDocumentsViewNavigationContext';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import useFolderAccessLevel from 'documents-folders/hooks/useFolderAccessLevel';

interface DocumentsActionOptionsButtonMenuProps extends ISxProps {
}

export default function DocumentsActionOptionsButtonMenu({
  sx,
}: DocumentsActionOptionsButtonMenuProps) {
  const { t } = useTranslation('documents');
  const theme = useTheme();
  const btnRef = useRef<HTMLButtonElement>(null);
  const queryClient = useQueryClient();
  const folderId = useCurrentFolderId();
  const { queryKeyBases: documentVersionsQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const { data: currentUser } = useCurrentUserQuery();
  const { mutateAsync: mutateArchive, isPending: isLoadingArchiveOperation } = useDocumentVersionArchiveOperationMutation(ArchiveOperation.Archive);
  const { mutateAsync: mutateIssue, isPending: isLoadingDocumentVersionMutation } = useIssueUpdateMutation();
  const getIssuesOdataQueryData = useIssuesOdataQueryData();
  const isLoading = useMemo(() => isLoadingDocumentVersionMutation || isLoadingArchiveOperation, [isLoadingArchiveOperation, isLoadingDocumentVersionMutation]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const { selectedDocumentVersionIds } = useDocumentSelectionContext();
  const { data: selectedDocumentVersions, isLoading: isLoadingDocumentVersions } = useDocumentVersionsQuery(selectedDocumentVersionIds ? { filter: { id: { in: selectedDocumentVersionIds } } } : undefined, { enabled: isMenuOpen });
  const isAtLeastOnePrivateDocumentSelected = selectedDocumentVersions?.some((v) => v.isPrivate);
  const allowedActions = useAllowedActions();
  const [isPrintWizardOpen, setIsPrintWizardVisible] = useState(false);
  const currentPlanlistId = useCurrentPlanlistId();
  const [isRemoveFromPlanlistConfirmDialogOpen, setIsRemoveFromPlanlistConfirmDialogOpen] = useState(false);
  const [isAddToPlanlistConfirmDialogOpen, setIsAddToPlanlistConfirmDialogOpen] = useState(false);
  const [isAddToIssueDialogOpen, setIsAddToIssueDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const { setDocumentScope } = useDocumentsViewNavigationContext();
  const getFolderAccessLevel = useFolderAccessLevel();
  const { data: folderTreeData } = useFolderTreeQuery();

  const onClickShare = useCallback(() => {
    setIsMenuOpen(false);
    setIsShareDialogOpen(true);
  }, []);

  const archiveAvailable = useMemo(() => selectedDocumentVersions?.every((version) => version.isPrivate) || allowedActions?.has(RoleAction.Document_Archiving), [allowedActions, selectedDocumentVersions]);

  const archiveDisabled = useMemo(() => {
    if (folderId) {
      const folder = folderTreeData?.foldersById.get(folderId);
      if (!folder) return undefined;
      return getFolderAccessLevel(folder) !== CollaboratorFolderAccessLevel.ReadWrite;
    }
    if (!selectedDocumentVersions || !folderTreeData) return undefined;
    const selectedDocumentVersionsFolders = Array.from(new Set(selectedDocumentVersions.map((documentVersion) => documentVersion.folderId)))
      .filter((id) => folderTreeData.foldersById.has(id))
      .map((id) => folderTreeData.foldersById.get(id)!);
    return selectedDocumentVersionsFolders.some((folder) => getFolderAccessLevel(folder) !== CollaboratorFolderAccessLevel.ReadWrite);
  }, [folderId, folderTreeData, getFolderAccessLevel, selectedDocumentVersions]);

  const onClickArchive = useCallback(async () => {
    setIsMenuOpen(false);
    try {
      await mutateArchive(selectedDocumentVersionIds);
      setSuccessMessage(t('list-options_archive-success-message', 'Successfully archived {{count}} documents.', { count: selectedDocumentVersionIds.length }));
    } catch (error) {
      const reason = getRequestErrorMessage(error);
      setErrorMessage(t('list-options_archive-error-message', 'Archiving documents failed. Reason: {{reason}}', { reason }));
    }
  }, [getRequestErrorMessage, mutateArchive, selectedDocumentVersionIds, t]);

  const printAvailable = useMemo(() => currentUser?.canUsePrintOrders, [currentUser?.canUsePrintOrders]);
  const onClickPrint = useCallback(() => {
    setIsMenuOpen(false);
    setIsPrintWizardVisible(true);
  }, []);

  const onClickRemoveFromPlanlist = useCallback(() => {
    setIsMenuOpen(false);
    setIsRemoveFromPlanlistConfirmDialogOpen(true);
  }, []);

  const onClickAddToPlanlist = useCallback(() => {
    setIsMenuOpen(false);
    setIsAddToPlanlistConfirmDialogOpen(true);
  }, []);

  const onClickAddToIssue = useCallback(() => {
    if (!selectedDocumentVersions) return;
    const uniqueDocumentIds = new Set(selectedDocumentVersions.map((d) => d.normalizedName));
    if (uniqueDocumentIds.size < selectedDocumentVersions.length) {
      setErrorMessage(t('documents-action-options-button-menu_multiple-versions-of-same-document-error-message', 'You have selected multiple versions of the same document, but an issue can only be linked to a single version of the same document.'));
    } else {
      setIsMenuOpen(false);
      setIsAddToIssueDialogOpen(true);
    }
  }, [selectedDocumentVersions, t]);

  const onCloseRemoveFromPlanlistDialog = useCallback((dialogSuccessMessage?: string) => {
    setIsRemoveFromPlanlistConfirmDialogOpen(false);
    setSuccessMessage(dialogSuccessMessage);
  }, []);

  const onCloseAddToPlanlistDialog = useCallback((planlistIdAddedTo: string | undefined) => {
    setIsAddToPlanlistConfirmDialogOpen(false);
    if (planlistIdAddedTo) {
      setDocumentScope({ key: DocumentScopeKey.Planlist, id: planlistIdAddedTo });
    }
  }, [setDocumentScope]);

  const onCloseLinkIssueDialog = useCallback(async (value: string[]) => {
    setIsAddToIssueDialogOpen(false);
    if (value.length !== 1) return; // cancelled
    const issueId = value[0];
    try {
      const [issue] = await getIssuesOdataQueryData({ filter: { id: issueId } });
      await mutateIssue({
        id: issueId,
        linkedDocumentVersionIds: { value: Array.from(new Set([...issue.linkedDocumentVersionIds, ...selectedDocumentVersionIds])) },
      });
      documentVersionsQueryKeyBases.forEach((queryKey) => queryClient.invalidateQueries({ queryKey }));
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [documentVersionsQueryKeyBases, getIssuesOdataQueryData, getRequestErrorMessage, mutateIssue, queryClient, selectedDocumentVersionIds]);

  const issueIdsAlreadyLinkedToAnyVersion = useMemo(() => (selectedDocumentVersions ? new Set(selectedDocumentVersions.flatMap((v) => v.documentLinkedIssueIds)) : undefined), [selectedDocumentVersions]);

  return (
    <>
      <Button onClick={() => setIsMenuOpen(true)} sx={{ ...sx, color: theme.palette.text.primary }} ref={btnRef} id="DocumentsActionOptionsButtonMenu" disabled={isLoadingDocumentVersions && isMenuOpen}>
        {isLoadingDocumentVersions && isMenuOpen && <CircularProgress size={12} />}
        <ListOptionsIcon sx={{ mr: '-2px' }} />
        {t('list-options_button-label', 'List Options')}
      </Button>
      {isMenuOpen && !isLoadingDocumentVersions && (
        <Menu
          anchorEl={btnRef.current}
          open={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
        >
          <MenuList>
            {!!allowedActions?.has(RoleAction.Document_Sharing) && (
              <MenuItem onClick={onClickShare} disabled={isAtLeastOnePrivateDocumentSelected}>{t('list-options_share', 'Share')}</MenuItem>
            )}
            {!!printAvailable && <MenuItem onClick={onClickPrint} disabled={isLoading}>{t('list-options_print', 'Print')}</MenuItem>}
            {!!archiveAvailable && (
              <MenuItem onClick={onClickArchive} disabled={isLoading || archiveDisabled}>{t('list-options_archive', 'Archive')}</MenuItem>
            )}
            {!!currentPlanlistId && <MenuItem onClick={onClickRemoveFromPlanlist} disabled={isAtLeastOnePrivateDocumentSelected}>{t('list-options_remove-from-planlist', 'Remove from planlist')}</MenuItem>}
            <MenuItem onClick={onClickAddToPlanlist} disabled={isAtLeastOnePrivateDocumentSelected}>{t('list-options_add-to-planlist', 'Add to planlist')}</MenuItem>
            {!!allowedActions?.has(RoleAction.IssueManagement_Editing) && <MenuItem onClick={onClickAddToIssue} disabled={isAtLeastOnePrivateDocumentSelected}>{t('list-options_add-to-issue-button-label', 'Add to issue')}</MenuItem>}
          </MenuList>
        </Menu>
      )}
      {isShareDialogOpen && <ShareDocumentsDialog open onClose={() => setIsShareDialogOpen(false)} documentVersionIds={selectedDocumentVersionIds} />}
      {isPrintWizardOpen && <PrintWizard open onClose={() => setIsPrintWizardVisible(false)} documentVersionIds={selectedDocumentVersionIds} />}
      {isRemoveFromPlanlistConfirmDialogOpen && <ConfirmRemoveFromPlanlistDialog open onClose={onCloseRemoveFromPlanlistDialog} />}
      {isAddToPlanlistConfirmDialogOpen && <AddToPlanlistDialog onClose={onCloseAddToPlanlistDialog} documentVersionIds={selectedDocumentVersionIds} />}
      {isAddToIssueDialogOpen && <LinkIssueModal title={t('documents-action-options-button-menu_link-existing-issue-modal-title', 'Link Existing Issue')} onClose={onCloseLinkIssueDialog} disabledIssueIds={issueIdsAlreadyLinkedToAnyVersion} />}
      {!!errorMessage && (
      <Dialog open>
        <DialogTitle>{t('list-options_error-dialog-title', 'Error')}</DialogTitle>
        <DialogContent>
          <Alert severity="error">{errorMessage}</Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setErrorMessage(undefined)}>{t('list-options_error-dialog-close-button-label', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
      {!!successMessage && (
        <Dialog open>
          <DialogTitle>{t('list-options_success-dialog-title', 'Success')}</DialogTitle>
          <DialogContent>
            <Alert severity="success">{successMessage}</Alert>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setSuccessMessage(undefined)}>{t('list-options_success-dialog-close-button-label', 'Close')}</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
