import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import RoleAction from 'projects/types/RoleAction';
import { useMemo } from 'react';
import SettingsTab from 'settings/types/SettingsTab';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';

export default function useVisibleSettingsTabs() {
  const allowedActions = useAllowedActions();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const { data: currentProject } = useCurrentProjectQuery();
  return useMemo(() => {
    if (!allowedActions || !currentProject || !currentCollaborator) return undefined;
    const visibleTabs: SettingsTab[] = [];
    if (currentCollaborator.id === currentProject.creatorId
        || allowedActions.has(RoleAction.Inbox_Edit)
        || allowedActions.has(RoleAction.Project_Edit_2Fa)) {
      visibleTabs.push(SettingsTab.Project);
    }
    if (allowedActions.has(RoleAction.Label_Special) || allowedActions.has(RoleAction.Tag_Management)) {
      visibleTabs.push(SettingsTab.ProjectMetadata);
    }
    if (allowedActions.has(RoleAction.Project_RoleManagement)) {
      visibleTabs.push(SettingsTab.Roles);
    }
    if (allowedActions.has(RoleAction.Project_GroupManagement)) {
      visibleTabs.push(SettingsTab.UserGroups);
    }
    if (allowedActions.has(RoleAction.NamingScheme_Management)) {
      visibleTabs.push(SettingsTab.Encoding);
    }
    if (allowedActions.has(RoleAction.Label_Special)
        || allowedActions.has(RoleAction.DocStamp_Edit)) {
      visibleTabs.push(SettingsTab.Documents);
    }
    if (allowedActions.has(RoleAction.Label_Special)) {
      visibleTabs.push(SettingsTab.Issues);
    }

    return new Set<SettingsTab>(visibleTabs);
  }, [allowedActions, currentProject, currentCollaborator]);
}
