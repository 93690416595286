import React, { useCallback, useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import HorizontalDivider from 'common/styled/HorizontalDivider';
import IssuesDataGrid from 'issues/components/IssuesDataGrid';
import { Resizable } from 're-resizable';
import { useTranslation } from 'react-i18next';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import IssuesFilterNavigationSidebar from 'issues/components/IssuesFilterNavigationSidebar';
import { Direction } from 're-resizable/lib/resizer';

type LinkExistingIssuePanelProps = ISxProps & {
  disabledIssueIds?: Set<string> | undefined,
  onClose: (value: string[]) => void,
};

const FILTER_SIDEBAR_COLLAPSED_WIDTH = 40;
const LINK_EXISTING_ISSUE_PANEL_FILTER_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY = 'link-existing-issue-panel_filter-sidebar-default-width';
const LINK_EXISTING_ISSUE_PANEL_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY = 'link-existing-issue-panel_details-sidebar-default-width';
const LINK_EXISTING_ISSUE_PANEL_FILTER_SIDEBAR_COLLAPSED = 'link-existing-issue-panel_details-sidebar-collapsed';

export default function LinkExistingIssuePanel({
  sx,
  disabledIssueIds,
  onClose,
}: LinkExistingIssuePanelProps) {
  const { t } = useTranslation('issues');
  const { selectedIssueIds, multiselectActive } = useIssuesSelectionContext();
  const [collapsed, setCollapsed] = useState(false);

  const [filterSidebarDefaultWidth, setFilterSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem(LINK_EXISTING_ISSUE_PANEL_FILTER_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY);
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 200;
  });

  const onResizeStopFilterSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    if (!collapsed) {
      localStorage.setItem(LINK_EXISTING_ISSUE_PANEL_FILTER_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY, `${elementRef.clientWidth}`);
      setFilterSidebarDefaultWidth(elementRef.clientWidth);
    }
  }, [collapsed]);

  const [detailsSidebarDefaultWidth, setDetailsSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem(LINK_EXISTING_ISSUE_PANEL_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY);
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 350;
  });

  const onResizeStopDetailsSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    localStorage.setItem(LINK_EXISTING_ISSUE_PANEL_DETAILS_SIDEBAR_DEFAULT_WIDTH_STORAGE_KEY, `${elementRef.clientWidth}`);
    setDetailsSidebarDefaultWidth(elementRef.clientWidth);
  }, []);

  const filterSidebarResizableRef = useRef<Resizable>(null);
  const onChangeExpanded = useCallback((nextCollapsed: boolean) => {
    setCollapsed(nextCollapsed);
    filterSidebarResizableRef.current?.updateSize({ width: nextCollapsed ? FILTER_SIDEBAR_COLLAPSED_WIDTH : filterSidebarDefaultWidth, height: 'auto' });
    localStorage.setItem(LINK_EXISTING_ISSUE_PANEL_FILTER_SIDEBAR_COLLAPSED, nextCollapsed ? 'true' : 'false');
  }, [filterSidebarDefaultWidth]);

  const onClickCancel = useCallback(() => {
    onClose([]);
  }, [onClose]);
  const onClickConfirm = useCallback(() => {
    onClose(selectedIssueIds);
  }, [onClose, selectedIssueIds]);
  return (
    <Box id="LinkExistingIssuePanel" sx={{ ...sx, flex: '1 1 0', display: 'flex', flexDirection: 'column' }}>
      <HorizontalDivider />
      <Box sx={{ flex: '1 1 0', overflow: 'hidden', display: 'flex', backgroundColor: '#F7F8FB' }}>
        <Resizable
          ref={filterSidebarResizableRef}
          style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
          enable={{ top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
          defaultSize={{ width: collapsed ? FILTER_SIDEBAR_COLLAPSED_WIDTH : filterSidebarDefaultWidth, height: 'auto' }}
          onResizeStop={onResizeStopFilterSidebar}
          minWidth={collapsed ? FILTER_SIDEBAR_COLLAPSED_WIDTH : 100}
          handleComponent={{ right: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
          handleStyles={{ right: { right: 0 } }}
        >
          <IssuesFilterNavigationSidebar collapsed={collapsed} onChangeCollapsed={onChangeExpanded} />
        </Resizable>
        <Box sx={{ flex: '1 1 0', overflow: 'hidden' }}>
          <IssuesDataGrid disabledIssueIds={disabledIssueIds} />
        </Box>
        {!multiselectActive && selectedIssueIds.length === 1 && (
          <Resizable
            style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
            enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
            defaultSize={{ width: detailsSidebarDefaultWidth, height: 'auto' }}
            onResizeStop={onResizeStopDetailsSidebar}
            minWidth="350px"
            handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
            handleStyles={{ left: { left: 0 } }}
          >
            <Box
              sx={{
                height: '100%',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <IssueDetailsPanel issueId={selectedIssueIds[0]} nonInteractive />
            </Box>
          </Resizable>
        )}
      </Box>
      <HorizontalDivider />
      <Box sx={{ flex: '0 0 0', display: 'flex', justifyContent: 'flex-end', gap: 4, p: 2 }}>
        <Button variant="contained" color="secondary" onClick={onClickCancel}>{t('link-existing-issue-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button variant="contained" color="primary" onClick={onClickConfirm} disabled={!selectedIssueIds.length} sx={{ minWidth: 120 }}>{t('link-existing-issue-dialog_confirm-button-label', 'Confirm')}</Button>
      </Box>
    </Box>
  );
}
