import React, { useMemo, MouseEvent } from 'react';
import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import IssueDto from 'issues/types/IssueDto';
import IconChip from 'labels/components/IconChip';
import { mdiAccountCheck, mdiAccountHardHat, mdiBug, mdiCalendar, mdiStateMachine } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import useResourceQuery from 'resources/hooks/useResourceQuery';
import useIssueOverdueCheck from 'issues/hooks/useIssueOverdueCheck';
import LabelChip from 'labels/components/LabelChip';
import { inReviewIssueStatusFilterPredicate } from 'issues/hooks/useInReviewIssueStatus';
import Icon from '@mdi/react';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';

interface IssueItemCardProps extends ISxProps {
  issue: IssueDto,
  onClick?: (issueId: string, ctrlKey?: boolean | undefined) => void,
  selected?: boolean | undefined,
  collapsed?: boolean | undefined,
}

export default function IssueItemCard({
  sx,
  issue,
  onClick,
  selected,
  collapsed,
}: IssueItemCardProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  const { data: issueTypes } = useProjectIssueTypesQuery();
  const issueStatus = useMemo(() => (issue && issueStatuses ? issueStatuses.find((status) => status.id === issue.issueStatus) : undefined), [issue, issueStatuses]);
  const issueType = useMemo(() => (issue && issueTypes ? issueTypes.find((type) => type.id === issue.issueType) : undefined), [issue, issueTypes]);
  const isInReview = useMemo(() => (issue?.issueStatus ? inReviewIssueStatusFilterPredicate(issue.issueStatus) : undefined), [issue.issueStatus]);
  const isOverdue = useIssueOverdueCheck(issue);
  const dateColor = useMemo(() => (isOverdue ? theme.palette.error.main : undefined), [isOverdue, theme.palette.error]);
  const dueDateString = useMemo(() => (issue?.dueDate ? new Date(issue.dueDate).toLocaleDateString('de-DE') : undefined), [issue]);
  const onClickButton = useMemo(() => {
    if (!onClick) return undefined;
    return (event: MouseEvent<HTMLButtonElement>) => onClick(issue.id, event.ctrlKey);
  }, [issue, onClick]);
  const { data: thumbnailDataUrl } = useResourceQuery(issue.thumbnailImageId || undefined);
  return (
    <ButtonBase
      id="IssueItemCard"
      sx={{
        ...sx,
        borderRadius: '6px',
        backgroundColor: selected ? theme.palette.secondary.light : theme.palette.background.default,
        display: 'flex',
        alignItems: 'stretch',
        textAlign: 'left',
        boxShadow: '0px 1px 2px 1px rgba(0,0,0,0.2)',
        '&:hover': {
          boxShadow: '0px 1px 5px 1px rgba(0,0,0,0.3)',
        },
        '&:active': {
          boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.3)',
        },
        ...(selected ? {
          outlineWidth: '2px',
          outlineStyle: 'solid',
          outlineColor: `${theme.palette.primary.main}55`,
        } : {}),
      }}
      onClick={onClickButton}
      disabled={!onClickButton}
      title={issue.title}
    >
      <Box
        sx={{
          backgroundColor: issueStatus?.color ?? theme.palette.grey[700],
          width: '6px',
          borderTopLeftRadius: '6px',
          borderBottomLeftRadius: '6px',
          flexShrink: 0,
        }}
      />
      <Box sx={{ p: collapsed ? 0.5 : 1, flexGrow: 1, display: 'flex', flexDirection: 'column', minWidth: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            gap: 2,
            flex: '1 1 0',
            fontSize: 14,
          }}
        >
          {!!collapsed && (
            <Box sx={{ display: 'flex', gap: 0.75 }}>
              <Box sx={{ borderRadius: '4px', backgroundColor: theme.palette.grey[700], color: theme.palette.background.default, fontWeight: 600, p: '2px', px: '4px', textAlign: 'center' }}>
                {issue.issueNumber}
              </Box>
              <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', minWidth: 0, maxHeight: 30 }}>{issue.title}</Typography>
            </Box>
          )}
          {!collapsed && (
            <Box sx={{ ...sx, display: 'flex', gap: 0.5, alignItems: 'flex-start', justifyContent: 'flex-end', minWidth: 0 }}>
              <Box sx={{ mr: 'auto', borderRadius: '4px', backgroundColor: theme.palette.grey[700], color: theme.palette.background.default, fontWeight: 600, p: '2px', px: '4px', textAlign: 'center' }}>
                {issue.issueNumber}
              </Box>
              <Box sx={{ flexGrow: 1, flexDirection: 'row-reverse', display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center', justifyContent: 'flex-start' }}>
                {!!issue?.issueStatus && <LabelChip label={issueStatus} mdiIconPath={mdiStateMachine} />}
                {!!issue?.issueType && <LabelChip label={issueType} mdiIconPath={mdiBug} />}
                {!!dueDateString && <IconChip text={dueDateString} color={dateColor} mdiIconPath={mdiCalendar} tooltip={t('issue-chip-list_due-date-toolip', 'Due Date: {{dueDate}}', { dueDate: dueDateString })} />}
              </Box>
            </Box>
          )}
          {!collapsed && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: 16, textOverflow: 'ellipsis', overflow: 'hidden' }}>{`${issue.title}`}</Typography>
                {!!issue.thumbnailImageId && (
                <Box sx={{ width: '72px', height: '72px', flexShrink: 0, borderRadius: '4px', backgroundColor: theme.palette.grey[500] }}>
                  {!!thumbnailDataUrl && <img src={thumbnailDataUrl} style={{ width: '72px', height: '72px', borderRadius: '4px', objectFit: 'cover' }} alt={issue.title} />}
                </Box>
                )}
              </Box>
              <Box>
                {isInReview === false && !!issue.assignedUsers && issue.assignedUsers.length > 0 && (
                <Box key={issue.assignedUsers[0].id} sx={{ display: 'flex', alignItems: 'center', gap: 1, overflow: 'hidden' }}>
                  <Box sx={{ backgroundColor: theme.palette.grey[200], borderRadius: 99, width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }}>
                    <Icon path={mdiAccountHardHat} size={0.8} />
                  </Box>
                  <Box>
                    <Typography sx={{
                      fontWeight: 600,
                      lineHeight: 1.25,
                    }}
                    >
                      {`${issue.assignedUsers[0].firstName} ${issue.assignedUsers[0].lastName}`}
                      {issue.assignedUsers.length > 1 ? ` (+${issue.assignedUsers.length - 1})` : ''}
                    </Typography>
                    <Typography sx={{ color: theme.palette.grey[800], lineHeight: 1.25 }}>{t('linked-issue-item_assignee-label', 'Assigned')}</Typography>
                  </Box>
                </Box>
                )}
                {isInReview === true && !!issue.reviewer && (
                  <Box key={issue.reviewer.id} sx={{ display: 'flex', alignItems: 'center', gap: 1, overflow: 'hidden' }}>
                    <Box sx={{ backgroundColor: theme.palette.grey[200], borderRadius: 99, width: 36, height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }}>
                      <Icon path={mdiAccountCheck} size={0.8} />
                    </Box>
                    <Box>
                      <Typography sx={{
                        fontWeight: 600,
                        lineHeight: 1.25,
                      }}
                      >
                        {`${issue.reviewer.firstName} ${issue.reviewer.lastName}`}
                      </Typography>
                      <Typography sx={{ color: theme.palette.grey[800], lineHeight: 1.25 }}>{t('linked-issue-item_reviewer-label', 'Reviewer')}</Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </ButtonBase>
  );
}
