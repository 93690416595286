import { Box, ButtonBase, Typography } from '@mui/material';
import AnnotationItem from 'documents-annotations/types/AnnotationItem';
import React, { useCallback, useMemo } from 'react';
import theme from 'theme';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useResourceQuery from 'resources/hooks/useResourceQuery';
import Icon from '@mdi/react';
import { mdiHistory, mdiMapMarker } from '@mdi/js';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import IconChip from 'labels/components/IconChip';

interface IssueAnnotationItemProps {
  annotation: AnnotationItem | undefined,
  pdfAnnotation: PdfAnnotationDto,
  onClickAnnotationItem?: (annotation: PdfAnnotationDto) => void,
}

export default function IssueAnnotationItem({
  annotation,
  pdfAnnotation,
  onClickAnnotationItem,
}: IssueAnnotationItemProps) {
  const { selectedAnnotationNames } = useDocumentViewerContext();
  const isSelectedAnnotation = useMemo(() => (annotation ? selectedAnnotationNames?.includes(annotation.viewerAnnotation.Id) : false), [annotation, selectedAnnotationNames]);
  const { data: thumbnail } = useResourceQuery(pdfAnnotation.thumbnailImageId);
  const { data: linkedDocumentVersion } = useDocumentVersionQuery(pdfAnnotation.documentVersionId);
  const onClick = useCallback(() => {
    if (onClickAnnotationItem) {
      onClickAnnotationItem(pdfAnnotation);
    }
  }, [onClickAnnotationItem, pdfAnnotation]);

  return (
    <ButtonBase
      id="IssueAnnotationItem"
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        textAlign: 'left',
        p: 1,
        gap: 1,
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
        backgroundColor: isSelectedAnnotation ? theme.palette.grey[200] : null,
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
        },
      }}
      onClick={onClick}
      disabled={!onClickAnnotationItem}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {thumbnail ? <img alt="thumbnail" src={thumbnail} width="50px" height="50px" /> : <Icon path={mdiMapMarker} size={1} />}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 1,
          flexGrow: 1,
          pointerEvents: 'none',
          fontSize: 14,
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', gap: 1, width: '100%' }}>
            <Typography variant="caption" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{linkedDocumentVersion?.name}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconChip mdiIconPath={mdiHistory} text={linkedDocumentVersion?.versionNumber.toString()} />
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
}
