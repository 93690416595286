import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { Filter, ITEM_ROOT } from 'odata-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useTimeFrameFromToDates from 'common/hooks/useTimeFrameDates';
import useQueryFiltersOdataQuery from 'query-filters/hooks/useQueryFiltersOdataQuery';
import useQueryFilterCreateMutation from 'query-filters/hooks/useQueryFilterCreateMutation';
import useQueryFilterUpdateMutation from 'query-filters/hooks/useQueryFilterUpdateMutation';
import useQueryFilterDeleteMutation from 'query-filters/hooks/useQueryFilterDeleteMutation';
import QueryFilterPersistableState from 'query-filters/types/QueryFilterPersistableState';
import QueryFilterKind from 'query-filters/types/QueryFilterKind';
import QueryFilterEndpointMap from 'query-filters/types/QueryFilterEndpointMap';
import DocumentFilterSettingsDto from 'query-filters/types/DocumentFilterSettingsDto';
import EmailFilterSettingsDto from 'query-filters/types/EmailFilterSettingsDto';
import IssueFilterSettingsDto from 'query-filters/types/IssueFilterSettingsDto';
import FilterSettingsDtoMap from 'query-filters/types/FilterSettingsDtoMap';
import QueryFilterCreateDto from 'query-filters/types/QueryFilterCreateDto';
import QueryFilterDto from 'query-filters/types/QueryFilterDto';
import QueryFilterUpdateDto from 'query-filters/types/QueryFilterUpdateDto';
import IssueVisibility from 'issues/types/IssueVisibility';

export function isIssueFilterSettingsDto(queryFilterKind: QueryFilterKind, queryFilterDto: DocumentFilterSettingsDto | EmailFilterSettingsDto | IssueFilterSettingsDto): queryFilterDto is IssueFilterSettingsDto {
  return queryFilterKind === QueryFilterKind.Issue;
}

function getEmptyFilterState<TQueryFilterKind extends QueryFilterKind>(
  queryFilterKind: TQueryFilterKind,
): FilterSettingsDtoMap[TQueryFilterKind] {
  if (queryFilterKind === QueryFilterKind.Document) {
    const emptyDocumentFilterSettingsDto: DocumentFilterSettingsDto = {
      // TODO: implement me
    };
    return emptyDocumentFilterSettingsDto as FilterSettingsDtoMap[TQueryFilterKind];
  }
  if (queryFilterKind === QueryFilterKind.Email) {
    const emptyEmailFilterSettingsDto: EmailFilterSettingsDto = {
      // TODO: implement me
    };
    return emptyEmailFilterSettingsDto as FilterSettingsDtoMap[TQueryFilterKind];
  }
  if (queryFilterKind === QueryFilterKind.Issue) {
    const emptyIssueFilterSettingsDto: IssueFilterSettingsDto = {
      title: undefined,
      description: undefined,
      creationDate: undefined,
      editDate: undefined,
      dueDate: undefined,
      startingDate: undefined,
      visibility: undefined,
      status: [],
      priority: undefined,
      reviewer: undefined,
      assignedCollaboratorIds: [],
      createAuthor: undefined,
      editAuthor: undefined,
      source: undefined,
      type: undefined,
      importedType: undefined,
      tags: [],
      disciplines: [],
      buildings: [],
      floors: [],
      workPhase: undefined,
      issueNumber: 0,
      tagComparisonMode: undefined,
      linkedComponentsGlobalIds: [],
    };
    return emptyIssueFilterSettingsDto as FilterSettingsDtoMap[TQueryFilterKind];
  }
  throw new Error('unknown query filter type');
}

interface UseDocumentFilterPersistableStateProps<TQueryFilterKind extends QueryFilterKind> {
  filterId: string | undefined,
  queryFilterKind: TQueryFilterKind,
}

const queryFilterEndpointMap: { [kind in QueryFilterKind]: QueryFilterEndpointMap[kind] } = {
  [QueryFilterKind.Document]: ApiEndpoint.QueryFilterDocument,
  [QueryFilterKind.Issue]: ApiEndpoint.QueryFilterIssue,
  [QueryFilterKind.Email]: ApiEndpoint.QueryFilterEmail,
};

export default function useQueryFilterPersistableState<TQueryFilterKind extends QueryFilterKind>({
  filterId,
  queryFilterKind,
}: UseDocumentFilterPersistableStateProps<TQueryFilterKind>) {
  type TFilterSettingsDto = FilterSettingsDtoMap[TQueryFilterKind];

  const { data: projectQueryFilters, isLoading: isLoadingProjectQueryFilters } = useQueryFiltersOdataQuery<TQueryFilterKind, QueryFilterEndpointMap[TQueryFilterKind]>(queryFilterEndpointMap[queryFilterKind], {});
  const { mutateAsync: createQueryFilter } = useQueryFilterCreateMutation<TQueryFilterKind>(queryFilterEndpointMap[queryFilterKind]);
  const { mutateAsync: updateQueryFilter } = useQueryFilterUpdateMutation(queryFilterEndpointMap[queryFilterKind]);
  const { mutateAsync: deleteQueryFilters } = useQueryFilterDeleteMutation(queryFilterEndpointMap[queryFilterKind]);
  const projectQueryFiltersById = useMemo(() => (projectQueryFilters ? new Map<string | undefined, QueryFilterDto<TQueryFilterKind>>(projectQueryFilters.map((f) => [f.id, f])) : undefined), [projectQueryFilters]);

  const loadedQueryFilter = useMemo(() => projectQueryFiltersById?.get(filterId), [projectQueryFiltersById, filterId]);
  const [loadedQueryFilterOriginalFilterState, setLoadedQueryFilterOriginalFilterState] = useState<TFilterSettingsDto | undefined>(undefined);

  const emptyFilterState = useMemo(() => getEmptyFilterState(queryFilterKind), [queryFilterKind]);

  const [appliedFilterState, setAppliedFilterState] = useState<TFilterSettingsDto>(emptyFilterState);
  const [quickFilterString, setQuickFilterString] = useState<string>('');
  const [filterStateQueryFilterId, setFilterStateQueryFilterId] = useState<string | undefined>(undefined);
  const isAppliedFilterStateEmpty = useMemo(() => isEqual(emptyFilterState, appliedFilterState), [appliedFilterState, emptyFilterState]);

  useEffect(() => {
    if (isLoadingProjectQueryFilters) return;
    if (loadedQueryFilter?.uiFilterSettings) {
      const nextFilterState: TFilterSettingsDto = { ...emptyFilterState, ...loadedQueryFilter.uiFilterSettings };
      setLoadedQueryFilterOriginalFilterState(nextFilterState);
      setAppliedFilterState(nextFilterState);
      setFilterStateQueryFilterId(loadedQueryFilter.id);
    } else {
      setLoadedQueryFilterOriginalFilterState(undefined);
      setAppliedFilterState(emptyFilterState);
      setFilterStateQueryFilterId(undefined);
    }
  }, [loadedQueryFilter, isLoadingProjectQueryFilters, emptyFilterState, queryFilterKind]);

  const loadedQueryFilterHasLocalEdits = useMemo(() => !!filterId && !!loadedQueryFilter && filterStateQueryFilterId === filterId && !isEqual(appliedFilterState, loadedQueryFilterOriginalFilterState), [filterId, loadedQueryFilter, filterStateQueryFilterId, appliedFilterState, loadedQueryFilterOriginalFilterState]);

  const saveQueryFilterAsNew = useCallback(async (name: string, isPrivate: boolean) => {
    const createDto = {
      name: queryFilterKind === QueryFilterKind.Issue ? { en: name, de: name } : name,
      isPrivate,
      uiFilterSettings: appliedFilterState,
    } as QueryFilterCreateDto<TQueryFilterKind>; // we have to typecast here because the type checker fails to infere that we're safe regarding the name property type
    const createdEntity = await createQueryFilter(createDto);
    return createdEntity;
  }, [appliedFilterState, createQueryFilter, queryFilterKind]);

  const saveLocalEditsToLoadedQueryFilter = useCallback(async () => {
    if (!loadedQueryFilter?.id || !loadedQueryFilterHasLocalEdits) return;
    setLoadedQueryFilterOriginalFilterState(appliedFilterState);
    const updateDto: QueryFilterUpdateDto<TQueryFilterKind> = {
      id: loadedQueryFilter.id,
      isPrivate: loadedQueryFilter.isPrivate,
      name: loadedQueryFilter.name ?? '',
      uiFilterSettings: appliedFilterState,
    };
    await updateQueryFilter(updateDto);
  }, [loadedQueryFilter, loadedQueryFilterHasLocalEdits, appliedFilterState, updateQueryFilter]);

  const resetFilterState = useCallback(() => {
    setAppliedFilterState(emptyFilterState);
  }, [emptyFilterState]);

  const discardLocalChangesToLoadedFilter = useCallback(() => {
    if (loadedQueryFilterOriginalFilterState) {
      setAppliedFilterState(loadedQueryFilterOriginalFilterState);
    }
  }, [loadedQueryFilterOriginalFilterState]);

  const deleteLoadedQueryFilter = useCallback(async () => {
    if (!loadedQueryFilter?.id) return;
    await deleteQueryFilters(loadedQueryFilter.id);
    resetFilterState();
  }, [deleteQueryFilters, loadedQueryFilter, resetFilterState]);

  const getTimeFrameDates = useTimeFrameFromToDates();

  const odataFilter = useMemo(() => {
    const filter: Filter = [];
    if (isIssueFilterSettingsDto(queryFilterKind, appliedFilterState)) {
      const emptyIssueFilterState = getEmptyFilterState(QueryFilterKind.Issue);
      if (appliedFilterState.issueNumber !== emptyIssueFilterState.issueNumber && appliedFilterState.issueNumber !== undefined) {
        filter.push({ issueNumber: appliedFilterState.issueNumber });
      }
      if (appliedFilterState.title !== emptyIssueFilterState.title && appliedFilterState.title) {
        filter.push({ 'tolower(title)': { contains: appliedFilterState.title.trim().toLocaleLowerCase() } });
      }
      if (appliedFilterState.description !== emptyIssueFilterState.description && appliedFilterState.description) {
        filter.push({ 'tolower(description)': { contains: appliedFilterState.description.trim().toLocaleLowerCase() } });
      }
      if (!isEqual(appliedFilterState.assignedCollaboratorIds, emptyIssueFilterState.assignedCollaboratorIds) && appliedFilterState.assignedCollaboratorIds) {
        filter.push(...appliedFilterState.assignedCollaboratorIds.map((collaboratorId) => ({ assignedCollaboratorIds: { any: collaboratorId } })));
      }
      if (appliedFilterState.reviewer !== emptyIssueFilterState.reviewer) {
        filter.push({ reviewerId: appliedFilterState.reviewer });
      }
      if (appliedFilterState.createAuthor !== emptyIssueFilterState.createAuthor) {
        filter.push({ createAuthorId: appliedFilterState.createAuthor });
      }
      if (appliedFilterState.editAuthor !== emptyIssueFilterState.editAuthor) {
        filter.push({ editAuthorId: appliedFilterState.editAuthor });
      }
      if (!isEqual(appliedFilterState.status, emptyIssueFilterState.status) && appliedFilterState.status) {
        filter.push({ issueStatus: { in: appliedFilterState.status } });
      }
      if (appliedFilterState.priority !== emptyIssueFilterState.priority) {
        filter.push({ issuePriority: appliedFilterState.priority });
      }
      if (appliedFilterState.type !== emptyIssueFilterState.type) {
        filter.push({ issueType: appliedFilterState.type });
      }
      if (!isEqual(appliedFilterState.tags, emptyIssueFilterState.tags) && appliedFilterState.tags) {
        filter.push(...appliedFilterState.tags.map((tag) => ({ tags: { any: tag } })));
      }
      if (!isEqual(appliedFilterState.disciplines, emptyIssueFilterState.disciplines) && appliedFilterState.disciplines) {
        filter.push(...appliedFilterState.disciplines.map((discipline) => ({ disciplines: { any: discipline } })));
      }
      if (!isEqual(appliedFilterState.buildings, emptyIssueFilterState.buildings) && appliedFilterState.buildings) {
        filter.push(...appliedFilterState.buildings.map((building) => ({ buildings: { any: building } })));
      }
      if (!isEqual(appliedFilterState.floors, emptyIssueFilterState.floors) && appliedFilterState.floors) {
        filter.push(...appliedFilterState.floors.map((floor) => ({ floors: { any: floor } })));
      }
      if (appliedFilterState.workPhase !== emptyIssueFilterState.linkedComponentsGlobalIds) {
        filter.push({ workPhase: appliedFilterState.workPhase });
      }
      if (!isEqual(appliedFilterState.linkedComponentsGlobalIds, emptyIssueFilterState.linkedComponentsGlobalIds)) {
        filter.push({ linkedComponentsGlobalIds: { any: { [ITEM_ROOT]: { in: appliedFilterState.linkedComponentsGlobalIds } } } });
      }
      if (appliedFilterState.visibility && (appliedFilterState.visibility?.visibility !== emptyIssueFilterState.visibility?.visibility || appliedFilterState.visibility?.groupIds !== emptyIssueFilterState.visibility?.groupIds)) {
        filter.push({ visibility: IssueVisibility[appliedFilterState.visibility.visibility] });
        if (appliedFilterState.visibility?.visibility === IssueVisibility.Restricted && appliedFilterState.visibility?.groupIds?.length) {
          filter.push({ allowedUserGroups: { any: { [ITEM_ROOT]: { in: appliedFilterState.visibility.groupIds } } } });
        }
      }
      (['creationDate', 'editDate', 'startingDate', 'dueDate'] as const).forEach((prop) => {
        const dateFilterSettings = appliedFilterState[prop];
        const emptyDateFilterSettings = emptyIssueFilterState[prop];
        if (dateFilterSettings) {
          if (dateFilterSettings.timeFrame !== emptyDateFilterSettings?.timeFrame && dateFilterSettings.timeFrame !== undefined) {
            const { fromValue, toValue } = getTimeFrameDates(dateFilterSettings.timeFrame);
            if (fromValue) {
              filter.push({ [prop]: { ge: fromValue } });
            }
            if (toValue) {
              filter.push({ [prop]: { le: toValue } });
            }
          }
          const { before, after } = dateFilterSettings;
          const { before: emptyBefore, after: emptyAfter } = dateFilterSettings;
          if (before !== emptyBefore && before !== undefined) {
            filter.push({ [prop]: { le: new Date(before) } });
          }
          if (after !== emptyAfter && after !== undefined) {
            filter.push({ [prop]: { ge: new Date(after) } });
          }
        }
      });
      if (quickFilterString.trim().length) {
        const quickFilterTrimmed = quickFilterString.trim();
        const quickFilterTrimmedLowerCase = quickFilterTrimmed.toLocaleLowerCase();
        const numericQuickFilter = /^\d+$/.test(quickFilterTrimmedLowerCase) ? parseInt(quickFilterTrimmedLowerCase, 10) : undefined;
        filter.push({ or: [
          { id: quickFilterTrimmedLowerCase },
          { 'tolower(title)': { contains: quickFilterTrimmedLowerCase } },
          { 'tolower(description)': { contains: quickFilterTrimmedLowerCase } },
          { issueNumber: !Number.isNaN(numericQuickFilter) ? numericQuickFilter : undefined },
          { linkedComponentsGlobalIds: { any: quickFilterTrimmed } },
        ] });
      }
    }
    return filter;
  }, [appliedFilterState, getTimeFrameDates, queryFilterKind, quickFilterString]);

  return useMemo<QueryFilterPersistableState<TQueryFilterKind>>(() => ({
    setAppliedFilterState,
    appliedFilterState,
    quickFilterString,
    setQuickFilterString,
    emptyFilterState,
    resetFilterState,
    discardLocalChangesToLoadedFilter,
    loadedQueryFilter,
    loadedQueryFilterHasLocalEdits,
    saveQueryFilterAsNew,
    saveLocalEditsToLoadedQueryFilter,
    deleteLoadedQueryFilter,
    isAppliedFilterStateEmpty,
    odataFilter,
  }), [appliedFilterState, deleteLoadedQueryFilter, discardLocalChangesToLoadedFilter, emptyFilterState, isAppliedFilterStateEmpty, loadedQueryFilter, loadedQueryFilterHasLocalEdits, odataFilter, quickFilterString, resetFilterState, saveLocalEditsToLoadedQueryFilter, saveQueryFilterAsNew]);
}
