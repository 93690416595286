import React, { useMemo } from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import IssueDto from 'issues/types/IssueDto';
import { Box } from '@mui/material';
import IssueVisibility from 'issues/types/IssueVisibility';
import useUserGroupsOdataQuery from 'users-groups/hooks/useUserGroupsOdataQuery';
import useIssueVisibilityTranslations from 'issues/hooks/useIssueVisibilityTranslations';

export default function ThumbnailCell({
  data: issue,
  value,
}: CustomCellRendererProps<IssueDto, IssueDto['visibility']>) {
  const visibilityTranslations = useIssueVisibilityTranslations();
  const { data: groups } = useUserGroupsOdataQuery(issue?.visibility === IssueVisibility.Restricted ? { filter: { id: { in: issue.allowedUserGroups }, isDeleted: false } } : undefined);
  const groupNamesString = useMemo(() => (groups ? groups.map((g) => g.name).join(', ') : undefined), [groups]);
  if (!value) return null;
  return (
    <Box>{groupNamesString ? `${visibilityTranslations[value]} (${groupNamesString})` : visibilityTranslations[value]}</Box>
  );
}
