import { useCallback, useMemo, useState } from 'react';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import { useTranslation } from 'react-i18next';
import useDocumentListUpdateDocumentsMutation from 'documents-lists/hooks/useDocumentListUpdateDocumentsMutation';
import DocumentListUpdateDocumentsDto from 'documents-lists/types/DocumentListUpdateDocumentsDto';

export default function useAddDocumentVersionsToPlanlist() {
  const { t } = useTranslation('documents-lists');
  const { mutateAsync } = useDocumentListUpdateDocumentsMutation();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const clearErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const clearSuccessMessage = useCallback(() => setSuccessMessage(undefined), []);

  const addDocumentVersionsToPlanlist = useCallback(async (documentVersionIds: string[], targetPlanlistId: string, targetPlanlistName: string) => {
    const updateDto: DocumentListUpdateDocumentsDto = {
      id: targetPlanlistId,
      documentVersionIds,
    };
    try {
      await mutateAsync(updateDto);
      setSuccessMessage(t('use-move-document-versions-to-planlist_documents-moved-success-message', 'Successfully added {{count}} documents to the plan list {{planlist}}.', { count: documentVersionIds.length, planlist: targetPlanlistName }));
    } catch (e) {
      const reason = getRequestErrorMessage(e) ?? 'Unknown';
      setErrorMessage(t('use-move-document-versions-to-planlist_documents-moved-error-message', 'Failed to move {{count}} documents. Reason: {{reason}}', { count: documentVersionIds.length, reason }));
    }
  }, [getRequestErrorMessage, mutateAsync, t]);

  return useMemo(() => ({
    addDocumentVersionsToPlanlist,
    errorMessage,
    clearErrorMessage,
    successMessage,
    clearSuccessMessage,
  }), [clearErrorMessage, clearSuccessMessage, errorMessage, addDocumentVersionsToPlanlist, successMessage]);
}
