import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApiEndpoint from 'api/types/ApiEndpoint';
import StampSettingsDto from 'documents/types/StampSettingsDto';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import { useCallback } from 'react';
import ProjectDto from 'projects/types/ProjectDto';

export default function useDocumentStampSettingsUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentStampSettings);
  const mutationFn = useCallback(async (dto: StampSettingsDto) => {
    const { data } = await axiosInstance.patch<ProjectDto>(`${ApiEndpoint.DocumentStampSettings}`, dto);
    return data;
  }, [axiosInstance]);
  const onSuccess = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryClient, queryKeyBases]);
  return useMutation<ProjectDto, unknown, StampSettingsDto>({
    mutationFn,
    onSuccess,
  });
}
