import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosInstance from 'api/hooks/useAxiosInstance';
import ApiEndpoint from 'api/types/ApiEndpoint';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import UserGroupUpdateDto from 'users-groups/types/UserGroupUpdateDto';
import { useCallback } from 'react';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';

interface UserGroupUpdateMutationContext {
  cachedEntities: UserGroupDto[] | undefined,
}

export default function useUserGroupUpdateMutation() {
  const axiosInstance = useAxiosInstance();
  const queryClient = useQueryClient();
  const { queryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.UserGroup);
  const mutationFn = useCallback(async (dto: UserGroupUpdateDto) => {
    const { data } = await axiosInstance.put<UserGroupDto>(`${ApiEndpoint.UserGroup}`, dto);
    return data;
  }, [axiosInstance]);
  const onSettled = useCallback(() => {
    queryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
  }, [queryKeyBases, queryClient]);
  return useMutation<UserGroupDto, unknown, UserGroupUpdateDto, UserGroupUpdateMutationContext>({
    mutationFn,
    onSettled,
  });
}
