import React from 'react';
import { MenuItem, Select } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useRolesOdataQuery from 'collaborators/hooks/useRolesOdataQuery';

interface CollaboratorRoleSelectProps extends ISxProps {
  value: string | undefined,
  onChange: (nextValue: string) => void,
  disabled?: boolean,
}

export default function CollaboratorRoleEditSelect({
  sx,
  value,
  onChange,
  disabled,
}: CollaboratorRoleSelectProps) {
  const { data: roles, isLoading } = useRolesOdataQuery({ filter: { isDeleted: false } });
  return (
    <Select
      id="CollaboratorRoleEditSelect"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled || isLoading}
      variant="standard"
      sx={sx}
    >
      {roles?.map((r) => <MenuItem value={r.id} key={r.id}>{r.name}</MenuItem>)}
    </Select>
  );
}
