import { Box3, MathUtils, Vector3 } from "three";
import {
  CameraProjections,
  IfcClipper,
  IfcContext,
  IfcManager,
  NavigationModes,
} from "./components";
import { VisualState } from "./BaseDefinitions";
import { CameraInfo, Section, VisoViewpoint } from "./viso-types";
import ViewpointDto from 'issues/types/ViewpointDto';
import CameraType from 'models/types/CameraType';

const EPSILON = 1e-6;

export class Viewpoint {
  private boxCenter: Vector3;

  constructor(
    private context: IfcContext,
    private ifc: IfcManager,
    private clipper: IfcClipper
  ) {
    this.boxCenter = new Vector3();
  }

  showViewpoint(viewpoint: ViewpointDto) {
    this.resetView();

    const getThreeVector = ({ x, y, z }: { x: number, y: number, z: number }) => new Vector3(x, z, -y);
    const getVector3d = ({ x, y, z }: { x: number, y: number, z: number }) => ({ x, y: z, z: -y });

    // First: Set Camera
    this.setCamera({
      direction: getVector3d(viewpoint.direction),
      isPerspective: viewpoint.cameraType === CameraType.Perspective,
      location: getVector3d(viewpoint.location),
      up: getVector3d(viewpoint.up),
      factor: viewpoint.cameraFactor
    });

    // Second: Set Section
    if (viewpoint.sections) this.setSections(viewpoint.sections.map((section) => ({
      location: getThreeVector(section.location),
      normal: getThreeVector(section.normal),
    })));

    // Last: Set Visual State
    if (viewpoint.visualStates) {
      this.handleVisualState({
        visibility: viewpoint.visibility,
        colorGroups: viewpoint.visualStates.colorGroups.map((g) => ({ color: g.color, componentIDs: g.componentGlobalIds })),
        exceptedIDs: viewpoint.visualStates.visbilityExceptionGlobalIds,
        selectedIDs: viewpoint.visualStates.selectedGlobalIds,
        transparentIDs: viewpoint.visualStates.transparentGlobalIds,
      });
    }
  }

  calculateCenter() {
    const box = this.context.boundingBox.clone();
    this.boxCenter = box.getCenter(new Vector3());
  }

  resetView() {
    this.ifc.visoLoader.ifcManager.resetModel();
    this.ifc.selector.resetSelection();

    this.clipper.deleteAllPlanes();
    this.clipper.active = false;
  }

  private setSections(sections: Section[]) {
    this.clipper.active = true;
    sections.forEach((sec) => {
      let normal = new Vector3().copy(sec.normal);
      normal.multiplyScalar(-1);

      let location = new Vector3().copy(sec.location);
      this.clipper.createFromNormalAndCoplanarPoint(normal, location);
    });
    this.clipper.active = false;
  }

  private setCamera(cameraInfo: CameraInfo) {
    if (this.isInvalidCamera(cameraInfo)) return;

    if (cameraInfo.isPerspective) {
      this.setPerspectiveCamera(cameraInfo);
    } else {
      this.setOrthogonalCamera(cameraInfo);
    }
  }

  private setPerspectiveCamera(cameraInfo: CameraInfo){
    const ifcCamera = this.context.ifcCamera;
    ifcCamera.projection = CameraProjections.Perspective;
    ifcCamera.perspectiveCamera.fov = cameraInfo.factor;
    ifcCamera.perspectiveCamera.updateProjectionMatrix();

    this.calculateCenter();
    const dist = this.boxCenter.length();

    const position = new Vector3(cameraInfo.location.x, cameraInfo.location.y, cameraInfo.location.z);
    const direction = new Vector3(cameraInfo.direction.x, cameraInfo.direction.y, cameraInfo.direction.z);
    const target = new Vector3().copy(position).add(direction.multiplyScalar(dist));

    ifcCamera.navMode[NavigationModes.Mouse].setLookAt(
      position,
      target,
      true
    );
    ifcCamera.navMode[NavigationModes.Standard].setLookAt(
      position,
      target
    );
  }

  private setOrthogonalCamera(cameraInfo:CameraInfo){
    const ifcCamera = this.context.ifcCamera;
    const dist = this.boxCenter.length();

    ifcCamera.projection = CameraProjections.Orthographic;

    ifcCamera.orthographicCamera.zoom = cameraInfo.factor;

    const dims = this.context.getDimensions();
    const aspect = dims.x/dims.y;
    const fov = ifcCamera.perspectiveCamera.fov;
    const height = dist*Math.atan((fov*MathUtils.DEG2RAD)/2);
    const width = height*aspect;

    ifcCamera.orthographicCamera.left=-width;
    ifcCamera.orthographicCamera.right=width;
    ifcCamera.orthographicCamera.top=height;
    ifcCamera.orthographicCamera.bottom=-height;

    ifcCamera.orthographicCamera.updateProjectionMatrix();

    const position = new Vector3(cameraInfo.location.x, cameraInfo.location.y, cameraInfo.location.z);
    const direction = new Vector3(cameraInfo.direction.x, cameraInfo.direction.y, cameraInfo.direction.z);
    const target = new Vector3().copy(position).add(direction.multiplyScalar(dist));

    ifcCamera.navMode[NavigationModes.Mouse].setLookAt(
      position,
      target,
      true
    );
    ifcCamera.navMode[NavigationModes.Mouse].setZoom(cameraInfo.factor);

    ifcCamera.navMode[NavigationModes.Standard].setLookAt(
      position,
      target
    );
    ifcCamera.navMode[NavigationModes.Standard].setZoom(cameraInfo.factor);
  }

  private calculateLineIntersection(point:Vector3, direction:Vector3, box:Box3){
    const size = box.getSize(new Vector3());
    const center = box.getCenter(new Vector3());

    const allPlanes = [
      new Vector3(size.x*0.5,0,0),
      new Vector3(-size.x*0.5,0,0),
      new Vector3(0,size.y*0.5, 0),
      new Vector3(0,-size.y*0.5,0),
      new Vector3(0,0,size.z*0.5),
      new Vector3(0,0,-size.z*0.5)
    ];

    for(let i = 0;i<6;i++){
      const plane = allPlanes[i];
      const intersectFator = this.planeIntersection(center.clone().add(plane), plane.clone(), point.clone(), direction.clone());
      if(intersectFator && intersectFator > 0){
        return point.clone().add(direction.clone().multiplyScalar(intersectFator));
      }
    }
    
    return null;
  }

  private planeIntersection(planeOrigin:Vector3, planeNormal:Vector3, lineOrigin:Vector3, lineDiretion:Vector3){
    const dot = lineDiretion.dot(planeNormal);
    if(dot == 0)return null;

    return planeOrigin.clone().sub(lineOrigin).dot(planeNormal)/dot;
  }

  private isInBoundBox(point:Vector3, box:Box3){
    return box.containsPoint(point);
  }

  private handleVisualState(visualState: VisualState) {
    this.ifc.selector.setVisualModel(
      this.ifc.visoLoader.ifcManager.subsets.handleVisualState(visualState)
    );
  }

  private isInvalidCamera(camera: CameraInfo) {
    const direction = new Vector3(camera.direction.x, camera.direction.y, camera.direction.z);
    const up = new Vector3(camera.up.x, camera.up.y, camera.up.z);
    if (this.isZeroVector(direction) || this.isZeroVector(up))
      return true;

    return direction.cross(up).length() < EPSILON;
  }

  private isZeroVector(vector: Vector3) {
    return vector.length() < EPSILON;
  }
}
