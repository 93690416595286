import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import RoleAction from 'projects/types/RoleAction';
import { useMemo } from 'react';

export default function useIssueEditingRoleRight(issueId: string | undefined) {
  const { data: issue } = useIssueQuery(issueId);
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const allowedActions = useAllowedActions();
  const canEdit = useMemo(() => {
    if (!allowedActions || !issue) {
      return undefined;
    }
    if (allowedActions.has(RoleAction.IssueManagement_Editing)) {
      return true;
    }
    if (!currentCollaborator) return undefined;
    if (allowedActions.has(RoleAction.IssueManagement_Reviewer_Editing) && issue.reviewerId && currentCollaborator.id === issue.reviewerId) {
      return true;
    }
    if (allowedActions.has(RoleAction.IssueManagement_Creator_Editing) && issue.createAuthorId && currentCollaborator.id === issue.createAuthorId) {
      return true;
    }
    if (allowedActions.has(RoleAction.IssueManagement_Assigned_Editing) && issue.assignedCollaboratorIds.some((id) => id === currentCollaborator.id)) {
      return true;
    }
    return false;
  }, [allowedActions, currentCollaborator, issue]);

  return canEdit;
}
