import React, {
  ChangeEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,
} from '@mui/material';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import UndoIcon from '@mui/icons-material/Undo';
import ISxProps from 'common/types/ISxProps';
import { Trans, useTranslation } from 'react-i18next';
import useUserGroupUpdateMutation from 'users-groups/hooks/useUserGroupUpdateMutation';
import UserGroupUpdateDto from 'users-groups/types/UserGroupUpdateDto';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useUserGroupDeleteMutation from 'users-groups/hooks/useUserGroupDeleteMutation';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';

interface UserGroupListItemProps extends ISxProps {
  userGroup: UserGroupDto,
}

export default function UserGroupListItem({
  userGroup,
  sx,
}: UserGroupListItemProps) {
  const { t } = useTranslation('settings');
  const currentUserRole = useCurrentCollaboratorRole();
  const [isRenaming, setIsRenaming] = useState(false);
  const [nameDisplayValue, setNameDisplayValue] = useState(userGroup.name);
  useEffect(() => setNameDisplayValue(userGroup.name), [userGroup.name]);
  const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
  const onClickRename = useCallback(() => setIsRenaming(true), []);
  const onChangeRenameTextField = useCallback((e: ChangeEvent<HTMLInputElement>) => setNameDisplayValue(e.target.value), []);
  const { mutateAsync: mutateUpdate, isPending: isLoadingUpdate } = useUserGroupUpdateMutation();
  const { mutateAsync: mutateDelete, isPending: isLoadingDelete } = useUserGroupDeleteMutation();
  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState<string | undefined>(undefined);
  const [isSuccessIndicatorVisible, setIsSuccessIndicatorVisible] = useState(false);
  const { data: userGroups } = useUserGroupsQuery();
  const existingGroupNames = useMemo(() => (userGroups ? new Set(userGroups.map((g) => g.name)) : undefined), [userGroups]);
  const hasNamingConflict = useMemo(() => !!userGroup?.name.length && nameDisplayValue.trim() !== userGroup.name && !!existingGroupNames?.has(nameDisplayValue.trim()), [existingGroupNames, nameDisplayValue, userGroup.name]);
  const onClickConfirmRename = useCallback(async () => {
    setIsRenaming(false);
    if (nameDisplayValue.trim() === userGroup.name) return;
    const updateDto: UserGroupUpdateDto = {
      id: userGroup.id,
      name: nameDisplayValue.trim(),
    };
    try {
      await mutateUpdate(updateDto);
      setIsSuccessIndicatorVisible(true);
      setTimeout(() => setIsSuccessIndicatorVisible(false), 3000);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, mutateUpdate, nameDisplayValue, userGroup]);
  const onClickCancelRename = useCallback(() => {
    setNameDisplayValue(userGroup.name);
    setIsRenaming(false);
  }, [userGroup.name]);
  const onClickDelete = useCallback(() => setIsDeleteConfirmDialogOpen(true), []);
  const onClickConfirmDelete = useCallback(async () => {
    setIsRenaming(false);
    try {
      await mutateDelete(userGroup.id);
      setDeleteSuccessMessage(t('user-group-list-item_delete-success-message', 'Delete successfull'));
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
    setIsDeleteConfirmDialogOpen(false);
  }, [getRequestErrorMessage, mutateDelete, t, userGroup.id]);
  const onClickCancelDelete = useCallback(() => {
    setIsDeleteConfirmDialogOpen(false);
  }, []);

  const canEdit = useMemo(() => currentUserRole?.allowedActions?.has(RoleAction.Project_EditGroups), [currentUserRole]);
  const canDelete = useMemo(() => currentUserRole?.allowedActions?.has(RoleAction.Project_DeleteGroups), [currentUserRole]);

  return (
    <>
      <Box sx={{
        ...sx, display: 'flex', alignItems: 'center', minHeight: '33px',
      }}
      >
        {!isRenaming && (
          <>
            <Typography sx={{ mr: 'auto' }}>{nameDisplayValue}</Typography>
            {isSuccessIndicatorVisible && <CheckIcon color="success" />}
            {isLoadingUpdate && <CircularProgress size={12} sx={{ mr: 1 }} />}
            {canDelete && <Button onClick={onClickDelete} variant="contained" color="secondary" sx={{ minWidth: 'unset', px: 1, ml: 1 }} disabled={isLoadingUpdate || isLoadingDelete || userGroup.isDefault}><DeleteIcon /></Button>}
            {canEdit && <Button onClick={onClickRename} variant="contained" color="secondary" sx={{ minWidth: 'unset', px: 1, ml: 1 }} disabled={isLoadingUpdate || isLoadingDelete || userGroup.isDefault}><DriveFileRenameOutlineIcon /></Button>}
          </>
        )}
        {isRenaming && (
          <>
            <TextField
              variant="standard"
              sx={{ mr: 'auto' }}
              value={nameDisplayValue}
              onChange={onChangeRenameTextField}
              autoFocus
              error={hasNamingConflict}
              helperText={hasNamingConflict ? t('user-group-list-item_naming-conflict-error-message', 'Must be unique') : undefined}
            />
            <Button onClick={onClickCancelRename} variant="contained" color="secondary" sx={{ minWidth: 'unset', px: 1, ml: 1 }}><UndoIcon /></Button>
            <Button onClick={onClickConfirmRename} variant="contained" color="primary" sx={{ minWidth: 'unset', px: 1, ml: 1 }} disabled={isLoadingUpdate || hasNamingConflict}><CheckIcon /></Button>
          </>
        )}
      </Box>
      {isDeleteConfirmDialogOpen && (
      <Dialog open PaperProps={{ sx: { minWidth: '450px' } }}>
        <DialogTitle>{t('user-group-list-item_confirm-delete-dialog-title', 'Delete User Group')}</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            <Trans
              t={t}
              i18nKey="user-group-list-item_confirm-delete-dialog-message"
              components={{ s: <strong /> }}
              defaults="Are you sure that you want to delete the user group <s>{{userGroupName}}</s>?"
              values={{ userGroupName: userGroup.name }}
            />
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClickConfirmDelete} sx={{ mr: 'auto' }} disabled={isLoadingDelete}>
            {isLoadingDelete && <CircularProgress size={12} sx={{ mr: 1 }} />}
            {t('user-group-list-item_confirm-delete-button', 'Yes, delete group')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onClickCancelDelete}>{t('user-group-list-item_cancel-delete-button', 'No, cancel')}</Button>
        </DialogActions>
      </Dialog>
      )}
      {!!deleteSuccessMessage && (
      <Dialog open PaperProps={{ sx: { minWidth: '450px' } }}>
        <DialogTitle>{t('user-group-list-item_delete-success-dialog-title', 'Success')}</DialogTitle>
        <DialogContent>
          <Alert severity="success">
            {deleteSuccessMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setDeleteSuccessMessage(undefined)}>{t('user-group-list-item_delete-success-dialog-close', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
      {!!errorMessage && (
      <Dialog open PaperProps={{ sx: { minWidth: '450px' } }}>
        <DialogTitle>{t('user-group-list-item_delete-error-dialog-title', 'Error')}</DialogTitle>
        <DialogContent>
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setErrorMessage(undefined)}>{t('user-group-list-item_delete-error-dialog-close', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </>
  );
}
