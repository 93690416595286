import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useBcfUploadHandler from 'issues/hooks/useBcfUploadHandler';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import BcfImportProgressDialog from 'issues/components/BcfImportProgressDialog';
import { mdiApplicationImport } from '@mdi/js';
import Icon from '@mdi/react';

type BcfImportButtonProps = Omit<ButtonProps, 'onClick'>;

export default function BcfImportButton(props: BcfImportButtonProps) {
  const { t } = useTranslation('issues');
  const inputRef = useRef<HTMLInputElement>(null);
  const onClick = useCallback(() => inputRef.current?.showPicker(), []);
  const getRequestErrorMessage = useRequestErrorMessage();
  const uploadBcfFiles = useBcfUploadHandler();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [progressDialogVisible, setProgressDialogVisible] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const onCloseProgressDialog = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setProgressDialogVisible(false);
    setProgress(0);
    setCompleted(false);
    setFileNames([]);
  }, []);

  const onChangeFileInput = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;
    try {
      setProgressDialogVisible(true);
      const files = Array.from(e.target.files);
      setFileNames(files.map((file) => file.name));
      await uploadBcfFiles(files, (value: number) => setProgress(value));
      setProgress(1);
      setCompleted(true);
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, uploadBcfFiles]);
  return (
    <>
      <Button id="BcfImportButton" variant="outlined" sx={{ pl: 1.5, gap: 1 }} {...props} onClick={onClick}>
        <Icon path={mdiApplicationImport} size={0.8} />
        {t('bcf-import-button_label', 'Import from BCF')}
      </Button>
      <input ref={inputRef} hidden accept=".bcf" multiple type="file" onChange={onChangeFileInput} />
      {!!progressDialogVisible && (
        <BcfImportProgressDialog
          fileNames={fileNames}
          onClose={onCloseProgressDialog}
          errorMessage={errorMessage}
          progress={progress}
          completed={completed}
        />
      )}
    </>
  );
}
