import React, { useCallback, useMemo, useState } from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import { ITEM_ROOT } from 'odata-query';
import IssueItemCard from 'issues/components/IssueItemCard';
import ComponentDetailsIssueDetailsPanel from 'models/components/ComponentDetailsIssueDetailsPanel';

interface ComponentDetailsLinkedIssuesPanelProps extends ISxProps {
  globalId: string,
}

export default function ComponentDetailsLinkedIssuesPanel({
  sx,
  globalId,
}: ComponentDetailsLinkedIssuesPanelProps) {
  const theme = useTheme();
  const [selectedIssueId, setSelectedIssueId] = useState<string | undefined>();
  const issuesOdataQuery = useMemo(() => ({
    filter: {
      or: [
        { linkedComponentsGlobalIds: { any: { [ITEM_ROOT]: globalId } } },
      ],
    },
  }), [globalId]);
  const { data: issues, isLoading: isLoadingIssues } = useIssuesOdataQuery(issuesOdataQuery);
  const onClickIssue = useCallback((issueId: string) => setSelectedIssueId(issueId), [setSelectedIssueId]);
  const onCloseIssueDetails = useCallback(() => setSelectedIssueId(undefined), []);
  if (selectedIssueId) return <ComponentDetailsIssueDetailsPanel issueId={selectedIssueId} onClose={onCloseIssueDetails} />;
  return (
    <Box
      id="ComponentDetailsLinkedIssuesPanel"
      sx={{ flex: '1 1 0', display: 'flex', background: theme.palette.grey[200], flexDirection: 'column', overflow: 'auto', p: 2, gap: 2, boxShadow: 'inset 0px 24px 24px -24px rgba(0,0,0,0.1)', ...sx }}
    >
      {!!isLoadingIssues && <CircularProgress sx={{ mt: 4, alignSelf: 'center' }} />}
      {!!issues && issues.map((issue) => <IssueItemCard key={issue.id} issue={issue} onClick={onClickIssue} />)}
    </Box>
  );
}
