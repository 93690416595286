import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useDocumentFilterContext from 'documents/hooks/useDocumentFilterContext';
import useDocumentVersionsOdataQueryData from 'documents/hooks/useDocumentVersionsOdataQueryData';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiTableArrowDown } from '@mdi/js';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import DocumentsDataGridRow from 'documents/types/DocumentDataGridRow';
import DocumentOptionsContext, { DocumentOptionsContextState } from 'documents/contexts/DocumentOptionsContext';
import DocumentAbbreviationDisplayMode from 'documents/types/DocumentAbbreviationDisplayMode';
import useLabelsOdataQueryData from 'labels/hooks/useLabelsOdataQueryData';
import useLetterNumbering from 'documents/hooks/useLetterNumbering';
import useFolderTreeQuery from 'documents-folders/hooks/useFolderTreeQuery';
import useDocumentSelectionContext from 'documents/hooks/useDocumentSelectionContext';
import useNamingSchemeSettingsQuery from 'naming-schemes/hooks/useNamingSchemeSettingsQuery';
import useDocumentsDynamicLayout from 'dynamic-layout/hooks/useDocumentsDynamicLayout';
import useDocumentsDataGridValueFormatters from 'documents/hooks/useDocumentsDataGridValueFormatters';

const HIDDEN_COLUMNS = new Set(['documentImportStatus']);

interface DocumentVersionsCsvExportButtonProps extends ISxProps {
  onError?: (message: string) => void,
}

export default function DocumentVersionsCsvExportButton({
  sx,
  onError,
}: DocumentVersionsCsvExportButtonProps) {
  const { t } = useTranslation('documents');
  const { odataQuery: odataFilter } = useDocumentFilterContext();
  const { selectedDocumentVersionIds, selectedDocumentVersionIdsSet } = useDocumentSelectionContext();
  const requestDocumentVersionsByIds = useMemo(() => selectedDocumentVersionIds.length < 100, [selectedDocumentVersionIds]); // if many items are selected, requesting by ID would result in a very long URL => request all document versions for the current filter and then filter by selection later
  const documentVersionDataOdataFilter = useMemo(() => (requestDocumentVersionsByIds ? { ...odataFilter, filter: { id: { in: selectedDocumentVersionIds } } } : odataFilter), [odataFilter, requestDocumentVersionsByIds, selectedDocumentVersionIds]);
  const [isWaitingForDownloadToStart, setIsWaitingForDownloadToStart] = useState(false);
  const getRequestErrorMessage = useRequestErrorMessage();
  const getDocumentVersionsOdataQueryData = useDocumentVersionsOdataQueryData();
  const getLabelsOdataQueryData = useLabelsOdataQueryData();
  const { abbreviationDisplayMode } = useContext<DocumentOptionsContextState>(DocumentOptionsContext);
  const { orderedVisibleColumnNames } = useDocumentsDynamicLayout();
  const { data: namingSchemeSettings } = useNamingSchemeSettingsQuery();
  const { data: folderTreeData } = useFolderTreeQuery();
  const { numberToLetter } = useLetterNumbering();
  const valueFormatterByColumnName = useDocumentsDataGridValueFormatters();
  const onClick = useCallback(async () => {
    if (!documentVersionDataOdataFilter || !orderedVisibleColumnNames || !namingSchemeSettings || !folderTreeData) throw new Error('dependency error');
    setIsWaitingForDownloadToStart(true);
    try {
      const filterdColumnNames = orderedVisibleColumnNames.filter((columnName) => !HIDDEN_COLUMNS.has(columnName));
      const stringValueFormatters = new Map(valueFormatterByColumnName);
      const labelsById = new Map((await getLabelsOdataQueryData({})).map((label) => [label.id, label]));
      const labelValueFormatter = (labelId: string) => {
        const label = labelsById.get(labelId);
        return (abbreviationDisplayMode === DocumentAbbreviationDisplayMode.Abbreviation && label?.abbreviation ? label.abbreviation : label?.name ?? '');
      };
      stringValueFormatters.set('disciplines', ({ data }) => data?.disciplines.map(labelValueFormatter).join(', ') ?? '');
      stringValueFormatters.set('building', ({ data }) => (data?.building ? labelValueFormatter(data.building) : undefined) ?? '');
      stringValueFormatters.set('floor', ({ data }) => (data?.floor ? labelValueFormatter(data.floor) : undefined) ?? '');
      stringValueFormatters.set('workPhase', ({ data }) => (data?.workPhase ? labelValueFormatter(data.workPhase) : undefined) ?? '');
      stringValueFormatters.set('tags', ({ data }) => data?.tags.map(labelValueFormatter).join(', ') ?? '');
      stringValueFormatters.set('versionNumber', ({ data }) => (data ? namingSchemeSettings?.useLetterVersioning ? numberToLetter(data.versionNumber) : `${data.versionNumber}` : ''));
      stringValueFormatters.set('folderId', ({ data }) => {
        if (!data) return '';
        const path = data.folderPath.split('//').map((folderId) => folderTreeData.foldersById.get(folderId)?.name ?? '').join('/');
        return path;
      });
      const documentVersionDtos = await getDocumentVersionsOdataQueryData(documentVersionDataOdataFilter);
      const selectedDocumentVersionDtos = requestDocumentVersionsByIds ? documentVersionDtos : documentVersionDtos.filter((dto) => selectedDocumentVersionIdsSet.has(dto.id));
      const formattedData = selectedDocumentVersionDtos.map((row) => filterdColumnNames.map((columnName) => {
        const valueFormatter = stringValueFormatters.get(columnName);
        const formattedValue = valueFormatter ? valueFormatter({ data: row }) : `${row[columnName as keyof DocumentsDataGridRow]}`;
        return formattedValue;
      }));
      const columnHeaders = filterdColumnNames.map((columnName) => t(`dms-table-column_${columnName}`, columnName));
      const rows = [columnHeaders, ...formattedData];
      const csv = rows.map((row) => row.map((value) => `"${value.replace('"', '""')}"`).join(';')).join('\n');
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/plain;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`);
      element.setAttribute('download', `visoplan_documents_${(new Date().toJSON().slice(0, 10))}.csv`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      onError?.(getRequestErrorMessage(error));
    } finally {
      setIsWaitingForDownloadToStart(false);
    }
  }, [abbreviationDisplayMode, documentVersionDataOdataFilter, folderTreeData, getDocumentVersionsOdataQueryData, getLabelsOdataQueryData, getRequestErrorMessage, namingSchemeSettings, numberToLetter, onError, orderedVisibleColumnNames, requestDocumentVersionsByIds, selectedDocumentVersionIdsSet, t, valueFormatterByColumnName]);
  return (
    <Button
      id="DocumentVersionsCsvExportButton"
      onClick={onClick}
      sx={{ ...sx, pl: 1, gap: 1 }}
      variant="contained"
      disabled={isWaitingForDownloadToStart}
    >
      {!isWaitingForDownloadToStart && <Icon path={mdiTableArrowDown} size={1} />}
      {!!isWaitingForDownloadToStart && <CircularProgress size={12} />}
      {t('document-versions-csv-export-button_label', 'Export as CSV')}
    </Button>
  );
}
