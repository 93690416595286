import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Alert, Divider, Menu, MenuItem } from '@mui/material';
import useViewer3dContext from 'models/hooks/useViewer3dContext';
import { useTranslation } from 'react-i18next';
import ColorPicker from 'common/components/ColorPicker';
import CreateIssueDialog from 'issues/components/CreateIssueDialog';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';

export interface Viewer3dContextMenuState {
  position: {
    top: number;
    left: number;
  },
}

interface Viewer3dContextMenuProps {
  onClose: () => void,
  state: Viewer3dContextMenuState | undefined,
}

export default function Viewer3dContextMenu({
  onClose,
  state,
}: Viewer3dContextMenuProps) {
  const { t } = useTranslation('models');
  const {
    interactionMode: viewer3dInteractionMode,
    selectedGlobalIds,
    getNode,
    addClippingPlaneAtCursor,
    zoomToSelectedComponents,
    hideSelectedComponents,
    isolateSelectedComponents,
    toggleSelectedComponentsTransparent,
    resetSelectedComponentsPresentation,
    setSelectedComponentsColor,
    resetSelectedComponentsColor,
    hideComponentsOfDifferentTypeThanSelected,
    isolateSelectedComponentsFloors,
    createViewpoint,
  } = useViewer3dContext();

  const { setViewpointItems, interactionMode: modelsInteractionMode, setLinkedComponentItems } = useModelsInteractionContext();

  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const onClickSetComponentsColor = useCallback(() => setColorPickerOpen(true), []);
  const colorMenuItemRef = useRef<HTMLLIElement>(null);
  const onConfirmColorPicker = useCallback((value: string) => {
    if (value) {
      setSelectedComponentsColor(value);
    }
    setColorPickerOpen(false);
    onClose();
  }, [onClose, setSelectedComponentsColor]);
  const onCancelColorPicker = useCallback(() => setColorPickerOpen(false), []);

  const onClickZoomToComponents = useCallback(async () => {
    await zoomToSelectedComponents();
    onClose();
  }, [onClose, zoomToSelectedComponents]);

  const onClickHideComponents = useCallback(() => {
    hideSelectedComponents();
    onClose();
  }, [hideSelectedComponents, onClose]);

  const onClickHideComponentsOfDifferentType = useCallback(() => {
    hideComponentsOfDifferentTypeThanSelected();
    onClose();
  }, [hideComponentsOfDifferentTypeThanSelected, onClose]);

  const onClickIsolateComponents = useCallback(() => {
    isolateSelectedComponents();
    onClose();
  }, [isolateSelectedComponents, onClose]);

  const onClickIsolateFloor = useCallback(() => {
    isolateSelectedComponentsFloors();
    onClose();
  }, [isolateSelectedComponentsFloors, onClose]);

  const onClickToggleDisplayComponentsTransparent = useCallback(() => {
    toggleSelectedComponentsTransparent();
    onClose();
  }, [onClose, toggleSelectedComponentsTransparent]);

  const onClickResetComponentPresentation = useCallback(() => {
    resetSelectedComponentsPresentation();
    onClose();
  }, [onClose, resetSelectedComponentsPresentation]);

  const onClickCreateSection = useCallback(() => {
    addClippingPlaneAtCursor();
    onClose();
  }, [addClippingPlaneAtCursor, onClose]);

  const [createIssueDialogOpen, setCreateIssueDialogOpen] = useState<boolean>(false);
  const onClickCreateIssue = useCallback(async () => {
    if (selectedGlobalIds.length) {
      setLinkedComponentItems(selectedGlobalIds.map((globalId) => {
        const node = getNode(globalId);
        if (node?.elementName) {
          return {
            nodeLoaded: true,
            elementName: node.elementName,
            globalId,
          };
        }
        return {
          nodeLoaded: false,
          globalId,
        };
      }));
    }
    setCreateIssueDialogOpen(true);
    const viewpoint = await createViewpoint();
    if (!viewpoint) throw new Error('Failed to create viewpoint');
    setViewpointItems([viewpoint]);
    onClose();
  }, [createViewpoint, getNode, onClose, selectedGlobalIds, setLinkedComponentItems, setViewpointItems]);
  const onCancelCreateIssueDialog = useCallback(() => {
    setCreateIssueDialogOpen(false);
  }, []);
  const onConfirmCreateIssueDialog = useCallback((createdIssueId: string | undefined, keepOpen: boolean) => {
    if (!keepOpen) {
      setCreateIssueDialogOpen(false);
    }
    setViewpointItems([]);
  }, [setViewpointItems]);

  const onClickResetComponentColor = useCallback(() => {
    resetSelectedComponentsColor();
    setColorPickerOpen(false);
    onClose();
  }, [onClose, resetSelectedComponentsColor]);

  const allowedActions = useAllowedActions();
  const canCreateIssues = useMemo(() => allowedActions?.has(RoleAction.IssueManagement_Creation), [allowedActions]);

  return (
    <>
      <Menu
        id="Viewer3dContextMenu"
        open={!!state}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={state?.position}
      >
        {!!selectedGlobalIds.length && (
          [
            <Alert
              severity="info"
              icon={false}
              id="Viewer3dContextMenuComponentCount"
              key="Viewer3dContextMenuComponentCount"
              sx={{ fontWeight: 600 }}
            >
              {t('viewer-3d-context-menu_selected-count-menu-item-label', '{{count}} components selected', { count: selectedGlobalIds.length })}
            </Alert>,
            <MenuItem
              id="Viewer3dContextMenuSetColorMenuItem"
              key="Viewer3dContextMenuSetColorMenuItem"
              onClick={onClickSetComponentsColor}
              ref={colorMenuItemRef}
            >
              {t('viewer-3d-context-menu_set-color-menu-item-label', 'Set components\' color')}
            </MenuItem>,
            <MenuItem
              id="Viewer3dContextMenuZoomToSelectionMenuItem"
              key="Viewer3dContextMenuZoomToSelectionMenuItem"
              onClick={onClickZoomToComponents}
            >
              {t('viewer-3d-context-menu_zoom-to-selection-menu-item-label', 'Zoom to components')}
            </MenuItem>,
            <MenuItem
              id="Viewer3dContextMenuHideComponentMenuItem"
              key="Viewer3dContextMenuHideComponentMenuItem"
              onClick={onClickHideComponents}
            >
              {t('viewer-3d-context-menu_hide-object-menu-item-label', 'Hide components')}
            </MenuItem>,
            <MenuItem
              id="Viewer3dContextMenuHideComponentOfDifferentTypeMenuItem"
              key="Viewer3dContextMenuHideComponentOfDifferentTypeMenuItem"
              onClick={onClickHideComponentsOfDifferentType}
            >
              {t('viewer-3d-context-menu_hide-objects-of-different-type-menu-item-label', 'Hide all components of a different type')}
            </MenuItem>,
            <MenuItem
              id="Viewer3dContextMenuIsolateComponentMenuItem"
              key="Viewer3dContextMenuIsolateComponentMenuItem"
              onClick={onClickIsolateComponents}
            >
              {t('viewer-3d-context-menu_isolate-object-menu-item-label', 'Isolate components')}
            </MenuItem>,
            <MenuItem
              id="Viewer3dContextMenuIsolateFloorMenuItem"
              key="Viewer3dContextMenuIsolateFloorMenuItem"
              onClick={onClickIsolateFloor}
            >
              {t('viewer-3d-context-menu_isolate-floor-menu-item-label', 'Isolate floor')}
            </MenuItem>,
            <MenuItem
              id="Viewer3dContextMenuToggleTransparencyMenuItem"
              key="Viewer3dContextMenuToggleTransparencyMenuItem"
              onClick={onClickToggleDisplayComponentsTransparent}
            >
              {t('viewer-3d-context-menu_toggle-transparency-menu-item-label', 'Toggle display transparent')}
            </MenuItem>,
            <MenuItem
              id="Viewer3dContextMenuResetPresentationMenuItem"
              key="Viewer3dContextMenuResetPresentationMenuItem"
              onClick={onClickResetComponentPresentation}
            >
              {t('viewer-3d-context-menu_reset-presentation-menu-item-label', 'Reset component presentation')}
            </MenuItem>,
            <Divider key="Viewer3dContextMenuDivider1" />,
          ]
        )}
        <MenuItem
          id="Viewer3dContextMenuCreateSectionMenuItem"
          onClick={onClickCreateSection}
        >
          {t('viewer-3d-context-menu_create-section-menu-item-label', 'Create Section')}
        </MenuItem>
        {!!canCreateIssues && (
          <MenuItem
            id="Viewer3dContextMenuCreateIssueMenuItem"
            onClick={onClickCreateIssue}
            disabled={!!viewer3dInteractionMode || !!modelsInteractionMode}
          >
            {t('viewer-3d-context-menu_create-issue-menu-item-label', 'Create Issue')}
          </MenuItem>
        )}
      </Menu>
      <ColorPicker
        open={colorPickerOpen}
        anchorEl={colorMenuItemRef.current}
        onConfirm={onConfirmColorPicker}
        onCancel={onCancelColorPicker}
        onReset={onClickResetComponentColor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
      {!!createIssueDialogOpen && (
        <CreateIssueDialog
          open={!viewer3dInteractionMode && !modelsInteractionMode}
          onConfirm={onConfirmCreateIssueDialog}
          onCancel={onCancelCreateIssueDialog}
        />
      )}
    </>
  );
}
