import type { Identifier, XYCoord } from 'dnd-core';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { Alert, IconButton, Radio, TableCell, TableRow } from '@mui/material';
import useLabelIcons from 'settings/hooks/usLabelIcons';
import LabelDto from 'labels/types/LabelDto';
import IconChip from 'labels/components/IconChip';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import LabelType from 'labels/types/LabelType';
import useProjectUpdateDefaultLabelsMutation from 'projects/hooks/useProjectUpdateDefaultLabelsMutation';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useLabelTypeProperties from 'settings/hooks/useLabelTypeProperties';

const ItemTypes = {
  CARD: 'card',
};

interface DragItem {
  index: number
  id: string
  type: string
}

interface LabelSettingsTableItemProps {
  label: LabelDto,
  index: number,
  moveItem: (dragIndex: number, hoverIndex: number) => void,
  onDrop: () => void,
  onClickEdit: (labeld: string) => void,
  canEdit: boolean,
}

export default function LabelSettingsTableItem({
  label,
  index,
  moveItem,
  onDrop,
  onClickEdit,
  canEdit,
}: LabelSettingsTableItemProps) {
  const { data: currentProject } = useCurrentProjectQuery();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const { hasColor, hasIcon, hasAbbreviation } = useLabelTypeProperties(label.type);

  const ref = useRef<HTMLTableRowElement>(null);
  const { mutateAsync: updateAsync } = useProjectUpdateDefaultLabelsMutation();
  const onClickEditButton = useCallback(() => onClickEdit(label.id), [label.id, onClickEdit]);
  const [{ handlerId }, drop] = useDrop<
  DragItem,
  void,
  { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
    drop: (item, monitor) => {
      if (monitor.canDrop()) {
        // item was dropped in the drop target
        onDrop();
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    canDrag: canEdit,
    item: () => ({ order: label.order, index }),
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        // Item was dropped outside of the drop target
        onDrop();
      }
    },
  });

  const handleRadioChange = useCallback(async () => {
    if (!currentProject) return;
    try {
      await updateAsync({ id: currentProject.id, defaultDocStatus: label.id, defaultIssueStatus: currentProject.defaultIssueStatus, architectureLabel: currentProject.architectureLabel });
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [currentProject, getRequestErrorMessage, label.id, updateAsync]);

  const { defaultIcons, otherIcons } = useLabelIcons();
  const iconPath = useMemo(() => (label.icon ? defaultIcons.get(label.icon) ?? otherIcons.get(label.icon) : undefined), [defaultIcons, label.icon, otherIcons]);

  const backgroundColor = index % 2 === 0 ? '#fafafa' : 'white';
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref)); // Makes ref draggable and drop target
  return (
    <TableRow id="LabelSettingsTableItem" ref={ref} style={{ display: 'table-row', height: 45, cursor: canEdit ? 'move' : undefined, opacity, backgroundColor }} data-handler-id={handlerId}>
      <TableCell>{!!canEdit && <DragHandleIcon />}</TableCell>
      <TableCell><IconChip text={label.name} color={hasColor ? label.color : undefined} mdiIconPath={hasIcon ? iconPath : undefined} /></TableCell>
      {hasAbbreviation && <TableCell sx={{ textAlign: 'center' }}>{label.abbreviation}</TableCell>}
      {label.type === LabelType.DocumentStatus && (
        <TableCell sx={{ textAlign: 'center' }}>
          <Radio onChange={handleRadioChange} checked={label.id === currentProject?.defaultDocStatus} />
        </TableCell>
      )}
      {label.type === LabelType.Discipline && (
        <TableCell sx={{ textAlign: 'center' }}>
            {label.id === currentProject?.architectureLabel && (
            <CheckIcon />
            )}
        </TableCell>
      )}
      <TableCell>
        <IconButton id={label.abbreviation} size="small" onClick={onClickEditButton} disabled={label.isDefault === true}>
          <EditIcon fontSize="small" />
        </IconButton>
      </TableCell>
      {!!errorMessage && <TableRow><TableCell><Alert style={{ position: 'absolute' }} severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert></TableCell></TableRow>}
    </TableRow>
  );
}
