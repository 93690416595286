import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import useProjectDisciplinesQuery from 'labels/hooks/useProjectDisciplinesQuery';
import LabelSettingsPanel from 'settings/components/LabelSettingsPanel';
import LabelType from 'labels/types/LabelType';
import useProjectBuildingsQuery from 'labels/hooks/useProjectBuildingsQuery';
import useProjectFloorsQuery from 'labels/hooks/useProjectFloorsQuery';

export default function ModelMetadataSettingsTab() {
  const allowedActions = useAllowedActions();
  const canCreate = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Create), [allowedActions]);
  const canEdit = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Update), [allowedActions]);
  const canDelete = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Delete), [allowedActions]);
  const { data: disciplines } = useProjectDisciplinesQuery();
  const { data: buildings } = useProjectBuildingsQuery();
  const { data: floors } = useProjectFloorsQuery();
  return (
    <Box id="ModelMetadataSettingsTab" sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'flex-start', gap: 4, p: 4 }}>
      <Box>
        <LabelSettingsPanel
          labelType={LabelType.Discipline}
          labels={disciplines}
          canEdit={canEdit}
          canCreate={canCreate}
          canDelete={canDelete}
          variant="card"
        />
      </Box>
      <Box>
        <LabelSettingsPanel
          labelType={LabelType.Building}
          labels={buildings}
          canEdit={canEdit}
          canCreate={canCreate}
          canDelete={canDelete}
          variant="card"
        />
      </Box>
      <Box>
        <LabelSettingsPanel
          labelType={LabelType.Floor}
          labels={floors}
          canEdit={canEdit}
          canCreate={canCreate}
          canDelete={canDelete}
          variant="card"
        />
      </Box>
    </Box>
  );
}
