import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ICellRendererParams } from '@ag-grid-community/core';
import CollaboratorsDataGridRow from 'collaborators/types/CollaboratorsDataGridRow';
import useUserGroupUpdateMutation from 'users-groups/hooks/useUserGroupUpdateMutation';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';
import ChipMultiselect from 'common/components/ChipMultiselect';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import UserGroupDto from 'users-groups/types/UserGroupDto';
import UserGroupUpdateDto from 'users-groups/types/UserGroupUpdateDto';

export default function GroupCell({
  data,
}: ICellRendererParams<CollaboratorsDataGridRow, string>) {
  const currentUserRole = useCurrentCollaboratorRole();
  const { data: currentProjectUserGroups } = useUserGroupsQuery();
  const defaultUserGroupIds = useMemo(() => currentProjectUserGroups?.filter((g) => g.isDefault).map((g) => g.id), [currentProjectUserGroups]);
  const userGroupsById = useMemo(() => (currentProjectUserGroups ? new Map<string, UserGroupDto>(currentProjectUserGroups.map((g) => [g.id, g])) : undefined), [currentProjectUserGroups]);
  const { mutateAsync, isPending } = useUserGroupUpdateMutation();
  const [displayValue, setDisplayValue] = useState<string[]>(data?.userGroups?.map((g) => g.id) ?? []);
  useEffect(() => setDisplayValue(data?.userGroups?.map((g) => g.id) ?? []), [data?.userGroups]);

  const onChange = useCallback(async (selectedUserGroupIds: string[]) => {
    if (!data?.userGroups) return;
    setDisplayValue(selectedUserGroupIds);

    const previousUserGroupIds = new Set(data.userGroups.map((g) => g.id));
    const nextUserGroupIds = new Set(selectedUserGroupIds);
    const userGroupsAdded = selectedUserGroupIds.filter((id) => !previousUserGroupIds.has(id)).filter((id) => userGroupsById?.has(id)).map((id) => userGroupsById?.get(id)!);
    const userGroupsRemoved = data.userGroups.map((g) => g.id).filter((id) => !nextUserGroupIds.has(id)).filter((id) => userGroupsById?.has(id)).map((id) => userGroupsById?.get(id)!);
    const addUpdateDtos: UserGroupUpdateDto[] = userGroupsAdded.map((group) => ({
      id: group.id,
      collaboratorIds: [...group.collaboratorIds, data.id],
    }));
    const removeUpdateDtos: UserGroupUpdateDto[] = userGroupsRemoved.map((group) => ({
      id: group.id,
      collaboratorIds: group.collaboratorIds.filter((id) => id !== data.id),
    }));
    await Promise.all(addUpdateDtos.concat(removeUpdateDtos).map(async (dto) => {
      await mutateAsync(dto);
    }));
  }, [data, mutateAsync, userGroupsById]);
  if (!currentUserRole?.allowedActions?.has(RoleAction.Project_EditMembers)) return data?.userGroups?.map((g) => g.name).join(', ');
  return (
    <ChipMultiselect
      entities={currentProjectUserGroups}
      disabledEntityIds={defaultUserGroupIds}
      value={displayValue}
      disabled={!currentProjectUserGroups?.length || isPending || !currentUserRole?.allowedActions?.has(RoleAction.Project_EditMembers)}
      onChange={onChange}
      variant="standard"
      sx={{ width: '100%' }}
      suppressSelectAll
    />
  );
}
