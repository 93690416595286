import { Alert, Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useUpdateNamingSchemeSettingsMutation from 'naming-schemes/hooks/useUpdateNamingSchemeSettingsMutation';
import React, { useCallback, useMemo, useState } from 'react';
import VersionNumbering from 'settings/types/VersionNumbering';
import useNamingSchemeSettingsQuery from 'naming-schemes/hooks/useNamingSchemeSettingsQuery';
import { useTranslation } from 'react-i18next';

export default function DocumentSettingsVersionsSection() {
  const { t } = useTranslation('settings');
  const { data: namingSchemeSettings } = useNamingSchemeSettingsQuery();

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const { mutateAsync } = useUpdateNamingSchemeSettingsMutation();

  const versionNumbering = useMemo(() => (namingSchemeSettings ? (namingSchemeSettings.useLetterVersioning ? VersionNumbering.Letters : VersionNumbering.Numbers) : ''), [namingSchemeSettings]);

  const onChangeVersioning = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const useLetterVersioning = event.target.value === VersionNumbering.Letters;
    if (!namingSchemeSettings) return;
    try {
      await mutateAsync({
        allowUploadFilesWhenSchemaByTypeNotFound: namingSchemeSettings.allowUploadFilesWhenSchemaByTypeNotFound,
        bypassRestrictionForPrivateDocs: namingSchemeSettings.bypassRestrictionForPrivateDocs,
        excludedFolders: namingSchemeSettings.excludedFolders,
        forceUploadRestriction: namingSchemeSettings.forceUploadRestriction,
        useLetterVersioning,
      });
    } catch (error) {
      setErrorMessage(getRequestErrorMessage(error));
    }
  }, [getRequestErrorMessage, namingSchemeSettings, mutateAsync]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h4">{t('document-settings-versions-section_title', 'Versions')}</Typography>
      <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <Typography>{t('document-settings-versions-section_version-select-label', 'Define document version:')}</Typography>
        <FormControl sx={{ display: 'flex', gap: 2 }} disabled={!namingSchemeSettings}>
          <RadioGroup row value={versionNumbering} onChange={onChangeVersioning}>
            <FormControlLabel value={VersionNumbering.Numbers} control={<Radio />} label={t('document-settings-versions-section_numbers-label', 'Numbers')} />
            <FormControlLabel value={VersionNumbering.Letters} control={<Radio />} label={t('document-settings-versions-section_letters-label', 'Letters')} />
          </RadioGroup>
        </FormControl>
      </Box>
      {!!errorMessage && <Alert style={{ position: 'absolute' }} severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
    </Box>
  );
}
