import React, { useCallback, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useUserGroupsOdataQuery from 'users-groups/hooks/useUserGroupsOdataQuery';

interface AssignGroupDialogProps {
  onClose: (result?: { selectedGroupId: string, groupCollaboratorIds: string[] }) => void,
}

export default function AssignGroupDialog({
  onClose,
}: AssignGroupDialogProps) {
  const { t } = useTranslation('issues');
  const { data: groups } = useUserGroupsOdataQuery({ filter: { isDeleted: false } });
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');
  const onChangeSelectedGroup = useCallback((event: SelectChangeEvent<string>) => setSelectedGroupId(event.target.value), []);
  const onCancel = useCallback(() => onClose(), [onClose]);
  const onConfirm = useCallback(() => {
    if (!groups) throw new Error('dependency error');
    if (selectedGroupId.length === 0) throw new Error('invalid input');
    const group = groups?.find((g) => g.id === selectedGroupId);
    if (!group) throw new Error('group entity missing');
    onClose({ selectedGroupId, groupCollaboratorIds: group.collaboratorIds });
  }, [groups, onClose, selectedGroupId]);
  return (
    <Dialog id="AssignGroupDialog" open>
      <DialogTitle>{t('assign-group-dialog_title', 'Assign Group Members')}</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1, display: 'flex', flexDirection: 'column', minWidth: 450 }}>
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel id="assign-group-dialog_group-select-label">
              {t('assign-group-dialog_group-select-label', 'Group')}
            </InputLabel>
            <Select
              id="assign-group-dialog_group-select"
              labelId="assign-group-dialog_group-select-label"
              value={selectedGroupId}
              onChange={onChangeSelectedGroup}
              label={t('assign-group-dialog_group-select-label', 'Assigned')}
            >
              {!!groups && groups.map((group) => <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onCancel}>{t('assign-group-dialog_cancel-button-label', 'Cancel')}</Button>
        <Button variant="contained" color="primary" onClick={onConfirm} sx={{ ml: 'auto' }}>{t('assign-group-dialog_confirm-button-label', 'Assign Group Members')}</Button>
      </DialogActions>
    </Dialog>
  );
}
