import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, ColumnMovedEvent, ColumnResizedEvent, GridApi, RowClassRules, RowClickedEvent } from '@ag-grid-community/core';
import { useTranslation } from 'react-i18next';
import AG_GRID_LOCALE_DE from 'setup/agGrid.locale.de';
import AG_GRID_LOCALE_EN from 'setup/agGrid.locale.en';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import useIssuesSelectionContext from 'issues/hooks/useIssuesSelectionContext';
import IssueDto from 'issues/types/IssueDto';
import CheckboxCell from 'issues/components/cell-renderers/CheckboxCell';
import CheckboxHeaderCell from 'issues/components/cell-renderers/CheckboxHeaderCell';
import useIssueItemClickHandler from 'issues/hooks/useIssueItemClickHandler';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useOdataRowRangeAgGridDatasource from 'api/hooks/useOdataRowRangeAgGridDatasource';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useIssuesDataGridColumnDefinitions from 'issues/hooks/useIssuesDataGridColumnDefinitions';
import useIssuesDynamicLayout from 'dynamic-layout/hooks/useIssuesDynamicLayout';

const modules = [ServerSideRowModelModule];

interface IssuesDataGridProps {
  disabledIssueIds?: Set<string> | undefined,
}

export default function IssuesDataGrid({
  disabledIssueIds,
}: IssuesDataGridProps) {
  const gridRef = useRef<AgGridReact<IssueDto>>(null);
  const { selectedIssueIds, multiselectActive } = useIssuesSelectionContext();
  const selectedIssueIdsSet = useMemo(() => new Set(selectedIssueIds), [selectedIssueIds]);

  const onClickIssueItem = useIssueItemClickHandler(disabledIssueIds);
  const onRowClicked = useCallback(async (event: RowClickedEvent<IssueDto>) => {
    const target = event.event?.target;
    if (target && 'nodeName' in target && `${target.nodeName}`.toLocaleLowerCase() === 'button') {
      return undefined;
    }
    const ctrlKey = Boolean(event.event && 'ctrlKey' in event.event && event.event.ctrlKey);
    return onClickIssueItem(event.data?.id, ctrlKey);
  }, [onClickIssueItem]);

  const defaultColDef = useMemo<ColDef<IssueDto>>(() => ({
    resizable: true,
    sortable: false,
    headerClass: 'no-hover',
  }), []);

  const { persistAsync } = useIssuesDynamicLayout();
  const onColumnEvent = useCallback((gridApi: GridApi) => {
    const columns = gridApi.getAllDisplayedColumns();
    const updatedColumns = columns.filter((c) => !!c.getColDef().field).map((c) => ({ fieldName: c.getColDef().field!, width: c.getActualWidth() }));
    persistAsync(updatedColumns);
  }, [persistAsync]);

  const onColumnResized = useCallback(({ source, finished, api }: ColumnResizedEvent<IssueDto>) => {
    if (source.startsWith('ui') && finished) onColumnEvent(api);
  }, [onColumnEvent]);

  const onColumnMoved = useCallback(({ source, finished, api }: ColumnMovedEvent<IssueDto>) => {
    if (source.startsWith('ui') && finished) onColumnEvent(api);
  }, [onColumnEvent]);

  const dynamicLayoutColDefs = useIssuesDataGridColumnDefinitions();
  const columnDefs = useMemo<(ColDef<IssueDto> | ColGroupDef<IssueDto>)[]>(() => [
    ...(multiselectActive ? [
      {
        colId: 'selected',
        resizable: false,
        sortable: false,
        width: 68,
        minWidth: 68,
        headerClass: 'no-hover no-padding',
        cellStyle: { paddingLeft: 0, paddingRight: 0 },
        cellRenderer: CheckboxCell,
        headerComponent: CheckboxHeaderCell,
      },
    ] : []),
    ...dynamicLayoutColDefs,
  ], [dynamicLayoutColDefs, multiselectActive]);

  const { odataQuery } = useIssuesFilterContext();
  const {
    datasource,
    dataUpdatedAt,
  } = useOdataRowRangeAgGridDatasource<ApiEndpoint.Issue, IssueDto>(ApiEndpoint.Issue, odataQuery);

  useEffect(() => {
    // cache invalidation (from sync or mutations)
    if (gridRef.current?.api) {
      gridRef.current.api.refreshServerSide();
    }
  }, [dataUpdatedAt]);

  const { i18n } = useTranslation();
  const localeText = useMemo<{ [key: string]: string; }>(() => (i18n.language.toLocaleLowerCase().startsWith('de') ? AG_GRID_LOCALE_DE : AG_GRID_LOCALE_EN), [i18n.language]);

  const rowStyle = useMemo(() => ({ cursor: 'pointer' }), []);
  const rowClassRules = useMemo<RowClassRules<IssueDto>>(() => ({
    'viso-selected': ({ data }) => !!data?.id && selectedIssueIdsSet.has(data.id),
    ...(disabledIssueIds ? { 'viso-disabled': ({ data }) => ((data?.id ? disabledIssueIds.has(data.id) : undefined) ?? false) } : {}),
  }), [disabledIssueIds, selectedIssueIdsSet]);

  return (
    <Box id="IssuesDataGrid" sx={{ height: '100%' }} className="ag-theme-material ag-theme-visoplan table-view-data-grid">
      <AgGridReact<IssueDto>
        localeText={localeText}
        rowHeight={54}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        ref={gridRef}
        maxConcurrentDatasourceRequests={1}
        rowSelection="multiple"
        rowModelType="serverSide"
        serverSideDatasource={datasource}
        suppressCellFocus
        suppressRowHoverHighlight
        suppressRowClickSelection
        enableCellTextSelection
        onRowClicked={onRowClicked}
        onColumnResized={onColumnResized}
        onColumnMoved={onColumnMoved}
        rowStyle={rowStyle}
        rowClassRules={rowClassRules}
        modules={modules}
      />
    </Box>
  );
}
