import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Box, Checkbox, IconButton, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography,
} from '@mui/material';
import ShareRecipients from 'share/types/ShareRecipients';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';
import { useTranslation } from 'react-i18next';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SelectExternalEmailsTextarea from 'share/components/SelectExternalEmailsTextarea';

interface SelectShareRecipientsControlProps {
  value: ShareRecipients | undefined,
  onChange: (shareRecipients: ShareRecipients) => void,
  sx?: SxProps<Theme>,
}

export default function SelectShareRecipientsControl({
  value,
  onChange,
  sx,
}: SelectShareRecipientsControlProps) {
  const { t } = useTranslation('share');
  const { data: groups, isLoading: isLoadingGroups } = useUserGroupsQuery();
  const { data: collaborators, isLoading: isLoadingCollaborators } = useCollaboratorsQuery();
  const disabled = useMemo(() => isLoadingGroups || isLoadingCollaborators, [isLoadingCollaborators, isLoadingGroups]);
  const [isGroupsExpanded, setIsGroupsExpanded] = useState(true);
  const toggleGroupsExpanded = useCallback(() => setIsGroupsExpanded((prev) => !prev), []);
  const [isCollaboratorsExpanded, setIsCollaboratorsExpanded] = useState(true);
  const toggleCollaboratorsExpanded = useCallback(() => setIsCollaboratorsExpanded((prev) => !prev), []);

  const selectedGroupIdsSet = useMemo(() => (value ? new Set<string>(value.userGroupIds) : undefined), [value]);
  const selectedCollaboratorIdsSet = useMemo(() => (value ? new Set<string>(value.projectCollaboratorIds) : undefined), [value]);

  const onChangeGroupSelected = useCallback((id: string) => {
    if (!value || !selectedGroupIdsSet) return;
    const nextSelectedGroupIds = selectedGroupIdsSet.has(id) ? value.userGroupIds.filter((prevId) => prevId !== id) : [...value.userGroupIds, id];
    onChange({ ...value, userGroupIds: nextSelectedGroupIds });
  }, [onChange, selectedGroupIdsSet, value]);

  const onChangeCollaboratorSelected = useCallback((id: string) => {
    if (!value || !selectedCollaboratorIdsSet) return;
    const nextSelectedCollaboratorIds = selectedCollaboratorIdsSet.has(id) ? value.projectCollaboratorIds.filter((prevId) => prevId !== id) : [...value.projectCollaboratorIds, id];
    onChange({ ...value, projectCollaboratorIds: nextSelectedCollaboratorIds });
  }, [onChange, selectedCollaboratorIdsSet, value]);

  const collaboratorsSelectedByGroupSet = useMemo(() => {
    if (!groups || !selectedGroupIdsSet) return undefined;
    return new Set<string>(groups.filter((g) => selectedGroupIdsSet.has(g.id)).flatMap((g) => g.collaboratorIds));
  }, [groups, selectedGroupIdsSet]);

  const isGroupSelectAllCheckboxChecked = useMemo(() => {
    if (!groups || !selectedGroupIdsSet?.size) return false;
    return selectedGroupIdsSet.size === groups.length;
  }, [groups, selectedGroupIdsSet?.size]);

  const isGroupSelectAllCheckboxIndeterminate = useMemo(() => {
    if (!groups || !selectedGroupIdsSet?.size) return false;
    if (!selectedGroupIdsSet.size) return false;
    return selectedGroupIdsSet.size < groups.length;
  }, [groups, selectedGroupIdsSet?.size]);

  const onChangeGroupSelectAllCheckbox = useCallback(() => {
    if (!groups || !value) return;
    const nextSelectedGroupIds = isGroupSelectAllCheckboxChecked ? [] : groups.map((g) => g.id);
    onChange({ ...value, userGroupIds: nextSelectedGroupIds });
  }, [groups, isGroupSelectAllCheckboxChecked, onChange, value]);

  const isCollaboratorSelectAllCheckboxChecked = useMemo(() => {
    if (!collaborators || !collaboratorsSelectedByGroupSet || !selectedCollaboratorIdsSet) return false;
    if (selectedCollaboratorIdsSet.size > 0 && selectedCollaboratorIdsSet.size === collaborators.length) return true;
    if (collaboratorsSelectedByGroupSet.size > 0 && collaboratorsSelectedByGroupSet.size === collaborators.length) return true;
    return false;
  }, [collaborators, collaboratorsSelectedByGroupSet, selectedCollaboratorIdsSet]);

  const isCollaboratorSelectAllCheckboxIndeterminate = useMemo(() => {
    if (!collaborators || !collaboratorsSelectedByGroupSet || !selectedCollaboratorIdsSet) return false;
    if (!selectedCollaboratorIdsSet.size) return false;
    if (collaboratorsSelectedByGroupSet.size === collaborators.length && selectedCollaboratorIdsSet.size !== collaborators.length) return false;
    return selectedCollaboratorIdsSet.size < collaborators.length;
  }, [collaborators, collaboratorsSelectedByGroupSet, selectedCollaboratorIdsSet]);

  const onChangeCollaboratorSelectAllCheckbox = useCallback(() => {
    if (!collaborators || !selectedCollaboratorIdsSet || !value) return;
    const nextSelectedCollaboratorIds = (selectedCollaboratorIdsSet.size > 0 && selectedCollaboratorIdsSet.size === collaborators.length) ? [] : collaborators.map((g) => g.id);
    onChange({ ...value, projectCollaboratorIds: nextSelectedCollaboratorIds });
  }, [collaborators, onChange, selectedCollaboratorIdsSet, value]);

  const isCollaboratorsSelectAllCheckboxDisabled = useMemo(() => {
    if (!collaborators || !collaboratorsSelectedByGroupSet || !selectedCollaboratorIdsSet) return false;
    return collaboratorsSelectedByGroupSet.size === collaborators.length && selectedCollaboratorIdsSet.size !== collaborators.length;
  }, [collaborators, collaboratorsSelectedByGroupSet, selectedCollaboratorIdsSet]);

  const onChangeExternalEmails = useCallback((externalEmailAddresses: string[]) => {
    if (!value) return;
    onChange({ ...value, externalEmailAddresses });
  }, [onChange, value]);

  const effectiveRecipientIds = useMemo(() => {
    if (!selectedCollaboratorIdsSet || !collaboratorsSelectedByGroupSet) return undefined;
    return new Set([...Array.from(selectedCollaboratorIdsSet.values()), ...Array.from(collaboratorsSelectedByGroupSet.values())]);
  }, [collaboratorsSelectedByGroupSet, selectedCollaboratorIdsSet]);

  return (
    <Box
      id="SelectShareRecipientsControl"
      sx={{
        ...sx, display: 'flex', flexDirection: 'column', height: '300px',
      }}
    >
      <TableContainer sx={sx} className="custom-scrollbar">
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ pr: 1, fontWeight: 700, fontSize: '0.875rem' }}>
                    {t('select-collaborators_label', 'Select Groups and/or Collaborators')}
                  </Typography>
                  <Typography sx={{ flexGrow: '1', fontSize: '0.875rem' }}>
                    {!!effectiveRecipientIds && t('select-collaborators_count', '{{ count }} collaborators will receive an e-mail', { count: effectiveRecipientIds.size })}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow sx={{ height: '24px' }}>
              <TableCell sx={{ width: '30px' }}>
                <IconButton onClick={toggleGroupsExpanded} sx={{ p: 0.5 }}>
                  {isGroupsExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </IconButton>
              </TableCell>
              <TableCell sx={{ width: '24px' }}>
                <Checkbox
                  checked={isGroupSelectAllCheckboxChecked}
                  onChange={onChangeGroupSelectAllCheckbox}
                  indeterminate={isGroupSelectAllCheckboxIndeterminate}
                  disabled={disabled}
                  inputProps={{ 'aria-label': 'All groups' }}
                  sx={{ p: 0, m: 0 }}
                />
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ pr: 1, fontWeight: 700, fontSize: '0.875rem' }}>
                    {t('select-share-recipients-control_groups-table-subheader', 'Groups')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isGroupsExpanded && !!groups && groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell sx={{ width: '30px' }} />
                <TableCell sx={{ width: '24px' }}>
                  <Checkbox
                    checked={selectedGroupIdsSet?.has(group.id)}
                    onChange={() => onChangeGroupSelected(group.id)}
                    disabled={disabled}
                    inputProps={{ 'aria-label': group.name }}
                    sx={{ p: 0, m: 0 }}
                  />
                </TableCell>
                <TableCell sx={{ fontWeight: 700 }}>
                  {group.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableHead>
            <TableRow sx={{ height: '24px' }}>
              <TableCell sx={{ width: '30px' }}>
                <IconButton onClick={toggleCollaboratorsExpanded} sx={{ p: 0.5 }}>
                  {isCollaboratorsExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                </IconButton>
              </TableCell>
              <TableCell sx={{ width: '24px' }}>
                <Checkbox
                  checked={isCollaboratorSelectAllCheckboxChecked}
                  onChange={onChangeCollaboratorSelectAllCheckbox}
                  indeterminate={isCollaboratorSelectAllCheckboxIndeterminate}
                  disabled={disabled}
                  color={isCollaboratorsSelectAllCheckboxDisabled ? 'default' : undefined}
                  inputProps={{ 'aria-label': 'All Collaborators' }}
                  sx={{ p: 0, m: 0 }}
                />
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ pr: 1, fontWeight: 700, fontSize: '0.875rem' }}>
                    {t('select-share-recipients-control_collaborators-table-subheader', 'Collaborators')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isCollaboratorsExpanded && !!collaborators && collaborators.map((collaborator) => (
              <TableRow key={collaborator.id}>
                <TableCell sx={{ width: '30px' }} />
                <TableCell sx={{ width: '24px' }}>
                  <Checkbox
                    checked={selectedCollaboratorIdsSet?.has(collaborator.id) || collaboratorsSelectedByGroupSet?.has(collaborator.id)}
                    onChange={() => onChangeCollaboratorSelected(collaborator.id)}
                    disabled={disabled}
                    color={collaboratorsSelectedByGroupSet?.has(collaborator.id) && !selectedCollaboratorIdsSet?.has(collaborator.id) ? 'default' : undefined}
                    inputProps={{ 'aria-label': `${collaborator.firstName} ${collaborator.lastName}` }}
                    sx={{ p: 0, m: 0 }}
                  />
                </TableCell>
                <TableCell colSpan={4} sx={{ fontWeight: 700 }}>
                  {`${collaborator.firstName} ${collaborator.lastName}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SelectExternalEmailsTextarea onChange={onChangeExternalEmails} sx={{ flexGrow: 0, mt: 1 }} />
    </Box>
  );
}
