import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';
import { debounce, isEqual } from 'lodash';
import { QueryOptions } from 'odata-query';
import { useTheme } from '@mui/material';
import Color from 'color';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useApiConfig from 'api/hooks/useApiConfig';
import DocumentViewerContext, { DocumentViewerContextState } from 'documents-details/contexts/DocumentViewerContext';
import AnnotationItem from 'documents-annotations/types/AnnotationItem';
import { CompareMode } from 'documents-details/components/DocumentViewer';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import usePdfAnnotationsQueryData from 'documents-annotations/hooks/usePdfAnnotationsQueryData';
import IssueDto from 'issues/types/IssueDto';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import LabelDto from 'labels/types/LabelDto';
import useIssuesFilterContext from 'issues/hooks/useIssuesFilterContext';
import useIssuesOdataQueryData from 'issues/hooks/useIssuesOdataQueryData';
import useDocumentVersionQueryData from 'documents/hooks/useDocumentVersionQueryData';
import useAsyncState from 'common/hooks/useAsyncState';
import usePdfAnnotationsQuery from 'documents-annotations/hooks/usePdfAnnotationsQuery';
import useAnnotationFillColor from 'documents-details/hooks/useAnnotationFillColor';
import useIssuesOdataQuery from 'issues/hooks/useIssuesOdataQuery';
import FileType from 'documents/types/FileType';

export const WEBVIEWER_LICENSE_KEY = 'Visoplan GmbH (visoplan.de):OEM:Visoplan::B+:AMS(20240229):89A5D22D04B7280A0360B13AC9A2537860612FCDE9267A15AB12ACF2EF3C208E54AA31F5C7';

const rectRegex = /([^,]+),([^,]+),([^,]+),([^,]+)/;
const ANNOTATION_SIZE = 48;
const serializer = new XMLSerializer();
const parser = new DOMParser();

interface DocumentViewerContextProviderProps {
  children: React.ReactNode;
  initiallySelectedAnnotationName?: string;
}

export default function DocumentViewerContextProvider({
  children,
  initiallySelectedAnnotationName,
}: DocumentViewerContextProviderProps) {
  const theme = useTheme();
  const { data: statuses } = useProjectIssueStatusesQuery();
  const { authProjectToken } = useVisoplanApiContext();
  const { apiUrl } = useApiConfig();
  const { getAnnotationFillColor, setAnntotationFillColor, getAnnotationColor, setAnntotationColor } = useAnnotationFillColor();

  const [webViewerInstance, setWebViewerInstance] = useState<WebViewerInstance | undefined>(undefined);
  const [annotationItems, setAnnotationItems] = useState<AnnotationItem[] | undefined>(undefined);
  const [selectedAnnotationNames, setSelectedAnnotationNames] = useState<string[] | undefined>(undefined);
  const [issueIdCurrentlyLinking, setIssueIdCurrentlyLinking] = useState<string | undefined>(undefined);
  const [compareDocumentVersionId, setCompareDocumentVersionId] = useAsyncState<string | undefined>(undefined);
  const setSelectedAnnotationNamesAndZoom = useMemo(() => (annotationNames: string[] | undefined) => {
    if (!webViewerInstance) return;
    setSelectedAnnotationNames((prev) => {
      if (isEqual(prev, annotationNames)) return prev;
      return annotationNames;
    });
    if (annotationNames?.length === 1) {
      const viewerAnnotation = webViewerInstance.Core.annotationManager.getAnnotationById(annotationNames[0]);
      if (viewerAnnotation) {
        webViewerInstance.Core.annotationManager.jumpToAnnotation(viewerAnnotation, { zoom: '2' });
      }
    }
  }, [webViewerInstance]);
  const getDocumentVersion = useDocumentVersionQueryData();
  const getPdfAnnotations = usePdfAnnotationsQueryData();
  const getIssues = useIssuesOdataQueryData();

  const [loadedViewerDocumentId, setLoadedViewerDocumentId] = useAsyncState<string | undefined>(undefined);
  useEffect(() => {
    if (!webViewerInstance) return;
    webViewerInstance.Core.documentViewer.addEventListener('documentLoaded', async () => {
      await setLoadedViewerDocumentId(webViewerInstance.Core.documentViewer.getDocument()?.getDocumentId());
    });
    webViewerInstance.Core.documentViewer.addEventListener('documentUnloaded', async () => {
      await setLoadedViewerDocumentId(undefined);
    });
  }, [setLoadedViewerDocumentId, webViewerInstance]);

  const initializeStartedRef = useRef<boolean>(false);
  const initializedRef = useRef<boolean>(false);
  const initializeViewer = useCallback((container: HTMLDivElement, customHeaderItemsContainer: HTMLElement) => {
    if (initializeStartedRef.current || initializedRef.current) return;
    initializeStartedRef.current = true;
    WebViewer(
      {
        fullAPI: true, // required for the compare feature
        path: '/webviewer/lib',
        licenseKey: WEBVIEWER_LICENSE_KEY,
        disabledElements: [
          'annotationPopup',
          'contextMenuPopup',
          'textPopup',
          'toolbarGroup-Shapes',
          'toolbarGroup-Annotate',
          'toolbarGroup-Insert',
          'toolbarGroup-Edit',
          'toolbarGroup-FillAndSign',
          'toolbarGroup-Forms',
          'toggleNotesButton',
          'downloadButton',
          'saveAsButton',
          'printButton',
          'thumbnailControl',
        ],
        css: '/webviewer.css',
        enableMeasurement: true,
      },
      container,
    ).then((instance) => {
      const { documentViewer } = instance.Core;
      instance.UI.disableReplyForAnnotations(() => true);
      instance.UI.setToolbarGroup('toolbarGroup-View');

      instance.UI.setHeaderItems((header) => {
        const headerItems = header.getHeader('HeaderItems');
        headerItems.push({
          type: 'divider',
        });
        headerItems.push({
          type: 'customElement',
          render: () => customHeaderItemsContainer,
        });

        const annotateHeader = header.getHeader('toolbarGroup-Annotate');
        annotateHeader.delete('shapeToolGroupButton'); // rectangle tool is added again at the end
        annotateHeader.delete('freeTextToolGroupButton');
        annotateHeader.delete('stickyToolGroupButton');
        annotateHeader.delete('markInsertTextGroupButton');
        annotateHeader.delete('markReplaceTextGroupButton');

        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'arrowTools',
          dataElement: 'arrowToolGroupButton',
          title: 'annotation.arrow',
        });
        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'rectangleTools',
          dataElement: 'shapeToolGroupButton',
          title: 'annotation.rectangle',
        });
        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'ellipseTools',
          dataElement: 'ellipseToolGroupButton',
          title: 'annotation.ellipse',
        });
        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'cloudTools',
          dataElement: 'polygonCloudToolGroupButton',
          title: 'annotation.polygonCloud',
        });
        annotateHeader.push({
          type: 'toolGroupButton',
          toolGroup: 'polygonTools',
          dataElement: 'polygonToolGroupButton',
          title: 'annotation.polygon',
        });
      });
      instance.UI.enableElements(['ribbons']);

      const toolMap = documentViewer.getToolModeMap();
      // @ts-ignore
      // eslint-disable-next-line no-restricted-syntax
      for (const tool of Object.values(toolMap)) {
        if (tool instanceof instance.Core.Tools.FreeHandCreateTool) {
          // @ts-ignore
          tool.setCreateDelay(0);
        }
      }

      setWebViewerInstance(instance);
    });
    initializedRef.current = true;
  }, []);

  const disposeViewer = useCallback(() => {
    if (!initializedRef.current || !initializeStartedRef.current) return;
    initializedRef.current = false;
    initializeStartedRef.current = false;
    setWebViewerInstance(undefined);
  }, []);

  const updateAnnotationSelectionPresentation = useCallback(() => {
    if (!webViewerInstance) return;
    const makeAnnotationSlightlyTransparent = (annotation: Core.Annotations.Annotation) => {
      const currentFillColor = getAnnotationFillColor(annotation);
      if (currentFillColor) {
        setAnntotationFillColor(annotation, new webViewerInstance.Core.Annotations.Color(currentFillColor.R, currentFillColor.G, currentFillColor.B, 0.35));
      }
      const currentColor = getAnnotationColor(annotation);
      if (currentColor) {
        setAnntotationColor(annotation, new webViewerInstance.Core.Annotations.Color(currentColor.R, currentColor.G, currentColor.B, 0.35));
      }
      webViewerInstance?.Core.annotationManager.updateAnnotation(annotation);
    };
    const resetAnnotationColor = (annotation: Core.Annotations.Annotation) => {
      // eslint-disable-next-line no-param-reassign
      const originalFillColorJson = annotation.getCustomData('originalFillColor');
      if (originalFillColorJson) {
        const parsedFillColor = JSON.parse(originalFillColorJson) as { R: number, G: number, B: number, A: number };
        if (parsedFillColor) {
          const { R, G, B, A } = parsedFillColor;
          const originalFillColor = new webViewerInstance.Core.Annotations.Color(R, G, B, A);
          setAnntotationFillColor(annotation, originalFillColor);
        }
      }

      const originalColorJson = annotation.getCustomData('originalColor');
      if (originalColorJson) {
        const parsedColor = JSON.parse(originalColorJson) as { R: number, G: number, B: number, A: number };
        if (parsedColor) {
          const { R, G, B, A } = parsedColor;
          const originalColor = new webViewerInstance.Core.Annotations.Color(R, G, B, A);
          // eslint-disable-next-line no-param-reassign
          annotation.Color = originalColor;
        }
      }
    };

    const selectedNames = webViewerInstance.Core.annotationManager.getSelectedAnnotations().map((a) => a.Id);
    if (selectedNames.length > 0 && !issueIdCurrentlyLinking) {
      const selectedNamesSet = new Set(selectedNames);
      const annotationsToHide = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => !selectedNamesSet.has(a.Id) && a.getCustomData('annotationKind') !== 'temp');
      annotationsToHide.forEach(makeAnnotationSlightlyTransparent);
      const annotationsToShow = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => selectedNamesSet.has(a.Id));
      annotationsToShow.forEach(resetAnnotationColor);
    } else {
      webViewerInstance.Core.annotationManager.getAnnotationsList().forEach(resetAnnotationColor);
    }
    webViewerInstance.Core.annotationManager.drawAnnotationsFromList(webViewerInstance.Core.annotationManager.getAnnotationsList());
  }, [getAnnotationColor, getAnnotationFillColor, issueIdCurrentlyLinking, setAnntotationColor, setAnntotationFillColor, webViewerInstance]);

  const updateViewerSelection = useCallback(() => {
    if (!webViewerInstance) return;
    const selectedNames = webViewerInstance.Core.annotationManager.getSelectedAnnotations().map((a) => a.Id);
    setSelectedAnnotationNames((prev) => {
      if (isEqual(prev, selectedNames)) return prev;
      return selectedNames;
    });
    updateAnnotationSelectionPresentation();
  }, [updateAnnotationSelectionPresentation, webViewerInstance]);

  const loadViewerFile = useCallback(async (fileUri: string, fileName: string, documentVersionId: string) => new Promise<void>((resolve, reject) => {
    if (!webViewerInstance || !authProjectToken) throw new Error('dependency error');
    if (loadedViewerDocumentId === documentVersionId) {
      resolve();
      return;
    }

    webViewerInstance.Core.documentViewer.addEventListener('documentLoaded', () => {
      webViewerInstance.UI.setLayoutMode(webViewerInstance.UI.LayoutMode.Single);
      setCompareDocumentVersionId(undefined);
    }, { once: true });

    const updateViewerSelectionDebounced = debounce(updateViewerSelection, 350);
    webViewerInstance.Core.annotationManager.addEventListener('annotationSelected', (annotations: Core.Annotations.Annotation[], action: string) => {
      if (action === 'deselected') {
        // When an annotation is selected and we select another annotation, a 'deselected' and a 'selected' event are fired.
        // Without debouncing, this would noticably and annoyingly close the previous issue and then open the next issue.
        // With debouncing we transition directly from the previously annotation's issue to the next annotation's issue,
        // because leaving the issue due to the deselection is delayed until after the 'selected' event and the actual selection state is settled.
        updateViewerSelectionDebounced();
      } else {
        updateViewerSelection();
      }
    });

    webViewerInstance.Core.documentViewer.addEventListener('annotationsLoaded', () => {
      const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList();
      annotations.forEach((a) => {
        if (a.getCustomData('annotationKind')) {
          return;
        }
        const originalFillColor = getAnnotationFillColor(a);
        if (originalFillColor) {
          a.setCustomData('originalFillColor', JSON.stringify(originalFillColor));
        }
        const originalColor = getAnnotationColor(a);
        if (originalColor) {
          a.setCustomData('originalColor', JSON.stringify(originalColor));
        }
        if (a.DateModified) {
          a.setCustomData('originalDateModified', a.DateModified.toISOString());
        }
        /* eslint-disable no-param-reassign */
        a.LockedContents = true;
        a.Locked = true;
        a.ReadOnly = true;
        a.setCustomData('annotationKind', 'internal');
        /* eslint-enable no-param-reassign */
      });
      resolve();
    }, { once: true });

    webViewerInstance.UI.loadDocument(fileUri, {
      customHeaders: {
        Authorization: `Bearer ${authProjectToken.bearer}`,
      },
      filename: fileName,
      docId: documentVersionId,
      onError: () => reject(new Error(`Error loading document version ${documentVersionId}`)),
    });
  }), [authProjectToken, getAnnotationColor, getAnnotationFillColor, loadedViewerDocumentId, setCompareDocumentVersionId, updateViewerSelection, webViewerInstance]);

  const loadViewerComparisonDocument = useCallback((pdfDoc: Core.PDFNet.PDFDoc, pdfDocId: string, documentVersionId: string, compareVersionId: string, mode: CompareMode) => new Promise<void>((resolve, reject) => {
    if (!webViewerInstance || !authProjectToken) throw new Error('dependency error');

    const docId = `compare_${documentVersionId}_${documentVersionId}_${mode}`;
    if (loadedViewerDocumentId === docId) {
      resolve();
      return;
    }

    webViewerInstance.Core.documentViewer.addEventListener('documentLoaded', () => {
      webViewerInstance.UI.setLayoutMode(webViewerInstance.UI.LayoutMode.FacingContinuous);
      setCompareDocumentVersionId(compareVersionId);
    }, { once: true });
    webViewerInstance.Core.documentViewer.addEventListener('annotationsLoaded', () => {
      const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList();
      annotations.forEach((a) => {
        if (a.DateModified) {
          a.setCustomData('originalDateModified', a.DateModified.toISOString());
        }
        const originalFillColor = getAnnotationFillColor(a);
        if (originalFillColor) {
          a.setCustomData('originalFillColor', JSON.stringify(originalFillColor));
        }
        const originalColor = getAnnotationColor(a);
        if (originalColor) {
          a.setCustomData('originalColor', JSON.stringify(originalColor));
        }
        /* eslint-disable no-param-reassign */
        a.LockedContents = true;
        a.Locked = true;
        a.ReadOnly = true;
        a.setCustomData('annotationKind', 'internal');
        /* eslint-enable no-param-reassign */
      });
      resolve();
    }, { once: true });

    webViewerInstance.UI.loadDocument(pdfDoc, {
      filename: `${pdfDocId}.pdf`,
      docId: pdfDocId,
      onError: () => reject(new Error(`Error comparing document versions ${documentVersionId} and ${compareVersionId}`)),
    });
  }), [authProjectToken, getAnnotationColor, getAnnotationFillColor, loadedViewerDocumentId, setCompareDocumentVersionId, webViewerInstance]);

  const statusBackgroundColor = useCallback((issue: IssueDto) => {
    if (!issue.issueStatus || !statuses) return theme.palette.secondary.light;
    const issueStatusLabelDto = statuses.find((status: LabelDto) => (status.id === issue.issueStatus));
    return Color(issueStatusLabelDto?.color).lightness(60).hex();
  }, [statuses, theme.palette.secondary.light]);

  const { odataQuery: issuesOdataQuery } = useIssuesFilterContext();

  const getPdfAnnotationsOdataQuery = useCallback((documentVersionId: string) => {
    const query: Partial<QueryOptions<PdfAnnotationDto>> = {
      filter: [
        { documentVersionId: { eq: documentVersionId } },
        ...(issueIdCurrentlyLinking ? [{ linkedIssueId: issueIdCurrentlyLinking }] : []),
      ],
    };
    return query;
  }, [issueIdCurrentlyLinking]);

  const loadedDocumentVersionId = useMemo(() => {
    if (!loadedViewerDocumentId || loadedViewerDocumentId.startsWith('compare_')) return undefined;
    return loadedViewerDocumentId;
  }, [loadedViewerDocumentId]);
  const loadedDocumentPdfAnnotationOdataQuery = useMemo(() => (loadedDocumentVersionId ? getPdfAnnotationsOdataQuery(loadedDocumentVersionId) : undefined), [getPdfAnnotationsOdataQuery, loadedDocumentVersionId]);
  const { dataUpdatedAt: pdfAnnotationsUpdatedAt } = usePdfAnnotationsQuery(loadedDocumentPdfAnnotationOdataQuery);
  const { dataUpdatedAt: issuesUpdatedAt } = useIssuesOdataQuery(issuesOdataQuery);

  const importVisoplanAnnotations = useCallback(async (documentVersionId: string) => {
    if (!issuesOdataQuery) throw new Error('dependency error');
    const pdfAnnotationsOdataQuery = getPdfAnnotationsOdataQuery(documentVersionId);
    const pdfAnnotations = await getPdfAnnotations(pdfAnnotationsOdataQuery);
    const issues = await getIssues(issuesOdataQuery);
    const issuesById = new Map(issues.map((issue) => [issue.id, issue]));
    if (!webViewerInstance || !pdfAnnotations || !issuesById) return;
    const resultXfdfDocument = parser.parseFromString('<?xml version="1.0" encoding="UTF-8"?><xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve"><annots></annots></xfdf>', 'application/xml');
    const resultRoot = resultXfdfDocument.documentElement.children[0];
    const visoplanAnnotationsByName = new Map<string, PdfAnnotationDto>();

    pdfAnnotations
      .forEach((pdfAnnotation) => {
        const issue = pdfAnnotation.linkedIssueId ? issuesById.get(pdfAnnotation.linkedIssueId) : undefined;
        if (!issue) return;
        const xfdfDocument = parser.parseFromString(pdfAnnotation.xfdfString, 'application/xml');
        const root = xfdfDocument.documentElement;
        const annots = root.children[0];
        if (!annots || annots.nodeName !== 'annots' || !annots.hasChildNodes()) {
          return;
        }
        const namedChildElements = Array.from(annots.children).filter((child) => child.hasAttribute('name'));
        const processedElements = namedChildElements.map((element) => {
          if (element.getAttribute('title') === 'Visoplan Location' && !!issue) {
            const name = element.getAttribute('name') as string;
            const page = element.getAttribute('page') as string;
            const rect = element.getAttribute('rect') as string;
            const color = statusBackgroundColor(issue);

            const rectMatch = rect.match(rectRegex)!;
            const [, ...coords] = rectMatch;
            const [x1, , , y2] = coords.map(parseFloat);

            const vertices = `${x1},${y2};${x1 + (ANNOTATION_SIZE / 4)},${y2 - ANNOTATION_SIZE};${x1 + (ANNOTATION_SIZE / 2)},${y2 - (ANNOTATION_SIZE / 2)};${x1 + ANNOTATION_SIZE},${y2 - (ANNOTATION_SIZE / 4)};${x1},${y2}`;
            const polygonString = `<polygon
            interior-color="${color}"
            width="0"
            opacity="0.8"
            creationdate="D:20231031091835+01'00'"
            flags="print,nozoom"
            date="D:20231031091835+01'00'"
            name="${name}"
            page="${page}"
            rect="${rect}"
            subject="Polygon"
            title="Visoplan Location">
            <vertices>${vertices}</vertices>
            </polygon>`;
            const xmlDoc = parser.parseFromString(polygonString, 'text/xml') as XMLDocument;
            return xmlDoc.firstElementChild;
          }
          return element;
        }).filter(Boolean).map((e) => e!);
        resultRoot.append(...processedElements);
        const annotationNames = namedChildElements.map((element) => element.getAttribute('name')!);
        annotationNames.forEach((name) => visoplanAnnotationsByName.set(name, pdfAnnotation));
      });

    const resultXfdfString = serializer.serializeToString(resultXfdfDocument);

    const previousVisoplanAnnotations = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => a.getCustomData('annotationKind') === 'visoplan');
    webViewerInstance.Core.annotationManager.deleteAnnotations(previousVisoplanAnnotations, { force: true });

    const importedAnnotations = await webViewerInstance.Core.annotationManager.importAnnotations(resultXfdfString) as Core.Annotations.Annotation[];
    importedAnnotations.forEach((a) => {
      /* eslint-disable no-param-reassign */
      a.LockedContents = true;
      a.Locked = true;
      a.ReadOnly = true;
      a.Author = `${a.Author}`;
      /* eslint-enable no-param-reassign */
      const originalFillColor = getAnnotationFillColor(a);
      if (originalFillColor) {
        a.setCustomData('originalFillColor', JSON.stringify(originalFillColor));
      }
      const originalColor = getAnnotationColor(a);
      if (originalColor) {
        a.setCustomData('originalColor', JSON.stringify(originalColor));
      }
      if (a.Subject === 'Visoplan Location') {
        a.setCustomData('isLocation', 'true');
      }
      a.setCustomData('annotationKind', 'visoplan');
    });

    const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList();

    setAnnotationItems(annotations.map((annotation) => ({
      viewerAnnotation: annotation,
      visoplanAnnotation: visoplanAnnotationsByName.get(annotation.Id),
    })));
  }, [getAnnotationColor, getAnnotationFillColor, getIssues, getPdfAnnotations, getPdfAnnotationsOdataQuery, issuesOdataQuery, statusBackgroundColor, webViewerInstance]);

  const loadDocumentVersion = useCallback(async (documentVersionId: string) => {
    const documentVersion = await getDocumentVersion(documentVersionId);
    const resourceId = (documentVersion.fileType === FileType.Pdf || (documentVersion.fileType === FileType.Image && !documentVersion.originalFileName.endsWith('.svg')))
      ? documentVersion.fileId
      : (documentVersion.fileType === FileType.DWG)
        ? documentVersion.previewFileId
        : undefined;
    if (!resourceId) return;
    const fileName = documentVersion.fileType === FileType.DWG ? `${documentVersion.originalFileName}.pdf` : documentVersion.originalFileName;
    const fileUri = `${apiUrl}/${ApiEndpoint.Resource}/${resourceId}`;
    await loadViewerFile(fileUri, fileName, documentVersionId);
    await importVisoplanAnnotations(documentVersionId);
    if (initiallySelectedAnnotationName) {
      setSelectedAnnotationNamesAndZoom([initiallySelectedAnnotationName]);
    }
  }, [apiUrl, getDocumentVersion, importVisoplanAnnotations, initiallySelectedAnnotationName, loadViewerFile, setSelectedAnnotationNamesAndZoom]);

  const compareDocumentVersions = useCallback(async (documentVersionId: string, compareVersionId: string, mode: CompareMode) => {
    if (!webViewerInstance || !authProjectToken) throw new Error('dependency error');
    await webViewerInstance.UI.closeDocument();
    const customHeaders = {
      Authorization: `Bearer ${authProjectToken.bearer}`,
    };
    const documentVersion = await getDocumentVersion(documentVersionId);
    const compareDocumentVersion = await getDocumentVersion(compareVersionId);
    const [resourceIdA, resourceIdB] = [compareDocumentVersion, documentVersion].map((v) => ((v.fileType === FileType.Pdf || (v.fileType === FileType.Image && !v.originalFileName.endsWith('.svg')))
      ? v.fileId
      : (v.fileType === FileType.DWG)
        ? v.previewFileId
        : undefined));
    const fileUriA = `${apiUrl}/${ApiEndpoint.Resource}/${resourceIdA}`;
    const fileUriB = `${apiUrl}/${ApiEndpoint.Resource}/${resourceIdB}`;
    await webViewerInstance.Core.PDFNet.initialize();
    const docA = await webViewerInstance.Core.PDFNet.PDFDoc.createFromURL(fileUriA, { withCredentials: true, customHeaders });
    const docB = await webViewerInstance.Core.PDFNet.PDFDoc.createFromURL(fileUriB, { withCredentials: true, customHeaders });
    const newDoc = await webViewerInstance.Core.PDFNet.PDFDoc.create();
    await newDoc.lock();
    if (mode === CompareMode.Text) {
      await newDoc.appendTextDiffDoc(docA, docB);
    } else {
      const getPageArray = async (doc: Core.PDFNet.PDFDoc) => {
        const arr = [];
        const itr = await doc.getPageIterator(1);
        /* eslint-disable no-await-in-loop */
        while (await itr.hasNext()) {
          const page = await itr.current();
          arr.push(page);
          itr.next();
        }
        /* eslint-enable no-await-in-loop */
        return arr;
      };
      const pagesA = await getPageArray(docA);
      const pagesB = await getPageArray(docB);
      const diffOptions = new webViewerInstance.Core.PDFNet.PDFDoc.DiffOptions();
      const colorA = Color(theme.palette.success.main);
      const colorB = Color(theme.palette.error.main);
      const rgbaA = { R: colorA.red(), G: colorA.green(), B: colorA.blue(), A: 1 };
      const rgbaB = { R: colorB.red(), G: colorB.green(), B: colorB.blue(), A: 1 };
      diffOptions.setColorA(rgbaA);
      diffOptions.setColorB(rgbaB);
      /* eslint-disable no-restricted-syntax */
      for await (const [pageA, pageB] of pagesA.map((page, index) => [page, pagesB[index]])) {
        if (pageB) {
          await newDoc.appendVisualDiff(pageA, pageB, diffOptions);
        }
      }
      /* eslint-enable no-await-in-loop */
    }
    await newDoc.unlock();
    const docId = `compare_${documentVersionId}_${compareVersionId}_${mode}`;
    await loadViewerComparisonDocument(newDoc, docId, documentVersionId, compareVersionId, mode);

    const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList();

    setAnnotationItems(annotations.map((annotation) => ({
      viewerAnnotation: annotation,
      visoplanAnnotation: undefined,
    })));
  }, [apiUrl, authProjectToken, getDocumentVersion, loadViewerComparisonDocument, theme.palette.error.main, theme.palette.success.main, webViewerInstance]);

  const visoplanAnnotationsLastImportedFor = useRef<{
    pdfAnnotationsUpdatedAt: number,
    issuesUpdatedAt: number,
    loadedDocumentVersionId: string,
  } | undefined>(undefined);

  // We have a transition from a declarative pattern (we are listening to cache invalidations of issues or annotations) to the imperative pattern (calling importVisoplanAnnotations).
  // We only want to re-import annotations when actually requred which is a) when annotations are changed (e.g. triggered by sync) or b) when linked issues are changed,
  // e.g. by a local mutation changing the status which means location annotations must change color accordinly, or c) when we switch documents.
  // However, that useEffect will trigger whenever any of the dependencies changes, which is far more often that that.
  // To avoid unnecessary and potentially costly redundant re-imports, we remember the values of the relevant dependencies every time we actually import annotations.
  // If this hook is triggered, but none of the relevant dependencies changed, don't do anything.
  useEffect(() => {
    if (!compareDocumentVersionId && loadedDocumentVersionId && pdfAnnotationsUpdatedAt && issuesUpdatedAt) {
      if (visoplanAnnotationsLastImportedFor.current
        && visoplanAnnotationsLastImportedFor.current.issuesUpdatedAt === issuesUpdatedAt
        && visoplanAnnotationsLastImportedFor.current.loadedDocumentVersionId === loadedDocumentVersionId
        && visoplanAnnotationsLastImportedFor.current.pdfAnnotationsUpdatedAt === pdfAnnotationsUpdatedAt) {
        return;
      }
      visoplanAnnotationsLastImportedFor.current = { issuesUpdatedAt, loadedDocumentVersionId, pdfAnnotationsUpdatedAt };
      importVisoplanAnnotations(loadedDocumentVersionId);
    }
  }, [compareDocumentVersionId, importVisoplanAnnotations, issuesUpdatedAt, loadedDocumentVersionId, pdfAnnotationsUpdatedAt]);

  const annotationsByName = useMemo(() => (annotationItems ? new Map(annotationItems.map((item) => [item.viewerAnnotation.Id, item.viewerAnnotation])) : undefined), [annotationItems]);
  useEffect(() => {
    if (!webViewerInstance || !annotationsByName || !selectedAnnotationNames) return;
    const currentlySelectedNames = webViewerInstance.Core.annotationManager.getSelectedAnnotations().map((a) => a.Id);
    if (isEqual(currentlySelectedNames, selectedAnnotationNames)) return;
    webViewerInstance.Core.annotationManager.deselectAllAnnotations();
    if (!selectedAnnotationNames) return;
    const annotationsToSelect = selectedAnnotationNames.filter((name) => annotationsByName.has(name)).map((name) => annotationsByName.get(name)!);
    webViewerInstance.Core.annotationManager.selectAnnotations(annotationsToSelect);
  }, [webViewerInstance, selectedAnnotationNames, annotationsByName]);

  useEffect(() => {
    if (!webViewerInstance) return;
    if (compareDocumentVersionId) {
      webViewerInstance.UI.disableElements(['ribbons']);
    } else if (issueIdCurrentlyLinking) {
      webViewerInstance.UI.disableElements(['ribbons']);
      webViewerInstance.UI.setToolbarGroup('toolbarGroup-Annotate');
      webViewerInstance.Core.documentViewer.getAnnotationHistoryManager().clear(); // reset undo buffer
    } else {
      webViewerInstance.UI.enableElements(['ribbons']);
      webViewerInstance.UI.setToolbarGroup('toolbarGroup-View');
    }
  }, [webViewerInstance, issueIdCurrentlyLinking, compareDocumentVersionId]);

  useEffect(() => {
    if (!webViewerInstance) return () => {};
    webViewerInstance.Core.annotationManager.addEventListener('annotationChanged.onWebViewerAnnotationChanged', (annotations: Core.Annotations.Annotation[], action: string) => {
      if (!issueIdCurrentlyLinking) return;
      if (action === 'add') {
        annotations.forEach((a) => {
          a.setCustomData('annotationKind', 'temp');
        });
      }
    });
    return () => {
      webViewerInstance.Core.annotationManager.removeEventListener('annotationChanged.onWebViewerAnnotationChanged');
    };
  }, [webViewerInstance, issueIdCurrentlyLinking]);

  const state = useMemo<DocumentViewerContextState>(() => ({
    webViewerInstance,
    initializeViewer,
    disposeViewer,
    annotationItems,
    setAnnotationItems,
    selectedAnnotationNames,
    setSelectedAnnotationNames: setSelectedAnnotationNamesAndZoom,
    issueIdCurrentlyLinking,
    setIssueIdCurrentlyLinking,
    loadDocumentVersion,
    compareDocumentVersions,
    compareDocumentVersionId,
  }), [annotationItems, compareDocumentVersions, compareDocumentVersionId, disposeViewer, initializeViewer, issueIdCurrentlyLinking, loadDocumentVersion, selectedAnnotationNames, setSelectedAnnotationNamesAndZoom, webViewerInstance]);
  return (
    <DocumentViewerContext.Provider value={state}>
      {children}
    </DocumentViewerContext.Provider>
  );
}
