import { SuggestionOptions } from '@tiptap/suggestion';
import useCollaboratorsQuery from 'collaborators/hooks/useCollaboratorsQuery';
import { useCallback, useMemo, useState } from 'react';
import SuggestionItem from 'rich-text/types/SuggestionItem';
import SuggestionState from 'rich-text/types/SuggestionState';
import useUserGroupsQuery from 'users-groups/hooks/useUserGroupsQuery';

export default function useMentionSuggestion() {
  const [suggestionState, setSuggestionState] = useState<SuggestionState | undefined>(undefined);
  const onCancel = useCallback(() => setSuggestionState(undefined), []);
  const { data: collaborators } = useCollaboratorsQuery();
  const { data: groups } = useUserGroupsQuery();
  const suggestionItems = useMemo<SuggestionItem[] | undefined>(() => {
    if (!collaborators || !groups) return undefined;
    return [
      ...collaborators.map((c) => ({ id: `User_${c.id}`, label: `${c.firstName} ${c.lastName}` })),
      ...groups.map((g) => ({ id: `UserGroup_${g.id}`, label: g.name })),
    ];
  }, [collaborators, groups]);

  const suggestion = useMemo<Omit<SuggestionOptions<SuggestionItem>, 'editor'> | undefined>(() => {
    if (!suggestionItems) return undefined;
    return {
      items: () => suggestionItems,
      render: () => ({
        onStart: ({ items, command, decorationNode }) => {
          setSuggestionState(decorationNode ? { decorationNode, command, items } : undefined);
        },
        onExit: () => {
          onCancel();
        },
      }),
    };
  }, [onCancel, suggestionItems]);

  return useMemo(() => ({
    suggestion,
    suggestionState,
    onCancel,
  }), [onCancel, suggestion, suggestionState]);
}
