import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useDocumentVersionQuery from 'documents/hooks/useDocumentVersionQuery';
import { useTranslation } from 'react-i18next';
import { Resizable } from 're-resizable';
import DocumentViewer from 'documents-details/components/DocumentViewer';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import VerticalDivider from 'common/styled/VerticalDivider';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import FileType from 'documents/types/FileType';
import CenteredCircularProgress from 'common/components/CenteredCircularProgress';
import DocumentVersionDetailsPanel from 'documents-details/components/DocumentVersionDetailsPanel';
import DocumentVersionComparePanel from 'documents-details/components/DocumentVersionComparePanel';
import AnnotationItem from 'documents-annotations/types/AnnotationItem';
import LegacyAnnotationPanel from 'documents-details/components/LegacyAnnotationPanel';
import { Direction } from 're-resizable/lib/resizer';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';
import DocumentVersionSelect from 'documents-details/components/DocumentVersionSelect';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import usePdfAnnotationNameParser from 'documents-annotations/hooks/usePdfAnnotationNameParser';
import usePdfAnnotationsOdataQueryData from 'documents-annotations/hooks/usePdfAnnotationsQueryData';
import { QueryOptions } from 'odata-query';

interface DocumentDetailsProps {
  onClose: () => void,
}

export default function DocumentDetails({
  onClose,
}: DocumentDetailsProps) {
  const { t } = useTranslation('documents-details');
  const { documentVersionId, setDocumentVersionId, setDocumentVersionAndIssueId, issueId, setIssueId } = useDocumentDetailsSelectionContext();
  const { compareDocumentVersionId } = useDocumentViewerContext();
  const { data: documentVersion } = useDocumentVersionQuery(documentVersionId);
  const {
    webViewerInstance,
    annotationItems,
    selectedAnnotationNames,
    setSelectedAnnotationNames,
    issueIdCurrentlyLinking,
    loadDocumentVersion,
  } = useDocumentViewerContext();
  const previewAvailable = useMemo(() => {
    if (!documentVersion) return undefined;
    return (documentVersion.fileType === FileType.Image && !documentVersion.originalFileName.endsWith('.svg'))
      || documentVersion.fileType === FileType.Pdf
      || documentVersion.fileType === FileType.DWG;
  }, [documentVersion]);

  const annotationItemsByName = useMemo(() => {
    if (!annotationItems) return undefined;
    return new Map<string, AnnotationItem>(annotationItems.map((item) => [item.viewerAnnotation.Id, item]));
  }, [annotationItems]);

  const onClickClose = useCallback(() => {
    setSelectedAnnotationNames([]);
    onClose();
  }, [onClose, setSelectedAnnotationNames]);

  const onCloseIssueDetailsPanel = useCallback(() => {
    setSelectedAnnotationNames([]);
    setIssueId(undefined);
  }, [setIssueId, setSelectedAnnotationNames]);

  const onSelectDocumentVersionItem = useCallback(async (itemDocumentVersionId: string) => {
    setSelectedAnnotationNames(undefined);
    setDocumentVersionId(itemDocumentVersionId);
    await loadDocumentVersion(itemDocumentVersionId);
  }, [loadDocumentVersion, setDocumentVersionId, setSelectedAnnotationNames]);

  const onClickIssueItem = useCallback((itemIssueId: string | undefined) => {
    setSelectedAnnotationNames([]);
    setIssueId(itemIssueId);
  }, [setIssueId, setSelectedAnnotationNames]);

  const parseAnnotationName = usePdfAnnotationNameParser();
  const onClickAnnotationItem = useCallback(async (annotation: PdfAnnotationDto) => {
    if (!annotation) return;
    const annotationName = parseAnnotationName(annotation);
    if (documentVersionId !== annotation.documentVersionId || issueId !== annotation.linkedIssueId) {
      setDocumentVersionAndIssueId({ documentVersionId: annotation.documentVersionId, issueId: annotation.linkedIssueId });
      await loadDocumentVersion(annotation.documentVersionId);
    }
    if (annotationName) {
      setSelectedAnnotationNames([annotationName]);
    }
  }, [documentVersionId, issueId, loadDocumentVersion, parseAnnotationName, setDocumentVersionAndIssueId, setSelectedAnnotationNames]);

  const initialized = useRef<boolean>(false);
  useEffect(() => {
    if (!initialized.current && documentVersionId && webViewerInstance) {
      initialized.current = true;
      loadDocumentVersion(documentVersionId);
    }
  }, [documentVersionId, loadDocumentVersion, webViewerInstance]);

  const getPdfAnnotations = usePdfAnnotationsOdataQueryData();
  const parsePdfAnnotationNames = usePdfAnnotationNameParser();
  useEffect(() => {
    if (!issueId || !documentVersionId || selectedAnnotationNames !== undefined) return;
    const query: Partial<QueryOptions<PdfAnnotationDto>> = {
      filter: [
        { documentVersionId: { eq: documentVersionId } },
        { linkedIssueId: issueId },
      ],
    };
    getPdfAnnotations(query).then((pdfAnnotations) => {
      const issueAnnotationNames = pdfAnnotations.map(parsePdfAnnotationNames).filter(Boolean).map((annotationname) => annotationname!);
      setSelectedAnnotationNames(issueAnnotationNames);
    });
  }, [annotationItems, documentVersionId, getPdfAnnotations, issueId, issueIdCurrentlyLinking, parsePdfAnnotationNames, selectedAnnotationNames, setSelectedAnnotationNames]);

  useEffect(() => {
    if (!annotationItemsByName || !selectedAnnotationNames) return;
    const selectedItemsIssueIds = selectedAnnotationNames
      .flatMap((name) => annotationItemsByName.get(name)?.visoplanAnnotation?.linkedIssueId)
      .filter((id) => !!id);
    if (new Set(selectedItemsIssueIds).size === 1) {
      setIssueId(selectedItemsIssueIds[0]);
    }
  }, [annotationItemsByName, selectedAnnotationNames, setIssueId]);

  const internalAnnotationNames = useMemo(() => (annotationItems ? new Set(annotationItems.filter((item) => !item.visoplanAnnotation).map((item) => item.viewerAnnotation.Id)) : undefined), [annotationItems]);

  useEffect(() => {
    if (internalAnnotationNames && selectedAnnotationNames?.length) {
      if (selectedAnnotationNames.every((name) => internalAnnotationNames.has(name))) {
        // when selecting internal annotations, move away from the currently open issue.
        setIssueId(undefined);
      }
    }
  }, [annotationItemsByName, internalAnnotationNames, selectedAnnotationNames, setIssueId]);

  const selectedLegacyAnnotationIds = useMemo(() => {
    // when selecting visplan annotations without a linked issue (must have been created in the old viewer),
    // display a special details page that allows the annotation to be deleted
    if (annotationItemsByName && selectedAnnotationNames?.length) {
      const selectedAnnotationItems = selectedAnnotationNames.map((name) => annotationItemsByName.get(name));
      if (selectedAnnotationItems.every((item) => item?.visoplanAnnotation?.id && !item.visoplanAnnotation.linkedIssueId)) {
        return selectedAnnotationItems.map((item) => item!.visoplanAnnotation!.id);
      }
    }
    return undefined;
  }, [annotationItemsByName, selectedAnnotationNames]);

  const [sidebarDefaultWidth, setSidebarDefaultWidth] = useState(() => {
    const storedWidth = localStorage.getItem('document-details_default-sidebar-width');
    if (storedWidth?.length) {
      const parsedWidth = parseInt(storedWidth, 10);
      if (!Number.isNaN(parsedWidth)) return parsedWidth;
    }
    return 350;
  });

  const onResizeStopSidebar = useCallback((event: MouseEvent | TouchEvent, direction: Direction, elementRef: HTMLElement) => {
    localStorage.setItem('document-details_default-sidebar-width', `${elementRef.clientWidth}`);
    setSidebarDefaultWidth(elementRef.clientWidth);
  }, []);

  const onCloseLegacyAnnotationPanel = useCallback(() => {
    setSelectedAnnotationNames([]);
  }, [setSelectedAnnotationNames]);

  const theme = useTheme();
  return (
    <Box
      id="DocumentDetails"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          flex: '0 1 0',
          display: 'flex',
          alignItems: 'center',
          p: 1,
          pl: 2,
          gap: 3,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: theme.palette.divider,
          boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.1)',
        }}
        id="DocumentViewerHeader"
      >
        <img src="/img/visoplan-logo.svg" alt="logo" />
        <VerticalDivider sx={{ alignSelf: 'stretch' }} />
        <Typography variant="h3">{documentVersion?.name}</Typography>
        <Button onClick={onClickClose} disabled={!!issueIdCurrentlyLinking} variant="outlined" sx={{ pl: 1, gap: 1, ml: 'auto' }}>
          <CloseIcon />
          {t('document-viewer_close-button-label', 'Close')}
        </Button>
      </Box>
      <Box sx={{ flex: '1 1 0', display: 'flex', overflow: 'hidden' }}>
        <Box sx={{ flex: '1 1 0', display: 'flex', flexDirection: 'column', overflow: 'auto', position: 'relative' }}>
          {!!documentVersionId && !compareDocumentVersionId && (
            <Box sx={{ display: 'flex', position: 'relative', left: '50%', transform: 'translate(-50%)', justifyContent: 'center', py: 1 }}>
              <DocumentVersionSelect onChange={onSelectDocumentVersionItem} />
            </Box>
          )}
          {previewAvailable !== false && (
            <DocumentViewer sx={{ flex: '1 1 0' }} />
          )}
          {previewAvailable === false && !!documentVersion && (
            <Box sx={{ flex: '1 1 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Alert severity="info">{t('document-details_no-preview-available-info', 'No preview available for this file type ({{fileType}})', { fileType: FileType[documentVersion.fileType] })}</Alert>
            </Box>
          )}
          {previewAvailable === undefined && (
            <CenteredCircularProgress />
          )}
        </Box>
        <Resizable
          style={{ boxShadow: '0px 0px 16px 0px rgba(0,0,0,0.1)' }}
          enable={{ top: false, right: false, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
          defaultSize={{ width: sidebarDefaultWidth, height: 'auto' }}
          minWidth="320px"
          handleComponent={{ left: <Box sx={{ height: '100%', width: '8px', backgroundColor: 'transparent' }}><Box sx={{ height: '100%' }} /></Box> }}
          handleStyles={{ left: { left: 0 } }}
          onResizeStop={onResizeStopSidebar}
        >
          <Box
            sx={{
              height: '100%',
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            {!issueId && !selectedLegacyAnnotationIds && !issueIdCurrentlyLinking && !compareDocumentVersionId && <DocumentVersionDetailsPanel documentVersionId={documentVersionId} onClickIssueItem={onClickIssueItem} />}
            {!issueId && !selectedLegacyAnnotationIds && !issueIdCurrentlyLinking && !!compareDocumentVersionId && <DocumentVersionComparePanel />}
            {!issueId && !selectedLegacyAnnotationIds && !!issueIdCurrentlyLinking && <IssueDetailsPanel issueId={issueIdCurrentlyLinking} nonInteractive title={t('document-details_link-issue-sidebar-header', 'Link issue: Locate')} />}
            {!!issueId && !selectedLegacyAnnotationIds && <IssueDetailsPanel issueId={issueId} onClose={onCloseIssueDetailsPanel} onClickDocumentVersionItem={onSelectDocumentVersionItem} onClickIssueItem={onClickIssueItem} onClickAnnotationItem={onClickAnnotationItem} />}
            {selectedLegacyAnnotationIds && <LegacyAnnotationPanel onClose={onCloseLegacyAnnotationPanel} pdfAnnotationIds={selectedLegacyAnnotationIds} />}
          </Box>
        </Resizable>
      </Box>
    </Box>
  );
}
