import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Typography,
} from '@mui/material';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import VerticalDivider from 'common/styled/VerticalDivider';
import HeaderBox from 'common/components/HeaderBox';
import AddCollaboratorDialogButton from 'collaborators/components/AddCollaboratorDialogButton';
import SendCollaboratorsEmailButton from 'collaborators/components/SendCollaboratorsEmailButton';
import CollaboratorsSearchTextField from 'collaborators/components/CollaboratorsSearchTextField';

export default function CollaboratorsPageHeader() {
  const { t } = useTranslation('collaborators');
  const currentUserRole = useCurrentCollaboratorRole();

  return (
    <HeaderBox id="CollaboratorsPageHeader">
      <Typography variant="h2" id="CollaboratorsPageHeaderTitle" sx={{ width: 292, pl: 4 }}>{t('collaborators-page-header_title', 'Collaborators')}</Typography>
      <VerticalDivider sx={{ height: 36 }} />
      <CollaboratorsSearchTextField />
      <Box sx={{
        ml: 'auto', display: 'flex', flexShrink: 0, pr: 4,
      }}
      >
        <SendCollaboratorsEmailButton sx={{ ml: 4, flexShrink: 0 }} />
        {!!currentUserRole?.allowedActions?.has(RoleAction.Project_EditMembers) && <AddCollaboratorDialogButton sx={{ flexShrink: 0, ml: 2 }} />}
      </Box>
    </HeaderBox>
  );
}
