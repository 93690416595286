import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';
import useProjectWorkPhasesQuery from 'labels/hooks/useProjectWorkPhasesQuery';
import LabelType from 'labels/types/LabelType';
import LabelSettingsPanel from 'settings/components/LabelSettingsPanel';

export default function WorkPhasesSettingsTab() {
  const allowedActions = useAllowedActions();
  const canCreate = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Create), [allowedActions]);
  const canEdit = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Update), [allowedActions]);
  const canDelete = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Label_Special_Delete), [allowedActions]);
  const { data: workPhases } = useProjectWorkPhasesQuery();
  return (
    <Box id="WorkPhasesSettingsTab" sx={{ p: 4 }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 4 }}>
        <LabelSettingsPanel
          labels={workPhases}
          labelType={LabelType.WorkPhase}
          canCreate={canCreate}
          canEdit={canEdit}
          canDelete={canDelete}
          variant="card"
        />
      </Box>
    </Box>
  );
}
