import { useEffect, useState } from 'react';
import {
  HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, JsonHubProtocol, LogLevel,
} from '@microsoft/signalr';
import useApiConfig from 'api/hooks/useApiConfig';
import ProjectSyncLogSummaryDto from 'api/types/ProjectSyncLogSummaryDto';
import useVisoplanApiContext from 'api/hooks/useVisoplanApiContext';
import { useNavigate, useParams } from 'react-router-dom';
import UrlParam from 'common/types/UrlParam';
import { useQueryClient } from '@tanstack/react-query';
import ProjectSyncLogSummaryEntryDto from 'api/types/ProjectSyncLogSummaryEntryDto';
import DataType from 'api/types/DataType';
import useCurrentUserQuery from 'users/hooks/useCurrentUserQuery';
import { useDispatch } from 'react-redux';
import { setSyncData } from 'Containers/ProjectContainer/Actions';
import UserChangedDto from 'api/types/UserChangedDto';
import OperationType from 'api/types/OperationType';
import ApiEndpoint from 'api/types/ApiEndpoint';
import useDefaultEntityQueryKeys from 'api/hooks/useQueryKeys';
import useDocumentCommentsOdataQueryData from 'documents/hooks/useDocumentCommentsOdataQueryData';
import DocumentCommentDto from 'documents/types/DocumentCommentDto';

const IS_DEVELOPMENT_ENVIRONMENT = process.env.NODE_ENV === 'development';

export default function useSync() {
  const { syncUrl } = useApiConfig();
  const { authUserToken, signOut } = useVisoplanApiContext();
  const queryClient = useQueryClient();
  const { projectId: currentProjectId } = useParams<UrlParam>();
  const [, setWatchedProjectId] = useState<string | undefined>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { queryKeyBases: projectBaseQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Project);
  const { queryKeyBases: roleBaseQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Role);
  const { queryKeyBases: projectRoleQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.ProjectRoles);
  const { queryKeyBases: collaboratorPermissionsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.CollaboratorPermissions);
  const { queryKeyBases: pdfAnnotationQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.PdfAnnotation);
  const { queryKeyBases: issueBaseQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Issue);
  const { listQueryKeyBases: commentListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Comment);
  const { listQueryKeyBases: modelListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Model);
  const { listQueryKeyBases: activityListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Activity);
  const { listQueryKeyBases: documentVersionListsQueryKeys, getDetailsByIdQueryKey: getDocumentVersionDetailsQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentVersion);
  const { getOdataQueryKey: getDocumentCommentOdataQueryKey, odataQueryKeyBase: documentCommentOdataQueryKey } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentComment);
  const { listQueryKeyBases: buildingMetaDataListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.BuildingMetaData);
  const { listQueryKeyBases: disciplineMetaDataListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.DisciplineMetaData);
  const { listQueryKeyBases: floorMetaDataListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.FloorMetaData);
  const { listQueryKeyBases: issueLogListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.IssueLog);
  const { listQueryKeyBases: folderTreeListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.FolderTree);
  const { listQueryKeyBases: folderListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Folder);
  const { listQueryKeyBases: processListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Process);
  const { listQueryKeyBases: tagListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.Tag);
  const { listQueryKeyBases: documentListListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.DocumentList);
  const { listQueryKeyBases: inboxEmailListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.InboxEmail);
  const { listQueryKeyBases: dynamicLayoutListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.DynamicLayout);
  const { listQueryKeyBases: propertyListListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.PropertyList);
  const { listQueryKeyBases: smartViewListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.SmartView);
  const { listQueryKeyBases: userGroupListsQueryKeys } = useDefaultEntityQueryKeys(ApiEndpoint.UserGroup);
  const { queryKeyBases: userQueryKeyBases } = useDefaultEntityQueryKeys(ApiEndpoint.User);

  const { data: currentUser } = useCurrentUserQuery();
  const fetchDocumentComments = useDocumentCommentsOdataQueryData();

  const [connectionPromise] = useState<Promise<HubConnection | undefined>>(async () => {
    const connection = new HubConnectionBuilder()
      .withUrl(syncUrl, {
        logMessageContent: IS_DEVELOPMENT_ENVIRONMENT,
        logger: IS_DEVELOPMENT_ENVIRONMENT ? LogLevel.Warning : LogLevel.Error,
        accessTokenFactory: authUserToken ? () => authUserToken.bearer : undefined,
        transport: HttpTransportType.LongPolling,
        headers: { 'Visoplan-Client-Id': 'Visoplan Webclient' },
      })
      .withAutomaticReconnect()
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Information)
      .build();

    connection.on('OnProjectChangedSummaryAsync', (dto: ProjectSyncLogSummaryDto) => {
      // TODO: remove this dispatch call (and according setup code elsewhere) as soon as the old redux based sync consumers have all been reworked to react query
      dispatch(setSyncData(dto));

      if (dto.wasProjectUpdated || dto.isProjectDeleted) {
        projectBaseQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
      }
      dto.dataSummaryEntries.forEach((entryDto: ProjectSyncLogSummaryEntryDto) => {
        switch (entryDto.dataType) {
          case DataType.Project:
            projectBaseQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            roleBaseQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            projectRoleQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            collaboratorPermissionsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.Issue:
            issueBaseQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.Comment:
            commentListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            issueBaseQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.Model:
            modelListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.Activity:
            activityListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.DocumentVersion:
            // when an entity was edited or deleted, update specific entity's detail cache
            [...entryDto.updatedIds, ...entryDto.deletedIds].forEach((id) => {
              queryClient.invalidateQueries({ queryKey: getDocumentVersionDetailsQueryKey(id) });
            });
            // list cache invalidation
            // if an entity was edited, added or removed, any list query can potentially be affected (e.g. any ODATA query)
            documentVersionListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            if (entryDto.addedIds.length || entryDto.deletedIds.length) {
              folderListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
              folderTreeListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            }
            pdfAnnotationQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.BuildingMetaData:
            buildingMetaDataListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.DisciplineMetaData:
            disciplineMetaDataListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.FloorMetaData:
            floorMetaDataListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.IssueLog:
            issueLogListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.Folder:
            folderListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            folderTreeListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.Process:
            processListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.Tag:
            tagListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.DocumentList:
            documentListListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            if (entryDto.addedIds?.length || entryDto.deletedIds?.length) {
              projectBaseQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            }
            break;
          case DataType.InboxEmail:
            inboxEmailListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.DynamicLayout:
            dynamicLayoutListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.CollaboratorRoleDefinition:
            projectRoleQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            collaboratorPermissionsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.PropertyListEntry:
            propertyListListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.SmartView:
            smartViewListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          case DataType.DocumentComment:
            {
              const idsToRequest = entryDto.addedIds.concat(entryDto.updatedIds);
              if (idsToRequest.length > 0) {
                fetchDocumentComments({ filter: { id: { in: idsToRequest } } })
                  .then((comments) => {
                    if (!comments) return;
                    const commentsById = new Map(comments.map((c) => [c.id, c]));
                    entryDto.addedIds.forEach((id) => {
                      const comment = commentsById.get(id);
                      if (!comment) return;
                      const queryKey = getDocumentCommentOdataQueryKey({ filter: { documentVersionId: comment.documentVersionId }, orderBy: 'creationDate desc' });
                      queryClient.setQueryData<DocumentCommentDto[] | undefined>(queryKey, (prev) => {
                        if (!prev) return [comment];
                        let result: DocumentCommentDto[];
                        if (prev.some((c) => c.id === id)) {
                          result = prev.map((c) => (c.id === id ? comment : c)); // deals with possible race condition between cache update and sync handler
                        } else {
                          result = [comment, ...prev];
                        }
                        return result;
                      });
                    });
                    entryDto.updatedIds.forEach((id) => {
                      const comment = commentsById.get(id);
                      if (!comment) return;
                      const queryKey = getDocumentCommentOdataQueryKey({ filter: { documentVersionId: comment.documentVersionId } });
                      queryClient.setQueryData<DocumentCommentDto[] | undefined>(queryKey, (prev) => prev?.map((c) => (c.id === id ? comment : c)));
                    });
                    entryDto.deletedIds.forEach((id) => {
                      queryClient.setQueriesData<DocumentCommentDto[] | undefined>({ queryKey: documentCommentOdataQueryKey }, (prev) => prev?.filter((c) => c.id !== id));
                    });
                  });
              }
            }
            break;
          case DataType.UserGroup:
            userGroupListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            folderTreeListsQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
            break;
          default:
            break;
        }
      });
    });

    connection.on('OnUserChangedAsync', (dto: UserChangedDto) => {
      // TODO: remove this dispatch call (and according setup code elsewhere) as soon as the old redux based sync consumers have all been reworked to react query
      dispatch(setSyncData(dto));

      if (dto.userId === currentUser?.id) {
        if (dto.operationType === OperationType.Delete) {
          signOut();
        }
      }
      userQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
    });

    connection.on('OnUserAddedToProjectAsync', (userId: string) => {
      if (!currentUser || userId === currentUser?.id) {
        userQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
      }
      projectBaseQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
    });

    connection.on('OnUserRemovedFromProjectAsync', (userId: string) => {
      if (!currentUser || userId === currentUser?.id) {
        userQueryKeyBases.forEach((queryKeyBase) => queryClient.invalidateQueries({ queryKey: queryKeyBase }));
        navigate('/projects');
      }
      projectBaseQueryKeys.forEach((queryKey) => queryClient.invalidateQueries({ queryKey, fetchStatus: 'idle' }));
    });

    try {
      await connection.start();
    } catch (e) {
      // establishing a sync connection failed => ignore
      if (connection.state === HubConnectionState.Disconnected) return undefined;
    }
    return connection;
  });

  useEffect(() => {
    if (currentUser) {
      connectionPromise.then((connection) => connection?.invoke('WatchCurrentUserAsync'));
    } else {
      connectionPromise.then((connection) => connection?.invoke('StopWatchingCurrentUserAsync'));
    }
  }, [connectionPromise, currentUser]);

  useEffect(() => {
    setWatchedProjectId((previouslyWatchedProjectId) => {
      if (currentProjectId !== previouslyWatchedProjectId) {
        if (previouslyWatchedProjectId) {
          connectionPromise.then((connection) => connection?.invoke('StopWatchingProjectAsync', previouslyWatchedProjectId));
        }
        if (currentProjectId) {
          connectionPromise.then((connection) => connection?.invoke('WatchProjectAsync', currentProjectId));
        }
        return currentProjectId;
      }
      return previouslyWatchedProjectId;
    });
  }, [currentProjectId, connectionPromise]);
}
