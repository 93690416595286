import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, Snackbar, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import RoleDefinitionListItem from 'settings/components/RoleDefinitionListItem';
import CreateRoleDialog from 'settings/components/CreateRoleDialog';
import useRolesOdataQuery from 'collaborators/hooks/useRolesOdataQuery';
import RoleActionNode, { MutationState, StateMap } from 'settings/components/RoleActionNode';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useRoleUpdateActionsMutation from 'collaborators/hooks/useRoleUpdateActionsMutation';
import RoleAction from 'projects/types/RoleAction';
import useRoleActionTranslations from 'settings/hooks/useRoleActionTranslations';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';

export default function RoleSettingsTabPane() {
  const { t } = useTranslation('settings');
  const allowedActions = useAllowedActions();
  const { data: roles, isLoading: isLoadingRoles } = useRolesOdataQuery({ filter: { isDeleted: false }, orderBy: [['isAdmin', 'desc'], ['creationDate', 'asc'], ['name', 'asc']] });
  const { mutateAsync, isPending: isLoadingUpdateActionsMutation } = useRoleUpdateActionsMutation();
  const rolesById = useMemo(() => (roles ? new Map(roles.map((d) => [d.id, d])) : undefined), [roles]);
  const [selectedRoleId, setSelectedRoleDefinitionId] = useState<string | undefined>(undefined);
  const selectedRole = useMemo(() => (selectedRoleId ? rolesById?.get(selectedRoleId) : undefined), [rolesById, selectedRoleId]);
  const disabled = useMemo(() => isLoadingRoles || isLoadingUpdateActionsMutation || selectedRole?.isAdmin || !allowedActions?.has(RoleAction.Project_Edit_Roles), [allowedActions, isLoadingRoles, isLoadingUpdateActionsMutation, selectedRole?.isAdmin]);
  const [isCreateRoleDialogOpen, setIsCreateRoleDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const getRequestErrorMessage = useRequestErrorMessage();
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);
  const [stateMap, setStateMap] = useState<StateMap>({});
  const onChangeSwitch = useCallback(async (action: RoleAction, isAllowed: boolean) => {
    if (!selectedRoleId) return;
    try {
      setStateMap((prev) => ({ ...prev, [action]: MutationState.Pending }));
      await mutateAsync({
        id: selectedRoleId,
        actions: [{
          action,
          isAllowed,
        }],
      });
      setStateMap((prev) => ({ ...prev, [action]: MutationState.Success }));
      setTimeout(() => setStateMap((prev) => ({ ...prev, [action]: undefined })), 1000);
    } catch (e) {
      setStateMap((prev) => ({ ...prev, [action]: MutationState.Error }));
      setErrorMessage(getRequestErrorMessage(e));
      setTimeout(() => {
        setStateMap((prev) => ({ ...prev, [action]: undefined }));
        setErrorMessage(undefined);
      }, 4000);
    }
  }, [getRequestErrorMessage, mutateAsync, selectedRoleId]);

  useEffect(() => {
    // select first role definition on init
    if (!selectedRoleId && roles?.length) {
      setSelectedRoleDefinitionId(roles[0].id);
    }
  }, [roles, selectedRoleId]);

  const onClickCreateRole = useCallback(() => {
    setIsCreateRoleDialogOpen(true);
  }, []);

  const onCreateRoleSuccess = useCallback((message: string) => {
    setSuccessMessage(message);
    setIsCreateRoleDialogOpen(false);
  }, []);

  const roleActionItems = useMemo(() => {
    if (!selectedRole) return undefined;
    const items = selectedRole.actions
      .map((rootAction) => ({ roleAction: rootAction, depth: 0 }))
      .flatMap((rootItem) => {
        const stack = [rootItem];
        const descendantAndSelf = [];

        while (stack.length) {
          const pivot = stack.pop()!;
          descendantAndSelf.push(pivot);

          if (pivot.roleAction.isAllowed ?? pivot.roleAction.defaultValue) {
            const childItems = pivot.roleAction.childActions
              .map((childAction) => ({
                roleAction: childAction,
                depth: pivot.depth + 1,
              }));
            // Push child items onto the stack in reverse order to maintain depth-first order
            stack.push(...childItems.reverse());
          }
        }

        return descendantAndSelf;
      });
    return items;
  }, [selectedRole]);

  const roleActionTranslations = useRoleActionTranslations();

  const canCreateRole = useMemo(() => allowedActions?.has(RoleAction.Project_Create_Roles), [allowedActions]);

  return (
    <Box
      id="RoleSettingsTabPane"
      sx={{
        display: 'grid',
        gridTemplateColumns: 'minmax(auto, 160px) 360px 96px 150px 600px 1fr',
        gridTemplateRows: 'auto 1fr',
        rowGap: 4,
        py: 8,
        px: 4,
      }}
    >
      <Box sx={{ display: 'flex', alignContent: 'center', gridColumn: '2 / 3', gridRow: '1 / 2' }}>
        <Typography variant="h2" sx={{ mb: 1, mr: 'auto' }}>{t('role-settings_project-roles', 'Project Roles')}</Typography>
        {canCreateRole && (
        <Button variant="contained" onClick={onClickCreateRole} sx={{ pl: 1 }}>
          <AddIcon sx={{ mr: 1 }} />
          {t('role-settings_create-new-role-button-caption', 'Create new role')}
        </Button>
        )}
      </Box>
      <Box sx={{ gridColumn: '2 / 3', gridRow: '2 / 3' }}>
        <List>
          {roles?.map((role) => (
            <RoleDefinitionListItem
              key={role.id}
              role={role}
              selected={selectedRoleId === role.id}
              onClick={() => setSelectedRoleDefinitionId(role.id)}
            />
          ))}
        </List>
      </Box>
      {!!selectedRole && (
        <Box sx={{ gridColumn: '5 / 6', gridRow: '1 / 3', display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="h3">{t('role-settings_restrictions-header', '{{roleName}} Restrictions', { roleName: selectedRole.name })}</Typography>
          {!!errorMessage && <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}><Alert severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert></Snackbar>}
          <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', rowGap: 1, columnGap: 2 }}>
            {!!roleActionItems && roleActionItems.map(({ roleAction, depth }) => (
              <RoleActionNode key={roleAction.action} label={roleActionTranslations[roleAction.action]} depth={depth} roleActionDto={roleAction} onChange={onChangeSwitch} stateMap={stateMap} disabled={disabled} />
            ))}
          </Box>
        </Box>
      )}
      {isCreateRoleDialogOpen && <CreateRoleDialog onSuccess={onCreateRoleSuccess} onClose={() => setIsCreateRoleDialogOpen(false)} />}
      {!!successMessage && (
      <Dialog open>
        <DialogTitle>{t('role-settings-tab-pane_success-dialog-title', 'Success')}</DialogTitle>
        <DialogContent>
          <Alert severity="success">
            {successMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setSuccessMessage(undefined)}>{t('role-settings-tab-pane_success-dialog-close', 'Close')}</Button>
        </DialogActions>
      </Dialog>
      )}
    </Box>
  );
}
