enum RoleAction {
  // ActivityLog
  ActivityLog = 0,
  ActivityLog_Export,
  // Project
  Project_Management = 100,
  Project_Copy,
  Project_CollaboratorManagement,
  Project_EditMembers = 103,
  Project_GroupManagement,
  Project_CreateGroups,
  Project_DeleteGroups,
  Project_EditGroups,
  Project_Edit_2Fa,
  Project_RoleManagement,
  Project_Create_Roles,
  Project_Edit_Roles,
  Project_Delete_Roles,
  // IssueManagement
  Issues = 200,
  Dashboard,
  // IssueTimeLine = 202, deleted by migration DBM027RemoveIssueTimeline
  IssueManagement_Creation = 203,

  /// <summary>
  /// User can completely edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Editing,

  /// <summary>
  /// User can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_StatusEditing,
  IssueManagement_CreatingComments,
  IssueManagement_BcfUpload,
  IssueManagement_Export,

  /// <summary>
  /// Assigned user can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Assigned_Editing,

  /// <summary>
  /// Assigned user can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_Assigned_StatusEditing,

  /// <summary>
  /// Creator can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Creator_Editing,

  /// <summary>
  /// Creator can edit issues status BUT is not allowed to edit closed issues or set the status to closed.
  /// </summary>
  IssueManagement_Creator_StatusEditing,

  /// <summary>
  /// Reviewer can edit an issues except the status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Reviewer_Editing,

  /// <summary>
  /// Reviewer can edit issues status BUT is not allowed to edit closed issues.
  /// </summary>
  IssueManagement_Reviewer_StatusEditing,

  /// <summary>
  /// User can edit issues status TO closed, BUT is not allowed to change the status FROM closed to something else.
  /// </summary>
  IssueManagement_StatusEditing_SetClosed,

  /// <summary>
  /// User can edit issues status FROM closed to something else, BUT is not allowed to change the status TO closed.
  /// </summary>
  IssueManagement_StatusEditing_UnsetClosed,

  // Document
  Documents = 300,

  /// <summary>
  /// User can upload new documents.
  /// </summary>
  Document_Upload,

  /// <summary>
  /// User can download documents.
  /// </summary>
  Document_Download,

  /// <summary>
  /// User can edit the properties of a document.
  /// </summary>
  Document_Editing,

  /// <summary>
  /// User can create sharelinks for documents.
  /// </summary>
  Document_Sharing,

  /// <summary>
  /// User can create and delete comments.
  /// </summary>
  Document_CommentEditing = 305,

  /// <summary>
  /// User can archive (non-private) documents
  /// </summary>
  Document_Archiving,

  /// <summary>
  /// User can access (non-private) archived documents
  /// </summary>
  Document_GetArchived,

  /// <summary>
  /// User can restore (non-private) archived documents
  /// </summary>
  Document_RestoreArchived,

  Document_Import = 310,
  /// <summary>
  /// User allow to run IFC import from document
  /// </summary>
  Document_IfcImport,

  /// <summary>
  /// User allow to run 2D import from document
  /// </summary>
  Document_FloorPlanImport,

  Document_StatusEditing,

  // Folder

  /// <summary>
  /// User allowed to edit folder properties of folder where he is not the creator
  /// </summary>
  Folder_Edit = 400,

  /// <summary>
  /// User allowed to edit folder access list of folder where he is not the creator
  /// </summary>
  Folder_EditAccessList,
  Folder_Create = 402,
  Folder_Delete = 403,

  // Labels
  Tag_Management = 500,
  Tag_Create,
  Tag_Update,
  Tag_Delete,

  // Special Labels
  Label_Special = 550,
  Label_Special_Create,
  Label_Special_Update,
  Label_Special_Delete,

  /// <summary>
  /// User able to create, edit naming scheme
  /// </summary>
  NamingScheme_Management = 800,
  NamingScheme_Create,
  NamingScheme_Update,
  NamingScheme_Delete,

  DocumentList = 900,
  DocumentList_Create,
  DocumentList_Edit,
  /// <summary>
  /// User is able to delete public document lists
  /// </summary>
  DocumentList_Delete,

  DocumentFilter = 950,
  DocumentFilter_Create,
  DocumentFilter_Edit,
  DocumentFilter_Delete,

  /// <summary>
  /// User can change from any status to status "published"
  /// </summary>
  DocStatus_ChangeToPublished = 1103,

  /// <summary>
  /// User can change from status "published" to any other status
  /// </summary>
  DocStatus_ChangeFromPublished = 1104,

  /// <summary>
  /// User can enable, disable and configure document stamps (inserted on download)
  /// </summary>
  DocStamp_Edit = 1150,

  /// <summary>
  /// User can delete public smartViews created by different User.
  /// </summary>
  SmartView_DeleteFromDifferentCreator = 1300,

  /// <summary>
  /// User can rename inbox email address
  /// </summary>
  Inbox_Edit = 1400,

  _3DModel = 1500,
  _3DModel_Properties,
  _3DModel_Edit_Properties,
  _3DModel_3D_View,
  _3DModel_SmartView,

  _2DModel = 1600,

  Email_View = 1700,
  Email_Upload,

  // BCF
  BCF = 2000,
  BCF_Project_update,
  BCF_Project_createTopic,
  BCF_Project_createDocument,
  BCF_Topic_update,
  BCF_Topic_updateRelatedTopics,
  BCF_Topic_updateDocumentReferences,
  BCF_Topic_updateFiles,
  BCF_Topic_createComment,
  BCF_Topic_createViewpoint,
  BCF_Topic_delete,
  BCF_Viewpoint_delete,
  BCF_Comment_update,
  BCF_Comment_delete,
}

export default RoleAction;
