import { DockviewApi } from 'dockview';
import { createContext } from 'react';

export enum ModelsPanelDockviewPanelId {
  Viewer3d = 'viewer-3d',
  ModelSelection = 'model-selection',
  Issues = 'issues',
  SectionPlanes = 'section-planes',
  Measurements = 'measurements',
  Plan2d = 'plan-2d',
  ComponentDetails = 'component-details',
}

export interface ModelsPanelDockviewContextState {
  dockviewApi: DockviewApi | undefined,
  setDockviewApi: (api: DockviewApi) => void,
  visiblePanelIds: Set<ModelsPanelDockviewPanelId> | undefined,
  showPanel: (panelId: ModelsPanelDockviewPanelId) => void,
  hidePanel: (panelId: ModelsPanelDockviewPanelId) => void,
  togglePanelVisible: (panelId: ModelsPanelDockviewPanelId) => void,
  resetLayoutToDefault: () => void,
}

const defaultState: ModelsPanelDockviewContextState = {
  dockviewApi: undefined,
  setDockviewApi: () => {},
  visiblePanelIds: new Set(),
  showPanel: () => {},
  hidePanel: () => {},
  togglePanelVisible: () => {},
  resetLayoutToDefault: () => {},
};

const ModelsPanelDockviewContext = createContext<ModelsPanelDockviewContextState>(defaultState);
export default ModelsPanelDockviewContext;
