import React, { useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ISxProps from 'common/types/ISxProps';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import { useTranslation } from 'react-i18next';
import IssueLabelsChipList from 'issues/components/IssueLabelsChipList';
import LabelChip from 'labels/components/LabelChip';
import { mdiBug, mdiCalendarEnd, mdiCalendarStart, mdiStateMachine } from '@mdi/js';
import IconChip from 'labels/components/IconChip';
import useIssueOverdueCheck from 'issues/hooks/useIssueOverdueCheck';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import useProjectIssueTypesQuery from 'issues/hooks/useProjectIssueTypesQuery';

interface IssueDetailsGeneralPanelProps extends ISxProps {
  issueId: string,
}

export default function IssueDetailsGeneralPanel({
  sx,
  issueId,
}: IssueDetailsGeneralPanelProps) {
  const theme = useTheme();
  const { t } = useTranslation('issues');
  const { data: issue } = useIssueQuery(issueId);
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  const { data: issueTypes } = useProjectIssueTypesQuery();
  const issueStatus = useMemo(() => (issue && issueStatuses ? issueStatuses.find((status) => issue.issueStatus === status.id) : undefined), [issue, issueStatuses]);
  const issueType = useMemo(() => (issue && issueTypes ? issueTypes.find((type) => issue.issueType === type.id) : undefined), [issue, issueTypes]);
  const creatorName = useMemo(() => (issue ? `${issue.createAuthor.firstName} ${issue.createAuthor.lastName}` : ''), [issue]);
  const editorName = useMemo(() => (issue?.editAuthor ? `${issue.editAuthor.firstName} ${issue.editAuthor.lastName}` : undefined), [issue]);
  const creationDateString = useMemo(() => (issue ? new Date(issue.creationDate).toLocaleString('de-DE') : ''), [issue]);
  const editDateString = useMemo(() => (issue?.editDate ? new Date(issue.editDate).toLocaleString('de-DE') : ''), [issue]);
  const isOverdue = useIssueOverdueCheck(issue);
  const dateColor = useMemo(() => (isOverdue ? theme.palette.error.main : undefined), [isOverdue, theme.palette.error]);
  const dueDateString = useMemo(() => (issue?.dueDate ? new Date(issue.dueDate).toLocaleDateString('de-DE') : ''), [issue]);
  const startDateString = useMemo(() => (issue?.startingDate ? new Date(issue.startingDate).toLocaleDateString('de-DE') : ''), [issue]);
  if (!issue) return null;
  return (
    <Box
      id="IssueDetailsGeneralPanel"
      sx={{
        ...sx,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 2,
        pt: 3,
        fontSize: 14,
      }}
    >
      <Box sx={{ ...sx, display: 'flex', gap: 0.5, alignItems: 'flex-start' }}>
        <Box sx={{ mr: 'auto', borderRadius: '4px', backgroundColor: theme.palette.grey[700], color: theme.palette.background.default, fontWeight: 600, fontSize: 20, p: '2px', px: '4px', textAlign: 'center' }}>
          {issue.issueNumber}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 0.5 }}>
          <Box sx={{ flexGrow: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center', justifyContent: 'flex-end' }}>
            {!!issueType && <LabelChip label={issueType} mdiIconPath={mdiBug} />}
            {!!issueStatus && <LabelChip label={issueStatus} mdiIconPath={mdiStateMachine} />}
          </Box>
          {(!!startDateString || !!dueDateString) && (
            <Box sx={{ flexGrow: 1, display: 'flex', flexWrap: 'wrap', gap: 0.5, alignItems: 'center', justifyContent: 'flex-end' }}>
              {!!startDateString && <IconChip text={startDateString} mdiIconPath={mdiCalendarStart} tooltip={t('issue-details-general-panel_start-date-toolip', 'Start Date: {{startDate}}', { startDate: startDateString })} />}
              {!!dueDateString && <IconChip text={dueDateString} color={dateColor} mdiIconPath={mdiCalendarEnd} tooltip={t('issue-details-general-panel_due-date-toolip', 'Due Date: {{dueDate}}', { dueDate: dueDateString })} />}
            </Box>
          )}
        </Box>
      </Box>
      <Typography variant="h2">{issue.title}</Typography>
      {!!issue.description?.trim().length && <Typography sx={{ fontSize: 14, whiteSpace: 'pre-line' }}>{issue.description}</Typography>}
      <IssueLabelsChipList issueId={issueId} />
      <Box>
        <Typography color="grey">{t('issue-details-general-panel_created-by-at', 'Created by {{creatorName}} on {{creationDate}}', { creatorName, creationDate: creationDateString })}</Typography>
        {!!editorName && <Typography color="grey">{t('issue-details-general-panel_edited-by-at', 'Last edited by {{editorName}} on {{editDate}}', { editorName, editDate: editDateString })}</Typography>}
      </Box>
    </Box>
  );
}
