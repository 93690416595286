import React, {
  Dispatch, SetStateAction, useCallback, useMemo, forwardRef,
} from 'react';
import {
  Box, CircularProgress, Typography, useTheme, Link, styled,
} from '@mui/material';
import DocumentCommentDto from 'documents/types/DocumentCommentDto';
import ISxProps from 'common/types/ISxProps';
import { useTranslation } from 'react-i18next';
import useDocumentCommentDeleteMutation from 'documents/hooks/useDocumentCommentDeleteMutation';
import axios from 'axios';
import InlineTypography from 'common/components/InlineTypography';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';

const HighlightableBox = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: '2s',
  }),
  backgroundColor: theme.palette.grey[200],
  '&.current-users-comment': {
    backgroundColor: theme.palette.secondary.main,
  },
  '&.highlighted': {
    backgroundColor: theme.palette.success.light,
  },
}));

const mentionSplitRegex = /(@(?:User|UserGroup)\[[^\]]+\]\([^)]+\))/i;
const mentionLabelRegex = /@(?:User|UserGroup)\[([^\]]+)\]\(([^)]+)\)/i;

interface DocumentCommentItemProps extends ISxProps {
  comment: DocumentCommentDto,
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>,
  isLastComment: boolean,
  highlighted?: boolean,
}

export default forwardRef<HTMLDivElement, DocumentCommentItemProps>(({
  sx,
  comment,
  setErrorMessage,
  isLastComment,
  highlighted,
}, ref) => {
  const theme = useTheme();
  const { t } = useTranslation('documents-details');
  const { mutateAsync } = useDocumentCommentDeleteMutation();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const currentCollaboratorRole = useCurrentCollaboratorRole();
  const isCurrentUsersComment = useMemo(() => (currentCollaborator ? comment.creator.id === currentCollaborator.id : undefined), [comment, currentCollaborator]);
  const canDelete = useMemo(
    () => isLastComment
    && !comment.isRemoved
    && !comment.id.startsWith('temp_')
    && currentCollaborator
    && comment.creator.id === currentCollaborator.id
    && currentCollaboratorRole?.allowedActions?.has(RoleAction.Document_CommentEditing),
    [comment.creator.id, comment.id, comment.isRemoved, currentCollaborator, currentCollaboratorRole?.allowedActions, isLastComment],
  );
  const onClickDelete = useCallback(async () => {
    try {
      await mutateAsync(comment);
    } catch (error: any) {
      setErrorMessage(axios.isAxiosError(error) && error.response?.data?.message
        ? error.response?.data?.message
        : error?.message ?? t('document-comment-drawer_delete-unknown-error-message', 'Deleting the comment failed due to an unknown problem.'));
    }
  }, [comment, mutateAsync, setErrorMessage, t]);

  const commentComponents = useMemo(() => {
    if (!currentCollaborator) return null;
    if (comment.isRemoved) {
      return <InlineTypography sx={{ fontStyle: 'italic', color: theme.palette.text.secondary }}>{t('document-comments_removed-comment-text', 'Comment removed')}</InlineTypography>;
    }
    const parts = comment.text.split(mentionSplitRegex);
    return parts.map((part) => {
      const labelMatch = part.match(mentionLabelRegex);
      if (labelMatch) {
        return (
          <InlineTypography
            key={crypto.randomUUID()}
            sx={{ fontWeight: 600, color: labelMatch[2] === currentCollaborator.id ? theme.palette.secondary.contrastText : undefined }}
          >
            {`@${labelMatch[1]}`}
          </InlineTypography>
        );
      }
      return <InlineTypography key={crypto.randomUUID()}>{part}</InlineTypography>;
    });
  }, [currentCollaborator, comment.isRemoved, comment.text, theme.palette.text.secondary, theme.palette.secondary.contrastText, t]);

  return (
    <HighlightableBox
      id="DocumentCommentItem"
      ref={ref}
      key={comment.id}
      sx={{
        p: 1, borderRadius: 1, ...sx,
      }}
      className={[
        ...(highlighted ? ['highlighted'] : []),
        ...(isCurrentUsersComment ? ['current-users-comment'] : []),
      ].join(' ')}
    >
      <Box sx={{ display: 'flex', alignItems: 'top' }}>
        <Typography sx={{ fontWeight: 700 }}>{`${comment.creator.firstName} ${comment.creator.lastName}`}</Typography>
        {comment.id.startsWith('temp_')
          ? <CircularProgress size={10} sx={{ ml: 'auto ' }} />
          : (
            <Typography sx={{ ml: 'auto ' }}>
              {new Date(comment.creationDate).toLocaleString('de-DE')}
            </Typography>
          )}
      </Box>
      <Box sx={{
        mt: 0.5, lineHeight: 1.25, whiteSpace: 'break-spaces', wordBreak: 'break-word',
      }}
      >
        {commentComponents}
      </Box>
      {!!canDelete && <Box sx={{ textAlign: 'right' }}><Link onClick={onClickDelete}>{t('document-comments_delete-button-label', 'Delete')}</Link></Box>}
    </HighlightableBox>
  );
});
