import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import LabelType from 'labels/types/LabelType';
import LabelSettingsPanel from 'settings/components/LabelSettingsPanel';
import useProjectTagsQuery from 'tags/hooks/useProjectTagsQuery';
import useAllowedActions from 'collaborators/hooks/useAllowedActions';
import RoleAction from 'projects/types/RoleAction';

export default function TagSettingsTab() {
  const { data: tags } = useProjectTagsQuery();
  const allowedActions = useAllowedActions();
  const canCreate = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Tag_Create), [allowedActions]);
  const canEdit = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Tag_Update), [allowedActions]);
  const canDelete = useMemo(() => !!allowedActions && allowedActions.has(RoleAction.Tag_Delete), [allowedActions]);
  return (
    <Box id="TagsSettingsTab" sx={{ p: 4 }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 4 }}>
        <LabelSettingsPanel
          labels={tags}
          labelType={LabelType.Tag}
          canCreate={canCreate}
          canEdit={canEdit}
          canDelete={canDelete}
          variant="card"
        />
      </Box>
    </Box>
  );
}
