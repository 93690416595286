import React, { useCallback, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import IssueDetailsPanel from 'issues/components/IssueDetailsPanel';
import useModelSelectionContext from 'models/hooks/useModelSelectionContext';
import ViewpointDto from 'issues/types/ViewpointDto';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import EditViewpointsDialog from 'models/components/EditViewpointsDialog';
import useModelsInteractionContext from 'models/hooks/useModelsInteractionContext';
import EditComponentsDialog from 'models/components/EditComponentsDialog';

interface ComponentDetailsIssueDetailsPanelProps {
  issueId: string,
  onClose: () => void,
}

export default function ComponentDetailsIssueDetailsPanel({
  issueId,
  onClose,
}: ComponentDetailsIssueDetailsPanelProps) {
  const theme = useTheme();
  const { loadModelFileIds } = useModelSelectionContext();
  const { data: issue } = useIssueQuery(issueId);
  const { interactionMode } = useModelsInteractionContext();

  const { setSelectedViewpoint } = useModelSelectionContext();
  const onClickViewpointItem = useCallback(async (nextViewpoint: ViewpointDto) => {
    if (issue && issue.modelFileComponentIds) {
      loadModelFileIds(Object.keys(issue.modelFileComponentIds));
    }
    setSelectedViewpoint(nextViewpoint);
  }, [issue, loadModelFileIds, setSelectedViewpoint]);

  const [editViewpointsDialogOpen, setEditViewpointsDialogOpen] = useState<boolean>(false);
  const onClickEditViewpoints = useCallback(() => setEditViewpointsDialogOpen(true), []);
  const onCloseEditViewpointsDialog = useCallback(() => setEditViewpointsDialogOpen(false), []);

  const [editComponentsDialogOpen, setEditComponentsDialogOpen] = useState<boolean>(false);
  const onClickEditComponents = useCallback(() => setEditComponentsDialogOpen(true), []);
  const onCloseEditComponentsDialog = useCallback(() => setEditComponentsDialogOpen(false), []);

  return (
    <Box id="ComponentDetailsIssueDetailsPanel" sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: theme.palette.background.default }}>
      <IssueDetailsPanel
        onClose={onClose}
        issueId={issueId}
        onClickViewpointItem={onClickViewpointItem}
        onClickEditViewpoints={onClickEditViewpoints}
        onClickEditComponents={onClickEditComponents}
      />
      {!!editViewpointsDialogOpen && !interactionMode && (
        <EditViewpointsDialog issueId={issueId} onClose={onCloseEditViewpointsDialog} />
      )}
      {!!editComponentsDialogOpen && !interactionMode && (
        <EditComponentsDialog issueId={issueId} onClose={onCloseEditComponentsDialog} />
      )}
    </Box>
  );
}
