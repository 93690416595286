import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box, FormControl, InputLabel, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CollaboratorSelect from 'collaborators/components/CollaboratorSelect';
import useLetterNumbering from 'documents/hooks/useLetterNumbering';
import DocumentStatusMultiselect from 'documents/components/DocumentStatusMultiselect';
import DocumentFilterState from 'documents-filter/types/DocumentFilterState';
import FileTypeSelect from 'documents/components/FileTypeSelect';
import useNamingSchemeSettingsQuery from 'naming-schemes/hooks/useNamingSchemeSettingsQuery';

interface DetailsFilterSectionProps {
  value: DocumentFilterState,
  onChange: (state: DocumentFilterState) => void,
}

enum VersionValueValidationResult {
  Valid,
  InvalidLetterVersion,
  InvalidNumericVersion,
}

export default function DetailsFilterSection({
  value,
  onChange,
}: DetailsFilterSectionProps) {
  const { t } = useTranslation('documents-filter');
  const { data: namingSchemeSettings } = useNamingSchemeSettingsQuery();
  const { letterOrNumericStringToNumber, numberToLetter } = useLetterNumbering();
  const [versionTextFieldValue, setVersionTextFieldValue] = useState<string>('');

  const isLetterVersioningEnabled = namingSchemeSettings?.useLetterVersioning;

  const validateVersionValue = useCallback((versionValue: string) => {
    if (isLetterVersioningEnabled && !versionValue.match(/^[a-zA-Z]*$/)) return VersionValueValidationResult.InvalidLetterVersion;
    if (!isLetterVersioningEnabled && !versionValue.match(/^[0-9]*$/)) return VersionValueValidationResult.InvalidNumericVersion;
    return VersionValueValidationResult.Valid;
  }, [isLetterVersioningEnabled]);

  const verionValueErrorText = useMemo(() => {
    const validationResult = validateVersionValue(versionTextFieldValue);
    if (validationResult === VersionValueValidationResult.InvalidLetterVersion) return t('filter-drawer_version-helper_letter', 'This project uses letters for version numbers');
    if (validationResult === VersionValueValidationResult.InvalidNumericVersion) return t('filter-drawer_version-helper_digits', 'This project uses digits for version numbers');
    return undefined;
  }, [t, validateVersionValue, versionTextFieldValue]);

  useEffect(() => {
    setVersionTextFieldValue(isLetterVersioningEnabled && value.versionNumber ? numberToLetter(value.versionNumber) : `${value.versionNumber ?? ''}`);
  }, [isLetterVersioningEnabled, numberToLetter, value.versionNumber]);

  const handleChangeVersionValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVersionTextFieldValue(e.target.value);
    const validationResult = validateVersionValue(e.target.value);
    if (validationResult !== VersionValueValidationResult.Valid) return;
    const numericVersionNumber = e.target.value ? letterOrNumericStringToNumber(e.target.value) : undefined;
    if (value.versionNumber !== numericVersionNumber) {
      onChange({ ...value, versionNumber: numericVersionNumber });
    }
  }, [letterOrNumericStringToNumber, onChange, validateVersionValue, value]);

  return (
    <>
      <Typography variant="h4" sx={{ mt: 3 }}>{t('filter-drawer_details-header', 'Details')}</Typography>
      <Box sx={{
        display: 'grid', gridTemplateColumns: '.5fr .5fr', columnGap: '4px', rowGap: '8px', mt: 1,
      }}
      >
        <FormControl>
          <InputLabel id="documents-filter_creator-label">{t('filter-drawer_creator-label', 'Creator')}</InputLabel>
          <CollaboratorSelect
            label={t('filter-drawer_creator-label', 'Creator')}
            value={value.creatorCollaboratorIds ?? []}
            onChange={(ids) => onChange({ ...value, creatorCollaboratorIds: ids })}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="documents-filter_editor-label">{t('filter-drawer_editor-label', 'Last edited by')}</InputLabel>
          <CollaboratorSelect
            label={t('filter-drawer_editor-label', 'Last edited by')}
            value={value.editorCollaboratorIds ?? []}
            onChange={(ids) => onChange({ ...value, editorCollaboratorIds: ids })}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="documents-filter_filetype-label">{t('filter-drawer_filetype-label', 'File Type')}</InputLabel>
          <FileTypeSelect
            label={t('filter-drawer_filetype-label', 'File Type')}
            value={value.fileTypes ?? []}
            onChange={(f) => onChange({ ...value, fileTypes: f })}
          />
        </FormControl>
        <TextField
          label={t('filter-drawer_version-label', 'Version')}
          value={versionTextFieldValue}
          onChange={handleChangeVersionValue}
          error={!!verionValueErrorText}
          helperText={verionValueErrorText}
        />
        <FormControl>
          <InputLabel id="documents-filter_statuses-label">{t('filter-drawer_statuses-label', 'Statuses')}</InputLabel>
          <DocumentStatusMultiselect
            label={t('documents-statuses-label', 'Statuses')}
            value={value.documentStatusLabelIds ?? []}
            onChange={(documentStatusLabelIds) => onChange({ ...value, documentStatusLabelIds })}
          />
        </FormControl>
      </Box>
    </>
  );
}
