import React, { useMemo } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, Box, LinearProgress, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface BcfImportProgressDialogProps {
  onClose: () => void,
  fileNames: string[],
  progress: number,
  completed: boolean,
  errorMessage: string | undefined,
}

export default function BcfImportProgressDialog({
  onClose,
  fileNames,
  progress,
  completed,
  errorMessage,
}: BcfImportProgressDialogProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const color = useMemo(() => {
    if (completed) return 'success';
    if (errorMessage) return 'error';
    return 'info';
  }, [completed, errorMessage]);
  return (
    <Dialog open id="BcfImportProgressDialog">
      <DialogTitle>
        {t('bcf-import-progress-dialog_title', 'BCF Upload')}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {!errorMessage && !completed && (
          <Alert severity="info">
            {t('bcf-import-progress-dialog_in-progress-message', 'Importing {{count}} BCF files...', { count: fileNames.length })}
          </Alert>
        )}
        {!errorMessage && !!completed && (
          <Alert severity="info">
            {t('bcf-import-progress-dialog_in-progress-message', 'Imported {{count}} BCF files.', { count: fileNames.length })}
          </Alert>
        )}
        {!!errorMessage && (
          <Alert severity="error">
            {errorMessage}
          </Alert>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, minWidth: 250 }}>
          <LinearProgress variant="determinate" value={progress * 100} sx={{ flexGrow: 1 }} color={color} />
          <Typography sx={{
            color: theme.palette[color].main,
            fontWeight: 500,
            pl: 1,
          }}
          >
            {(completed ? '100%' : `${Math.floor(errorMessage ? 0 : progress)} %`)}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ gap: 2, justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={onClose} disabled={completed && !!errorMessage}>
          {t('bcf-import-progress-dialog_confirm-button-label', 'Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
