import useLetterNumbering from 'documents/hooks/useLetterNumbering';
import useNamingSchemeSettingsQuery from 'naming-schemes/hooks/useNamingSchemeSettingsQuery';
import { useCallback } from 'react';

export default function useDocumentVersionNumberString() {
  const { data: namingSchemeSettings } = useNamingSchemeSettingsQuery();
  const { numberToLetter } = useLetterNumbering();
  return useCallback((versionNumber: number | undefined) => {
    if (!namingSchemeSettings || versionNumber === undefined) return undefined;
    if (namingSchemeSettings?.useLetterVersioning) {
      return numberToLetter(versionNumber);
    }
    return versionNumber;
  }, [namingSchemeSettings, numberToLetter]);
}
