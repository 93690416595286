import { useMemo } from 'react';
import useProjectIssueStatusesQuery from 'issues/hooks/useProjectIssueStatusesQuery';
import LabelDto from 'labels/types/LabelDto';

export function inReviewIssueStatusFilterPredicate(issueStatus: LabelDto | string) {
  const name = typeof issueStatus === 'string' ? issueStatus : issueStatus.originalName;
  return name === 'In Review';
}

export default function useInReviewIssuesStatus() {
  const { data: issueStatuses } = useProjectIssueStatusesQuery();
  return useMemo(() => issueStatuses?.find(inReviewIssueStatusFilterPredicate), [issueStatuses]);
}
