import React, { useCallback, useMemo } from 'react';
import { Box, Button, CircularProgress, Typography, useTheme } from '@mui/material';
import { mdiCancel, mdiCheck, mdiFileDocumentEdit } from '@mdi/js';
import useDocumentViewerContext from 'documents-details/hooks/useDocumentViewerContext';
import useCurrentCollaboratorQuery from 'collaborators/hooks/useCurrentCollaboratorQuery';
import useCurrentCollaboratorRole from 'collaborators/hooks/useCurrentCollaboratorRole';
import RoleAction from 'projects/types/RoleAction';
import useIssueQuery from 'issues/hooks/useIssueQuery';
import usePdfAnnotationCreateMutation from 'documents-annotations/hooks/usePdfAnnotationCreateMutation';
import { useTranslation } from 'react-i18next';
import ISxProps from 'common/types/ISxProps';
import Icon from '@mdi/react';
import IssueAnnotationItem from 'issues/components/IssueAnnotationItem';
import { Core } from '@pdftron/webviewer';
import useResourceUploadHandler from 'resources/hooks/useResourceUploadHandler';
import useDocumentDetailsSelectionContext from 'documents-details/hooks/useDocumentDetailsSelectionContext';
import usePdfAnnotationsQuery from 'documents-annotations/hooks/usePdfAnnotationsQuery';
import PdfAnnotationDto from 'documents-annotations/types/PdfAnnotationDto';
import useClosedIssueStatus from 'issues/hooks/useClosedIssueStatus';
import useIssueUpdateMutation from 'issues/hooks/useIssueUpdateMutation';
import useIssueQueryData from 'issues/hooks/useIssueQueryData';

interface IssueManageAnnotationsPanelProps extends ISxProps {
  nonInteractive: boolean | undefined;
  issueId: string | undefined;
  onClickAnnotationItem?: (annotation: PdfAnnotationDto) => void;
}

export default function IssueManageAnnotationsPanel({
  sx,
  nonInteractive,
  issueId,
  onClickAnnotationItem,
}: IssueManageAnnotationsPanelProps) {
  const { t } = useTranslation('issues');
  const theme = useTheme();
  const handleUploadResources = useResourceUploadHandler();
  const { documentVersionId, setIssueId } = useDocumentDetailsSelectionContext();
  const { webViewerInstance, setIssueIdCurrentlyLinking, issueIdCurrentlyLinking, setSelectedAnnotationNames, annotationItems } = useDocumentViewerContext();
  const { data: currentCollaborator } = useCurrentCollaboratorQuery();
  const currentUserRole = useCurrentCollaboratorRole();
  const { data: issue } = useIssueQuery(issueId);
  const getIssue = useIssueQueryData();
  const { mutateAsync: createPdfAnnotationAsync } = usePdfAnnotationCreateMutation();
  const { mutateAsync: updateIssueAsync } = useIssueUpdateMutation();

  const pdfAnnotationsOdataQuery = useMemo(() => ({ filter: [
    ...(issueId ? [{ linkedIssueId: issueId }] : []),
  ] }), [issueId]);
  const { data: allAnnotationItems } = usePdfAnnotationsQuery(pdfAnnotationsOdataQuery);

  const annotationItemsInDocument = useMemo(() => annotationItems?.filter((a) => a.visoplanAnnotation?.linkedIssueId === issueId), [annotationItems, issueId]);

  const canEditIssue = useMemo(() => {
    if (!currentUserRole || !currentCollaborator || !issue) return undefined;
    if (currentUserRole.allowedActions.has(RoleAction.IssueManagement_Editing)) return true;
    if (issue.createAuthor.id === currentCollaborator.id && currentUserRole.allowedActions.has(RoleAction.IssueManagement_Creator_Editing)) return true;
    if (issue.assignedCollaboratorIds.some((id) => id === currentCollaborator.id) && currentUserRole.allowedActions.has(RoleAction.IssueManagement_Assigned_Editing)) return true;
    return false;
  }, [currentCollaborator, currentUserRole, issue]);

  const onClickAddAnnotations = useCallback(() => {
    setIssueIdCurrentlyLinking(issueId);
  }, [issueId, setIssueIdCurrentlyLinking]);

  const onClickDiscard = useCallback(() => {
    if (!webViewerInstance) return;
    const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => a.getCustomData('annotationKind') === 'temp');
    webViewerInstance.Core.annotationManager.deleteAnnotations(annotations);
    setIssueIdCurrentlyLinking(undefined);
  }, [webViewerInstance, setIssueIdCurrentlyLinking]);

  const takeScreenshot = useCallback(async (annotation: Core.Annotations.Annotation) => {
    if (!webViewerInstance) throw new Error('webViewerInstance not defined');
    const { documentViewer } = webViewerInstance.Core;
    const pageNumber = annotation.PageNumber;
    const zoom = 5; // Resolution of the screenshot
    const zoomOutFactor = 0.1;

    return new Promise<string>((resolve) => {
      documentViewer.getDocument().loadCanvas({
        pageNumber,
        zoom,
        drawComplete: async (canvas) => {
          const corePageRotation = (documentViewer.getDocument().getPageRotation(pageNumber) / 90) % 4;
          webViewerInstance.Core.annotationManager.setAnnotationCanvasTransform(canvas.getContext('2d'), zoom, corePageRotation);

          await documentViewer.getAnnotationManager().drawAnnotations({ pageNumber, overrideCanvas: canvas });

          // Get annotation bounding box in document coordinates
          const { x1, y1, x2, y2 } = annotation.getRect();
          const width = (x2 - x1) * zoom;
          const height = (y2 - y1) * zoom;

          // Calculate the position and size on the canvas
          const canvasX = x1 * zoom;
          const canvasY = y1 * zoom;

          // Determine the size of the square side
          const squareSide = Math.max(width, height) * (1 + zoomOutFactor);

          // Calculate the offsets to center the annotation in the square canvas
          const offsetX = (squareSide - width) / 2;
          const offsetY = (squareSide - height) / 2;

          // Calculate the bounding box for the square area around the annotation
          const squareX1 = Math.max(0, canvasX - offsetX);
          const squareY1 = Math.max(0, canvasY - offsetY);
          const squareX2 = Math.min(canvas.width, canvasX + width + offsetX);
          const squareY2 = Math.min(canvas.height, canvasY + height + offsetY);

          // Create a new canvas to draw the annotation area
          const screenshotCanvas = document.createElement('canvas');
          screenshotCanvas.width = squareSide;
          screenshotCanvas.height = squareSide;
          const screenshotCtx = screenshotCanvas.getContext('2d');

          if (!screenshotCtx) throw new Error('Failed to get 2D context');

          // Draw the annotation area on the new canvas
          screenshotCtx.drawImage(
            canvas,
            squareX1, // Source x coordinate on the original canvas
            squareY1, // Source y coordinate on the original canvas
            squareX2 - squareX1, // Width of the source rectangle
            squareY2 - squareY1, // Height of the source rectangle
            0,
            0,
            squareSide, // Destination width on the new canvas
            squareSide, // Destination height on the new canvas
          );

          screenshotCanvas.toBlob(async (blob) => {
            if (!blob) {
              resolve('');
              return;
            }
            const file = new File([blob], `${annotation.ToolName}${annotation.DateModified}.png`, { type: 'image/png' });
            const [id] = await handleUploadResources([file]);
            resolve(id);
          });
        },
      });
    });
  }, [handleUploadResources, webViewerInstance]);

  const onClickConfirm = useCallback(async () => {
    if (!webViewerInstance) throw new Error('webViewerInstance missing');
    if (!documentVersionId) throw new Error('documentVersionId missing');
    if (!issueIdCurrentlyLinking) throw new Error('issueIdCurrentlyLinking missing');
    const annotations = webViewerInstance.Core.annotationManager.getAnnotationsList().filter((a) => a.getCustomData('annotationKind') === 'temp');
    if (annotations.length > 0) {
      // if annotations were created, we only have to persist those. The API will automatically link the issue to the document version.
      await Promise.all(annotations.map(async (annotation) => {
        const id = await takeScreenshot(annotation);
        annotation.deleteCustomData('annotationKind');
        const xfdfString = await webViewerInstance.Core.annotationManager.exportAnnotations({ annotationList: [annotation] });
        webViewerInstance.Core.annotationManager.deleteAnnotations([annotation]);
        await createPdfAnnotationAsync({
          documentVersionId,
          linkedIssueId: issueIdCurrentlyLinking,
          XFDFString: xfdfString,
          thumbnailImageId: id,
        });
      }));
      setSelectedAnnotationNames(annotations.map((a) => a.Id));
    } else {
      // if no annotation was created, we have to link the document version to the issue 'manually'
      const issueCurrentlyLinking = await getIssue(issueIdCurrentlyLinking);
      await updateIssueAsync({
        id: issueIdCurrentlyLinking,
        linkedDocumentVersionIds: { value: Array.from(new Set(issueCurrentlyLinking.linkedDocumentVersionIds.concat(documentVersionId))) },
      });
    }
    setIssueId(issueIdCurrentlyLinking);
    setIssueIdCurrentlyLinking(undefined);
  }, [webViewerInstance, documentVersionId, issueIdCurrentlyLinking, setIssueIdCurrentlyLinking, takeScreenshot, createPdfAnnotationAsync, setIssueId, setSelectedAnnotationNames, getIssue, updateIssueAsync]);

  const closedIssueStatus = useClosedIssueStatus();
  const addLocationButtonDisabled = useMemo(() => issue?.issueStatus === closedIssueStatus?.id, [closedIssueStatus, issue?.issueStatus]);

  return (
    <Box id="IssueManageAnnotationsPanel" sx={{ ...sx }}>
      {!!canEditIssue && !issueIdCurrentlyLinking && (
        <Box sx={{
          backgroundColor: theme.palette.background.default,
          borderRadius: '8px',
          boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.3)',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 0.5, flexWrap: 'wrap', p: 2, pb: 0 }}>
            <Typography variant="h4">
              {t('issue-manage-annotations-panel_header', 'Locations')}
              {!!allAnnotationItems && ` (${allAnnotationItems.length})`}
              {!allAnnotationItems && <CircularProgress size={12} sx={{ ml: 1 }} />}
            </Typography>
            {!nonInteractive && documentVersionId && (
            <Button variant="contained" color="secondary" onClick={onClickAddAnnotations} size="small" sx={{ gap: 0.5, pl: 1 }} disabled={!allAnnotationItems || addLocationButtonDisabled}>
              <Icon path={mdiFileDocumentEdit} size={0.75} color={addLocationButtonDisabled ? theme.palette.grey[400] : theme.palette.secondary.contrastText} />
              {t('issue-manage-annotations-panel_add-locations-button-label', 'Add location')}
            </Button>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: 'auto', maxHeight: '240px' }}>
            {!!allAnnotationItems && allAnnotationItems.map((annotation) => (
              <IssueAnnotationItem
                key={annotation.id}
                annotation={annotationItemsInDocument?.find((a) => a.visoplanAnnotation?.id === annotation.id)}
                pdfAnnotation={annotation}
                onClickAnnotationItem={nonInteractive ? undefined : onClickAnnotationItem}
              />
            ))}
          </Box>
        </Box>
      )}
      {!!issueIdCurrentlyLinking && (
      <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2, borderRadius: '8px', backgroundColor: theme.palette.info.main }}>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Icon path={mdiFileDocumentEdit} size={1} color={theme.palette.primary.contrastText} />
          <Typography sx={{ mr: 'auto', color: theme.palette.primary.contrastText, fontSize: 16, fontWeight: 600 }}>
            {t('issue-manage-annotations-panel_locating-text', 'Add issue location annotations')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button variant="contained" color="primary" onClick={onClickConfirm} sx={{ borderWidth: 1, borderStyle: 'solid', borderColor: theme.palette.primary.light, pl: 1, gap: 0.5 }}>
            <Icon path={mdiCheck} size={0.8} />
            {t('issue-manage-annotations-panel_confirm-button-label', 'Confirm')}
          </Button>
          <Button variant="contained" color="secondary" onClick={onClickDiscard} sx={{ ml: 'auto', pl: 1, gap: 0.5 }}>
            <Icon path={mdiCancel} size={0.8} />
            {t('issue-manage-annotations-panel_discard-button-label', 'Discard')}
          </Button>
        </Box>
      </Box>
      )}
    </Box>
  );
}
