import { Select, Box, MenuItem, Switch, Typography, FormControl, InputLabel, SelectChangeEvent, RadioGroup, FormControlLabel, Radio, Alert } from '@mui/material';
import StampExample from 'Components/ProjectSettingConfig/StampExample';
import useRequestErrorMessage from 'api/hooks/useRequestErrorMessage';
import useDocumentStampSettingsUpdateMutation from 'documents/hooks/useDocumentStampSettingsUpdateMutation';
import StampPosition from 'documents/types/StampPosition';
import StampSettingsDto from 'documents/types/StampSettingsDto';
import useCurrentProjectQuery from 'projects/hooks/useCurrentProjectQuery';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function DocumentSettingsDocumentStampSection() {
  const { t } = useTranslation('settings');
  const { data: currentProject } = useCurrentProjectQuery();

  const getRequestErrorMessage = useRequestErrorMessage();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const onCloseErrorMessage = useCallback(() => setErrorMessage(undefined), []);

  const { mutateAsync: updateAsync } = useDocumentStampSettingsUpdateMutation();

  const [stampSettings, setStampSettings] = useState<StampSettingsDto>(
    currentProject?.stampSettings || { isStampInsertionOnDownloadEnabled: false, position: StampPosition.BottomLeft, locale: 'en-US' } as StampSettingsDto,
  );
  const [stampEnabled, setStampEnabled] = useState<boolean>(stampSettings.isStampInsertionOnDownloadEnabled);

  const handlePositionChange = useCallback((event: SelectChangeEvent<number>) => {
    setStampSettings({ ...stampSettings, position: event.target.value as StampPosition });
  }, [stampSettings]);

  const handleStampEnableChange = useCallback(() => {
    setStampSettings({ ...stampSettings, isStampInsertionOnDownloadEnabled: !stampEnabled });
    setStampEnabled(!stampEnabled);
  }, [stampEnabled, stampSettings]);

  const handleLanguageChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setStampSettings({ ...stampSettings, locale: event.target.value as 'en-US' | 'de-DE' });
  }, [stampSettings]);

  useEffect(() => {
    const updateStampSettings = async () => {
      try {
        await updateAsync(stampSettings);
      } catch (error) {
        setErrorMessage(getRequestErrorMessage(error));
      }
    };
    updateStampSettings();
  }, [stampSettings, updateAsync, getRequestErrorMessage]);

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h4">{t('document-settings-document-stamp-section_title', 'Document stamp')}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>{`${t('document-settings-document-stamp-section_stamp-width', 'Width')}: 180mm, ${t('document-settings-document-stamp-section_stamp-height', 'Height')}: 64mm, ${t('document-settings-document-stamp-section_stamp-margin', 'Margin')}: 30mm`}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography>{t('document-settings-document-stamp-section_switch-description', 'Add an export stamp:')}</Typography>
          <Typography>{t('document-settings-document-stamp-section_position-selector-description', 'Select position:')}</Typography>
          <Typography>{t('document-settings-document-stamp-section_language-selector-description', 'Select language:')}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Switch checked={stampSettings.isStampInsertionOnDownloadEnabled} onChange={handleStampEnableChange} />
          <FormControl disabled={!stampEnabled}>
            <InputLabel>{t('document-settings-document-stamp-section_position-selector-label', 'Position')}</InputLabel>
            <Select value={stampSettings.position} label={t('document-settings-document-stamp-section_position-selector-label', 'Position')} onChange={handlePositionChange}>
              <MenuItem value={StampPosition.TopLeft}>{t('document-settings-document-stamp-section_top-left-label', 'Top Left')}</MenuItem>
              <MenuItem value={StampPosition.TopRight}>{t('document-settings-document-stamp-section_top-right-label', 'Top Right')}</MenuItem>
              <MenuItem value={StampPosition.BottomLeft}>{t('document-settings-document-stamp-section_bottom-left-label', 'Bottom Left')}</MenuItem>
              <MenuItem value={StampPosition.BottomRight}>{t('document-settings-document-stamp-section_bottom-right-label', 'Bottom Right')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl disabled={!stampEnabled}>
            <RadioGroup value={stampSettings.locale} onChange={handleLanguageChange} row>
              <FormControlLabel value="de-DE" control={<Radio />} label={t('document-settings-document-stamp-section_german-label', 'German')} />
              <FormControlLabel value="en-US" control={<Radio />} label={t('document-settings-document-stamp-section_english-label', 'English')} />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Typography variant="caption">{t('document-settings-document-stamp-section_stamp-caption', 'Example')}</Typography>
          <StampExample stampSettings={stampSettings} />
        </Box>
      </Box>
      {!!errorMessage && <Alert style={{ position: 'absolute' }} severity="error" onClose={onCloseErrorMessage}>{errorMessage}</Alert>}
    </Box>
  );
}
