enum ApiEndpoint {
  Project = 'project',
  ProjectCopy = 'project/copy',
  ProjectRoles = 'project/roles',
  ProjectAuth = 'project/auth',
  ProjectName = 'project/name',
  ProjectLabels = 'project/labels',
  Issue = 'issue',
  IssueMany = 'issue/many',
  Comment = 'comment',
  Model = 'model',
  ModelFile = 'modelfile',
  Activity = 'activity',
  DocumentVersion = 'documentVersion',
  DocumentVersionDisciplines = 'documentVersion/disciplines',
  DocumentVersionBuilding = 'documentVersion/building',
  DocumentVersionFloor = 'documentVersion/floor',
  DocumentVersionStatus = 'documentVersion/status',
  DocumentVersionTags = 'documentVersion/tags',
  DocumentVersionWorkPhase = 'documentVersion/workphase',
  Collaborator = 'collaborator',
  CollaboratorCurrent = 'collaborator/current',
  DisciplineMetaData = 'metaData/discipline',
  BuildingMetaData = 'metaData/building',
  FloorMetaData = 'metaData/floor',
  IssueLog = 'issue/issuelog',
  FolderTree = 'folderTree',
  Folder = 'folder',
  Process = 'process',
  QueryFilterIssue = 'query-filter/issue',
  QueryFilterEmail = 'query-filter/email',
  QueryFilterDocument = 'query-filter/document',
  Tag = 'tag',
  DocumentList = 'document-list',
  DocumentStampSettings = 'stamp-settings',
  DocumentStatus = 'document-status',
  IssueType = 'issue-type',
  IssueStatus = 'issue-status',
  IssuePriority = 'issue-priority',
  InboxEmail = 'inbox-email',
  DynamicLayout = 'dynamic-layout',
  PropertyList = 'property-list',
  SmartView = 'smartview',
  WorkPhase = 'workPhase',
  DocumentComment = 'documentComment',
  UserGroup = 'usergroup',
  EmailSettings = 'EmailSettings',
  CopyRoleDefinitions = 'copyRoleDefinitions',
  DefaultRoleDefinitions = 'defaultRoleDefinitions',
  Label = 'label',
  LabelOrder = 'label/order',
  LabelRestore = 'label/restore',
  Resource = 'resource',
  PdfAnnotation = 'pdf-annotation',
  User = 'user',
  UserCurrent = 'user/current',
  Inbox = 'inbox',
  Totp = 'totp',
  ResetPassword = 'reset-password',
  NamingSettings = 'naming-settings',
  Role = 'role',
  CollaboratorPermissions = 'collaborator-permissions',
  Viewpoint = 'viewpoint',
  AuthUser = 'auth-user',
  AuthUserPassword = 'auth-user/password',
  AuthUserValidatePassword = 'auth-user/validatePassword',
  PropertyGroup = 'propertyGroup',
  NodeHistory = 'node/history',
  ExportBcf = 'export/bcf',
  Report = 'report',
  NamingScheme = 'naming-scheme',
}

export default ApiEndpoint;
